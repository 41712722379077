import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../../../../node_modules/axios/index";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logOut } from "../../../redux/actions/authActions/authAction";
import LoaderButton from "../../Common/LoaderButton";
import constatntMessages from "../../../constants/constatntMessages";
import "./org.css";

const Organization = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (authData.isInValidUser) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const organizationId = userData?.organizationId;
  const accessToken = localStorage.getItem("accessToken");
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phoneNo: "",
    organizationCode: "",
    dashboardLogo: "",
    loginLogo: "",
    organizationId: "",
  });
  const [errors, setErrors] = useState({});
  const [loginLogo, setLoginLogo] = useState(null);
  const [dashboardLogo, setDashboardLogo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [dashboardErrorMessage, setDashboardErrorMessage] = useState("");

  const MAX_FILE_SIZE = 500 * 1024;
  const MAX_WIDTH = 220;
  const MAX_HEIGHT = 50;
  const ACCEPTED_FILE_TYPES = "image/jpeg, image/png";

  const validate = () => {
    let isValid = true;
    let errors = {};
    const excludeHtml = /^[0-9a-zA-Z ,&,/-]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneNumberRegex = /^[0-9]{10}$/;

    if (!formValues?.name?.trim()) {
      isValid = false;
      errors.name = "Organization Name is required";
    } else if (!excludeHtml.test(formValues?.name)) {
      isValid = false;
      errors.name = constatntMessages.invalidInput;
    } else if (formValues.name.length < 2 || formValues.name.length > 100) {
      isValid = false;
      errors.name = "Organization Name must be between 2-100  characters";
    }

    if (!formValues?.email?.trim()) {
      isValid = false;
      errors.email = "Email is required";
    } else if (!emailRegex.test(formValues?.email)) {
      isValid = false;
      errors.email = "Invalid email format";
    }

    if (!formValues?.phoneNo) {
      isValid = false;
      errors.phoneNo = "Phone Number is required";
    } else if (!phoneNumberRegex.test(formValues.phoneNo)) {
      isValid = false;
      errors.phoneNo = "Phone Number must be 10 digits long and numeric";
    }

    if (!formValues?.organizationCode?.trim()) {
      isValid = false;
      errors.organizationCode = "Organization Code is required";
    } else if (!excludeHtml.test(formValues?.organizationCode)) {
      isValid = false;
      errors.organizationCode = constatntMessages.invalidInput;
    } else if (
      formValues.organizationCode.length < 2 ||
      formValues.organizationCode.length > 100
    ) {
      isValid = false;
      errors.organizationCode =
        "Organization Code must be between 2-100  characters";
    }
    setErrors(errors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors({});
    setFormValues({ ...formValues, [name]: value.toString() });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", formValues?.name);
    formData.append("email", formValues?.email);
    formData.append("phoneNo", formValues?.phoneNo);
    formData.append("organizationCode", formValues?.organizationCode);
    formData.append("organizationId", organizationId);

    if (loginLogo) {
      formData.append("loginLogo", loginLogo?.file);
    }
    if (dashboardLogo) {
      formData.append("dashboardLogo", dashboardLogo?.file);
    }

    if (validate()) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/V1/organization/editOrganization/${organizationId}`,
          formData,
          {
            headers: {
              Authorization: accessToken,
            },
          }
        );
        if (response.data.success) {
          getOrganizationData();
          setFormValues({
            name: "",
            email: "",
            phoneNo: "",
            organizationCode: "",
            dashboardLogo: "",
            loginLogo: "",
            organizationId: "",
          });

          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          fetchOrganization();
        } else {
          toast.error(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      } catch (error) {
        console.error("Error editing organization:", error);

        toast.error(error.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  const nameValidationCheck = () => {
    const newErrors = { ...errors };
    const excludeHtml = /^[0-9a-zA-Z ,&,/-]+$/;
    if (!formValues?.name || !formValues?.name.trim()) {
      newErrors.name = "Organization Name is required";
    } else if (!excludeHtml.test(formValues.name)) {
      newErrors.name = constatntMessages.invalidInput;
    } else if (formValues.name.length < 3 || formValues.name.length > 20) {
      newErrors.name = "Organization Name must be between 3 - 20  characters";
    } else {
      delete newErrors.name;
    }
    setErrors(newErrors);
    return newErrors;
  };

  const phoneNoValidationCheck = () => {
    const newErrors = { ...errors };
    const phoneNumberRegex = /^[0-9]{10}$/;

    if (!formValues?.phoneNo || !formValues.phoneNo) {
      newErrors.phoneNo = "Phone Number is required";
    } else if (!phoneNumberRegex.test(formValues.phoneNo)) {
      newErrors.phoneNo = "Phone Number must be 10 digits long and numeric";
    } else {
      delete newErrors.phoneNo;
    }

    setErrors(newErrors);
    return newErrors;
  };

  const emailValidationCheck = () => {
    const newErrors = { ...errors };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formValues?.email || !formValues.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(formValues.email)) {
      newErrors.email = "Invalid email format";
    } else {
      delete newErrors.email;
    }
    setErrors(newErrors);
    return newErrors;
  };

  const organizationCodeValidationCheck = () => {
    const newErrors = { ...errors };
    const excludeHtml = /^[0-9a-zA-Z -.(),_/]+$/;
    if (!formValues?.organizationCode || !formValues?.organizationCode.trim()) {
      newErrors.organizationCode = "Organization Code is required";
    } else if (!excludeHtml.test(formValues.organizationCode)) {
      newErrors.organizationCode = constatntMessages.invalidInput;
    } else if (
      formValues.organizationCode.length < 3 ||
      formValues.organizationCode.length > 20
    ) {
      newErrors.organizationCode =
        "Organization Code must be between 3 - 20  characters";
    } else {
      delete newErrors.organizationCode;
    }
    setErrors(newErrors);
    return newErrors;
  };

  const fetchOrganization = async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/V1/organization/viewSingleOrganization/${organizationId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching organization data:", error);
      throw error;
    }
  };

  useEffect(() => {
    document.title = "Organization: MinutesVault";
    const savedLoginLogo = localStorage.getItem("loginLogo");
    const savedDashboardLogo = localStorage.getItem("dashboardLogo");

    if (savedLoginLogo) {
      setLoginLogo(JSON.parse(savedLoginLogo));
    }

    if (savedDashboardLogo) {
      setDashboardLogo(JSON.parse(savedDashboardLogo));
    }
    getOrganizationData();
  }, [organizationId]);

  const getOrganizationData = async () => {
    try {
      const data = await fetchOrganization(organizationId);
      setFormValues(data.data);
    } catch (error) {
      console.error("Failed to fetch organization data:", error);
    }
  };

  //IMAGEE

  const validateImageDimensions = (file, callback, setError) => {
    const img = new Image();
    img.onload = () => {
      if (img.width > MAX_WIDTH || img.height > MAX_HEIGHT) {
        setError(
          `Image dimensions should be max ${MAX_WIDTH}px width and max ${MAX_HEIGHT}px height.`
        );
      } else {
        setError("");
        callback(file);
      }
    };
    img.src = URL.createObjectURL(file);
  };

  const handleLoginLogoFileChange = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileData = {
        file: file,
        preview: e.target.result,
      };
      setLoginLogo(fileData);
      setFormValues((prevValues) => ({
        ...prevValues,
        loginLogo: e.target.result,
      }));
      localStorage.setItem("loginLogo", JSON.stringify(fileData));
    };
    reader.readAsDataURL(file);
  };

  const handleDashboardFileChange = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const fileData = {
        file: file,
        preview: e.target.result,
      };
      setDashboardLogo(fileData);
      setFormValues((prevValues) => ({
        ...prevValues,
        dashboardLogo: e.target.result,
      }));
      localStorage.setItem("dashboardLogo", JSON.stringify(fileData));
    };
    reader.readAsDataURL(file);
  };

  const onDropLogin = (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file?.size > MAX_FILE_SIZE || file === undefined) {
      setLoginErrorMessage("File size exceeds the limit of 200 KB.");
    } else {
      validateImageDimensions(
        file,
        handleLoginLogoFileChange,
        setLoginErrorMessage
      );
    }
  };

  const onDropDashboard = (acceptedFiles) => {
    const file = acceptedFiles[0];

    if (file.size > MAX_FILE_SIZE) {
      setDashboardErrorMessage("File size exceeds the limit of 200 KB.");
    } else {
      validateImageDimensions(
        file,
        handleDashboardFileChange,
        setDashboardErrorMessage
      );
    }
  };

  const { getRootProps: getRootPropsLogin, getInputProps: getInputPropsLogin } =
    useDropzone({
      accept: ACCEPTED_FILE_TYPES,
      maxSize: MAX_FILE_SIZE,
      onDrop: onDropLogin,
    });

  const {
    getRootProps: getRootPropsDashboard,
    getInputProps: getInputPropsDashboard,
  } = useDropzone({
    accept: ACCEPTED_FILE_TYPES,
    maxSize: MAX_FILE_SIZE,
    onDrop: onDropDashboard,
  });

  return (
    <div>
      <Header />
      <Sidebar loginLogo={formValues?.loginLogo} />
      <div className="main-content">
        <div className="Action-list-page">
          <div className="meeting-header-text">
            <h4>Organization</h4>
          </div>
          <div className="mt-2 table-box responsive-table">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-xl-4">
                  <div className="form-group">
                    <label className="mb-1">
                      Organization Name <span className="star-mark"> *</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      onChange={handleChange}
                      onBlur={nameValidationCheck}
                      value={formValues?.name}
                    />
                    {errors?.name && (
                      <span className="error-message">{errors?.name}</span>
                    )}
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="form-group">
                    <label className="mb-1">
                      {" "}
                      Email <span className="star-mark"> *</span>
                    </label>
                    <input
                      type="email"
                      value={formValues?.email}
                      onChange={handleChange}
                      name="email"
                      onBlur={emailValidationCheck}
                    />
                    {errors?.email && (
                      <span className="error-message">{errors?.email}</span>
                    )}
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="form-group">
                    <label className="mb-1">
                      Phone No <span className="star-mark"> *</span>
                    </label>
                    <input
                      type="text"
                      name="phoneNo"
                      onChange={handleChange}
                      value={formValues?.phoneNo}
                      onBlur={phoneNoValidationCheck}
                    />
                    {errors?.phoneNo && (
                      <span className="error-message">{errors.phoneNo}</span>
                    )}
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="form-group">
                    <label className="mb-1">White Logo</label>
                    <div
                      {...getRootPropsLogin()}
                      className="dropzone"
                      style={{
                        border: "1px dashed #ccc",
                        borderRadius: "4px",
                        padding: "20px",
                        textAlign: "center",
                      }}
                    >
                      <input {...getInputPropsLogin()} />
                      <p>
                        Drag 'n' drop a file here, or click to select a file
                      </p>
                    </div>
                    {loginErrorMessage && (
                      <p className="fontSize" style={{ color: "red" }}>
                        {loginErrorMessage}
                      </p>
                    )}
                    {loginLogo && (
                      <div className="mt-2 uploaded-logo">
                        <img
                          src={formValues?.loginLogo}
                          alt="Login Logo"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-xl-4">
                  <div className="form-group">
                    <label className="mb-1">Colored Logo</label>
                    <div
                      {...getRootPropsDashboard()}
                      className="dropzone"
                      style={{
                        border: "1px dashed #ccc",
                        borderRadius: "4px",
                        padding: "20px",
                        textAlign: "center",
                      }}
                    >
                      <input {...getInputPropsDashboard()} />
                      <p>
                        Drag 'n' drop a file here, or click to select a file
                      </p>
                    </div>
                    {dashboardErrorMessage && (
                      <p className="fontSize" style={{ color: "red" }}>
                        {dashboardErrorMessage}
                      </p>
                    )}
                    {dashboardLogo && (
                      <div className="mt-2 uploaded-logo">
                        <img
                          src={formValues?.dashboardLogo}
                          alt="Dashboard Logo"
                          style={{ maxWidth: "100%", height: "auto" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-4">
                  <div className="form-group">
                    <label className="mb-1">
                      Organization Code <span className="star-mark"> *</span>
                    </label>
                    <input
                      type="text"
                      name="organizationCode"
                      onChange={handleChange}
                      onBlur={organizationCodeValidationCheck}
                      value={formValues?.organizationCode}
                    />
                    {errors?.organizationCode && (
                      <span className="error-message">
                        {errors?.organizationCode}
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  {isLoading ? (
                    <LoaderButton />
                  ) : (
                    <button className="save Mom-btn">
                      <p>Update</p>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organization;
