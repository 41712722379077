import axios from "axios";
import {
  CREATE_MEETING_RESPONSE,
  FAIL_REQUEST,
  GET_ATTENDEES_LIST,
  GET_MEETING_LIST,
  MAKE_REQUEST,
  MAKE_RSVP_UPDATE_REQUEST,
  UPDATE_RSVP,
  GET_CREATE_MEETING_STEPS,
  UPDATE_ISCREATE_MEETING_PROCESSED,
  UPDATE_MEETING_RESPONSE,
  LOAD_PREVIOUS_STEP,
  SET_SINGLE_MEETING_DETAILS,
  SET_MEETING_VIEW_PAGE,
  SET_CREATE_NEW_MEETING_PAGE,
  UNSET_SINGLE_MEETING_DETAILS,
  UPDATE_STEP,
  UPDATE_FETCH_MEETING_LIST_STATUS,
  SET_AGENDA_AND_MINUTES_DETAILS,
  SET_MEETING_ACTIVITIES_DETAILS,
  SET_USER_MEETING_LIST,
  UPDATE_ATTENDANCE_RESPONSE,
  UPDATE_MEETING_ISSUCCESS,
  SET_DOWNLOAD_MOM_RESPONSE,
  SET_TIMELINE_RESPONSE,
  SET_PARENT_AGENDA_AND_MINUTES_DETAILS,
  SET_GENERATE_MOM_RESPONSE,
  SET_MEETING_STATISTICS_RESPONSE,
  MAKE_STATISTIC_REQUEST,
  UPDATE_ISSHOW_UPDATE_NOTIFY,
  SEND_MEETING_DETAILS_RESPONSE,
  GET_CURRENT_ATTENDEES_LIST,
} from "./actionTypes";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import "react-toastify/dist/ReactToastify.css";
import * as constantMessages from "../../../constants/constatntMessages";
import { setInValidUser } from "../authActions/authAction";

const accessToken = localStorage.getItem("accessToken");

// axios.defaults.headers = {
//   "Content-Type": "application/json",
//   Authorization: accessToken,
// };

export const makeStastisticRequest = () => {
  return {
    type: MAKE_STATISTIC_REQUEST,
  };
};
export const makeRequest = () => {
  return {
    type: MAKE_REQUEST,
  };
};
export const failRequest = (err) => {
  return {
    type: FAIL_REQUEST,
    payload: err,
  };
};

export const fetchMeetingList = (payload) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/viewAllMeetings`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: payload.accessToken,
      },
      params: {
        limit: payload.limit,
        page: payload.page,
        order: payload.order,
      },
    };

    const bodyPayload = {
      organizationId: payload.organizationId,
      searchKey: payload.searchKey ? payload.searchKey : undefined,
      meetingStatus: payload.meetingStatus ? payload.meetingStatus : undefined,
      toDate: payload.toDate ? payload.toDate : undefined,
      fromDate: payload.fromDate ? payload.fromDate : undefined,
      attendeeId: payload.attendeeId ? payload.attendeeId : undefined,
    };
    axios
      .post(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (
          (!resData.success && resData.data?.totalCount !== 0) ||
          resData.data?.isInValidUser
        ) {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        dispatch(getMeetingList(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const getMeetingList = (data) => {
  return {
    type: GET_MEETING_LIST,
    payload: data,
  };
};

export const fetchAttendeesList = (organizationId, token) => {
  return (dispatch) => {
    dispatch(makeRsvpRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/listAttendeesFromPreviousMeeting/${organizationId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        dispatch(getAttendeesList(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const fetchCurrentAttendeesList = (
  organizationId,
  parentMeetingId,
  token
) => {
  return (dispatch) => {
    dispatch(makeRsvpRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/fetchCurrentAttendeesList`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .post(webApiUrl, { organizationId, parentMeetingId }, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        dispatch(getCurrentAttendeesList(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const getAttendeesList = (data) => {
  return {
    type: GET_ATTENDEES_LIST,
    payload: data,
  };
};

export const getCurrentAttendeesList = (data) => {
  return {
    type: GET_CURRENT_ATTENDEES_LIST,
    payload: data,
  };
};
export const makeRsvpRequest = () => {
  return {
    type: MAKE_RSVP_UPDATE_REQUEST,
  };
};
export const updateRsvp = (rsvp, meetingId) => {
  return (dispatch) => {
    dispatch(makeRsvpRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/updateRsvp/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    const bodyPayload = {
      rsvp,
    };
    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (resData.success) {
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        dispatch(updateRsvpStatus(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const updateRsvpStatus = (data) => {
  return {
    type: UPDATE_RSVP,
    payload: data,
  };
};

export const createMeetingDetails = (payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/createMeeting`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .post(webApiUrl, payload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (resData.success) {
          dispatch(getSingleMeetingDetails(resData.data._id, accessToken));

          toast.success(constantMessages.meetingCreated, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(createMeetingResponse(resData));
          dispatch(updateStep(1, false));
        } else {
          dispatch(failRequest(resData.message));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const createMeetingResponse = (data) => {
  return {
    type: CREATE_MEETING_RESPONSE,
    payload: data,
  };
};

export const getCreateMeetingStep = (organizationId, accessToken) => {
  return (dispatch) => {
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/getCreateMeetingStep/${organizationId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        dispatch(fetchCreateMeetingStep(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const fetchCreateMeetingStep = (data) => {
  return {
    type: GET_CREATE_MEETING_STEPS,
    payload: data,
  };
};

export const updateIsCreateMeetingProcessed = (data) => {
  return {
    type: UPDATE_ISCREATE_MEETING_PROCESSED,
    payload: data,
  };
};

export const updateMeetingDetails = (
  meetingId,
  bodyPayload,
  accessToken,
  isFrom,
  isUpdate,
  isPropCall
) => {
  return (dispatch) => {
    dispatch(makeRequest());
    dispatch(updateIsSuccess(false));
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/updateMeeting/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;

        const message =
          isFrom === "addAttendee" && !isUpdate
            ? "Attendees added successfully. Please add agenda."
            : isFrom === "addAttendee" && isUpdate
            ? "Attendees updated successfully."
            : isFrom === "addAgenda" && !isUpdate
            ? "Agenda added and notification of meeting has been sent to all attendees successfully."
            : isFrom === "addAgenda" && isUpdate
            ? //  ? "Agenda updated successfully."
              "Meeting is updated and notification sent to all the attendees successfully."
            : resData.message;
        if (resData.success) {
          dispatch(updateIsSuccess(true));
          toast.success(message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          if (isPropCall) {
            dispatch(getAgendaWithMinutesDetails(meetingId, accessToken));
          } else {
            dispatch(getSingleMeetingDetails(meetingId, accessToken));
          }
          dispatch(updateMeetingResponse(resData));
          dispatch(updateStep(bodyPayload.step, true));
          if (isFrom === "addAgenda" && isUpdate) {
            dispatch(updateIShowUpdateNotify(false));
          }
        } else {
          if (resData.data?.isInValidUser) {
            dispatch(setInValidUser(true));
          }
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(failRequest(resData.message));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const updateMeetingResponse = (data) => {
  return {
    type: UPDATE_MEETING_RESPONSE,
    payload: data,
  };
};
export const updateIsSuccess = (data) => {
  return {
    type: UPDATE_MEETING_ISSUCCESS,
    payload: data,
  };
};
export const updateIShowUpdateNotify = (data) => {
  return {
    type: UPDATE_ISSHOW_UPDATE_NOTIFY,
    payload: data,
  };
};

export const getSingleMeetingDetails = (meetingId, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/viewMeeting/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (resData.success === false) {
          dispatch(failRequest(resData.message));
        }
        if (!resData.success && !resData.data?.isInValidUser) {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          dispatch(failRequest(resData.message));
        }
        if (resData.success) {
          dispatch(setSingleMeetingDetails({ data: resData.data, meetingId }));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setSingleMeetingDetails = (data) => {
  return {
    type: SET_SINGLE_MEETING_DETAILS,
    payload: data,
  };
};

export const unSetSingleMeetingDetails = () => {
  return {
    type: UNSET_SINGLE_MEETING_DETAILS,
  };
};
export const updateStep = (step, isUpdateStep) => {
  return {
    type: UPDATE_STEP,
    payload: { step, isUpdateStep },
  };
};

export const loadCreateMeeting = (data) => {
  return {
    type: LOAD_PREVIOUS_STEP,
    payload: data,
  };
};
export const setMeetingViewPage = (data) => {
  return {
    type: SET_MEETING_VIEW_PAGE,
    payload: data,
  };
};

export const setCreateNewMeetingPage = (data) => {
  return {
    type: SET_CREATE_NEW_MEETING_PAGE,
    payload: data,
  };
};

export const processCancelMeeting = (meetingId, bodyPayload, accessToken) => {
  return (dispatch) => {
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/cancelMeeting/${meetingId}`;

    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (resData.success) {
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(updateFetchMeetingListStatus(true));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(updateFetchMeetingListStatus(false));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        dispatch(updateFetchMeetingListStatus(false));
      });
  };
};

export const updateFetchMeetingListStatus = (data) => {
  return {
    type: UPDATE_FETCH_MEETING_LIST_STATUS,
    payload: data,
  };
};

export const processCloseMeeting = (meetingId, bodyPayload, accessToken) => {
  return (dispatch) => {
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/updateMeetingStatus/${meetingId}`;

    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (resData.success) {
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(updateFetchMeetingListStatus(true));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(updateFetchMeetingListStatus(false));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        dispatch(updateFetchMeetingListStatus(false));
      });
  };
};

export const getAgendaWithMinutesDetails = (meetingId, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/viewMeetingAgendaWithMinutes/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (resData.success) {
          dispatch(updateFetchMeetingListStatus(false));
          dispatch(setAgendaWithMinutesDetails(resData.data));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(failRequest(resData.message));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setAgendaWithMinutesDetails = (data) => {
  return {
    type: SET_AGENDA_AND_MINUTES_DETAILS,
    payload: data,
  };
};

export const viewParentMeeting = (meetingId, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/viewParentMeeting/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (resData.success) {
          dispatch(setParentAgendaWithMinutesDetails(resData.data));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};
export const setParentAgendaWithMinutesDetails = (data) => {
  return {
    type: SET_PARENT_AGENDA_AND_MINUTES_DETAILS,
    payload: data,
  };
};

export const getMeetingActivitiesDetails = (meetingId, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/viewMeetingActivities/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (resData.success) {
          dispatch(setMeetingActivitiesDetails(resData));
        } else {
          dispatch(failRequest(resData.message));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setMeetingActivitiesDetails = (data) => {
  return {
    type: SET_MEETING_ACTIVITIES_DETAILS,
    payload: data,
  };
};

export const fetchUserMeetingList = (organizationId, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/minute/getMeetingListOfAttendees/${organizationId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (resData.success) {
          dispatch(setUserMeetingList(resData));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setUserMeetingList = (data) => {
  return {
    type: SET_USER_MEETING_LIST,
    payload: data,
  };
};

export const updateAttendanceAndTimeRanges = (
  meetingId,
  bodyPayload,
  accessToken
) => {
  return (dispatch) => {
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/updateMeetingAttendance/${meetingId}`;

    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (resData.success) {
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(updateAttendanceAndTimeRangesResponse(resData));
          dispatch(getMeetingActivitiesDetails(meetingId, accessToken));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(updateAttendanceAndTimeRangesResponse(resData));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        dispatch(updateFetchMeetingListStatus(false));
      });
  };
};

export const updateAttendanceAndTimeRangesResponse = (data) => {
  return {
    type: UPDATE_ATTENDANCE_RESPONSE,
    payload: data,
  };
};

export const generateMOM = (meetingId, bodyPayload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/generateMOM/${meetingId}`;

    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, bodyPayload, headerObject, {
        responseType: "blob",
      })

      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }

        if (resData.success) {
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          dispatch(setGenerateMOMResponse(resData));
          dispatch(getAgendaWithMinutesDetails(meetingId, accessToken));
          dispatch(getMeetingActivitiesDetails(meetingId, accessToken));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(setGenerateMOMResponse(resData));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};
export const setGenerateMOMResponse = (data) => {
  return {
    type: SET_GENERATE_MOM_RESPONSE,
    payload: data,
  };
};

export const downloadMOM = (meetingId, token) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/downloadMOM/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        if (resData.success) {
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          saveAs(resData.data, "mom.pdf");
          dispatch(setDownloadMomResponse(resData));
          dispatch(getMeetingActivitiesDetails(meetingId, token));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(setDownloadMomResponse(resData));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setDownloadMomResponse = (data) => {
  return {
    type: SET_DOWNLOAD_MOM_RESPONSE,
    payload: data,
  };
};

export const rescheduleMeeting = (meetingId, bodyPayload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    dispatch(updateFetchMeetingListStatus(false));

    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/rescheduleMeeting/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (resData.success) {
          dispatch(updateFetchMeetingListStatus(true));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const giveMomWritePermission = (meetingId, bodyPayload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    dispatch(updateFetchMeetingListStatus(false));

    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/giveMomWritePermission/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .put(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }

        if (resData.success) {
          dispatch(updateFetchMeetingListStatus(true));
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const getTimelineList = (meetingId, token) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/getTimelineList/${meetingId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        if (resData.success) {
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          dispatch(setTimelineResponse(resData));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(setTimelineResponse(resData));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setTimelineResponse = (data) => {
  return {
    type: SET_TIMELINE_RESPONSE,
    payload: data,
  };
};

export const viewMeetingStatistics = (organizationId, token) => {
  return (dispatch) => {
    dispatch(makeStastisticRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/viewMeetingStatistics/${organizationId}`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
    };

    axios
      .get(webApiUrl, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }

        if (resData.success) {
          dispatch(setMeetingStatisticsResponse(resData));
        } else {
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(setMeetingStatisticsResponse(resData));
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const setMeetingStatisticsResponse = (data) => {
  return {
    type: SET_MEETING_STATISTICS_RESPONSE,
    payload: data,
  };
};

export const sendMeetingDetails = (payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/meeting/sendMeetingDetails`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    axios
      .post(webApiUrl, payload, headerObject)
      .then((result) => {
        const resData = result.data;
        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (resData.success) {
          toast.success(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          dispatch(sendMeetingDetailsResponse(resData));
        } else {
          dispatch(failRequest(resData.message));
          toast.error(resData.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const sendMeetingDetailsResponse = (data) => {
  return {
    type: SEND_MEETING_DETAILS_RESPONSE,
    payload: data,
  };
};
