import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import AttendeeRsvpTable from "./AttendeeRsvpTable";
const AttendeesRsvpModal = (props) => {
  const { attendees } = props;
  const rsvpObject = {};
  attendees.map((attendee) => {
    if (rsvpObject[attendee.rsvp]) {
      rsvpObject[attendee.rsvp] = [...rsvpObject[attendee.rsvp], attendee];
    } else {
      rsvpObject[attendee.rsvp] = [attendee];
    }
  });

  return (
    <>
      <Modal
        show={props.IsRsvpModalOpen}
        onHide={(e) => props.setIsRsvpModalOpen(false)}
        backdrop="static"
      >
        <div className="modal-header attendees-modal">
          <div>
            <h4 className="modal-title">RSVP Confirmation</h4>
            <span>{props.rsvpCount}</span>
          </div>
          <div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={(e) => props.setIsRsvpModalOpen(false)}
            ></button>
          </div>
        </div>

        <Modal.Body>
          <div className="attendees-popup modal-margin">
            <AttendeeRsvpTable rsvpObject={rsvpObject} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AttendeesRsvpModal;
