import { SET_ORGANIZATION_DATA } from "../actions/organizationAction/actionTypes";

const initialObject = {
  loading: false,
  message: "",
  organizationData: {},
};

export const organizationReducer = (state = initialObject, action) => {
  switch (action.type) {
    case SET_ORGANIZATION_DATA:
      return {
        ...state,

        organizationData: action.payload,
      };

    default:
      return state;
  }
};
