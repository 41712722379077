import React from "react";
import noDataFound from "../../assets/images/mom-404.svg";
const PageNotFound = () => {
  return (
    <div className="no-data-found">
      <img
        // @ts-ignore
        src={noDataFound}
        alt=""
      ></img>
    </div>
  );
};

export default PageNotFound;
