import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import AddAttendees from "./AddAttendees";
const AddAttendeesModal = (props) => {
  const meetingData = useSelector((state) => state.meeting);
  useEffect(() => {
    if (meetingData.isSuccess) {
      props.setIsAddAttendeeModal(false);
    }
  }, [meetingData.isSuccess]);
  return (
    <>
      <Modal
        show={props.isAddAttendeeModal}
        onHide={(e) => props.setIsAddAttendeeModal(false)}
        backdrop="static"
      >
        <div className="modal-header attendees-modal">
          <h4 className="modal-title">Add Attendee(s)</h4>
          <div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={(e) => props.setIsAddAttendeeModal(false)}
            ></button>
          </div>
        </div>
        <Modal.Body className="update-attendee">
          <AddAttendees meetingData={props.meetingData} fromMinutePage={true} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddAttendeesModal;
