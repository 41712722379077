const { textBoxStrLength } = require("../config/config");
//import crypt from 'crypto-js';

var CryptoJS = require("crypto-js");

process.env.TZ = "Asia/Calcutta";
const customName = (fullname) => {
  const nameArray = fullname.trimStart().split(" ");

  let result;

  if (nameArray.length > 1) {
    result =
      nameArray[0].charAt(0).toUpperCase() +
      nameArray[nameArray.length - 1].charAt(0).toUpperCase();
  } else {
    result = nameArray[0].charAt(0).toUpperCase();
  }

  return result;
};

const formatDateTimeFormat = (date) => {
  const sourceDate = new Date(date).toDateString();
  const sourceTime = new Date(date).toLocaleTimeString();
  // The above yields e.g. 'Mon Jan 06 2020'

  const [, month, day, year] = sourceDate.split(" ");
  const formattedDate = [day, month, year].join(" ");
  //

  const [hour, minute, second] = sourceTime.split(" ")[0].split(":");
  const formattedTime =
    [hour, minute].join(":") + " " + sourceTime.split(" ")[1];
  return {
    formattedTime,
    formattedDate,
  };
};

const getTimeSession = (time) => {
  const timeArray = time.split(":");
  const timeHour = timeArray[0];

  if (timeHour > 12) {
    return "PM";
  }
  return "AM";
};

const checkRsvpCount = (attendees) => {
  let yesCount = 0;
  let noCount = 0;
  let pendingCount = 0;
  let mayBeCount = 0;

  attendees.map((item) => {
    item.rsvp === "YES"
      ? (yesCount = yesCount + 1)
      : item.rsvp === "NO"
      ? (noCount = noCount + 1)
      : item.rsvp === "MAYBE"
      ? (mayBeCount = mayBeCount + 1)
      : (pendingCount = pendingCount + 1);
  });

  const countMessage = `${yesCount} Yes, ${noCount} No, ${mayBeCount} May Be, ${pendingCount} Awaiting`;

  return {
    yesCount,
    noCount,
    mayBeCount,
    pendingCount,
    countMessage,
  };
};

const convertFirstLetterToCapital = (text) => {
  return text.trimStart().charAt(0).toUpperCase() + text.toLowerCase().slice(1);
};
const convertFirstLetterOfFullNameToCapital = (textData) => {
  const textArray = textData.split(" ");

  const convertedTextArray = textArray.map((text) => {
    return (
      text.trimStart().charAt(0).toUpperCase() + text.toLowerCase().slice(1)
    );
  });
  return convertedTextArray.join(" ");
};

const checkCharacterLeft = (string) => {
  return textBoxStrLength - string.length;
};


//The Function Below To Encrypt Text
const encryptWithAES = (text) => {
  const passphrase = process.env.REACT_APP_PASSWORD_KEY;
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

const combineDateAndTime = (date, time) => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);

  const modifiedHour =
    time.split(":")[0] < 10
      ? time.split(":")[0].split("")[1]
      : time.split(":")[0];
  const modifiedMinute =
    time.split(":")[1] < 10
      ? time.split(":")[1].split("")[1]
      : time.split(":")[1];
  date.setHours(date.getHours() + parseInt(modifiedHour));
  date.setMinutes(date.getMinutes() + parseInt(modifiedMinute));

  return date;
 
};

const convertTimetoIST = (time) => {
  const timeArray = time.split(":");
  const timeHour = timeArray[0];
  if (timeHour >= 12 && timeHour !== 24) {
    let finalHour = timeHour == 12 ? 12 : timeHour - 12;
    finalHour = finalHour.toString().length == 1 ? "0" + finalHour : finalHour;
    return finalHour + ":" + timeArray[1] + " PM";
  } else if (timeHour == 24) {
    return "00" + ":" + timeArray[1] + " AM";
  } else {
    return timeHour + ":" + timeArray[1] + " AM";
  }
};

const getDaysDiiference = (fromDate, toDate) => {
  let date1 = new Date(fromDate.toString());
  let date2 = new Date(toDate.toString());
  // Convert dates to UTC timestamps
  let utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
  let utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());
  // Calculate the time difference in milliseconds
  let timeDiff = utc2 - utc1;
  // Convert milliseconds to days
  let daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  return daysDiff;
};

const fetchIpAddress = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
  }
};

module.exports = {
  customName,
  formatDateTimeFormat,
  getTimeSession,
  checkRsvpCount,
  convertFirstLetterToCapital,
  convertFirstLetterOfFullNameToCapital,
  checkCharacterLeft,
  encryptWithAES,
  combineDateAndTime,
  convertTimetoIST,
  getDaysDiiference,
  fetchIpAddress,
};
