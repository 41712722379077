import React from "react";
import Select from "react-select";

const UnitDropdown = ({ units, employeeData, handleChange, errors }) => {
  const unitOptions = units
    .filter((un) => un?.isActive === true)
    .map((unit) => ({
      value: unit?._id,
      label: unit?.name,
    }));

  const handleUnitChange = (selectedOption) => {
    handleChange({
      target: {
        name: "unitId",
        value: selectedOption ? selectedOption?.value : "",
      },
    });
  };

  return (
    <div className="form-group">
      <label className="mb-1">
        Unit<span className="star-mark"> *</span>
      </label>
      <Select
        name="unitId"
        value={
          unitOptions.find((option) => option.value === employeeData?.unitId) ||
          null
        }
        onChange={handleUnitChange}
        options={unitOptions}
        placeholder="Select Unit"
      />
      {errors.unitId && <span className="error-message">{errors?.unitId}</span>}
    </div>
  );
};

export default UnitDropdown;
