import {
  FAIL_REQUEST,
  CREATE_MINUTE_RESPONSE,
  MAKE_REQUEST,
  SET_FINAL_MINUTES_DATA,
  SET_FINAL_AGENDA_MINUTES_DATA,
  SET_AGENDA_DATAS,
  CREATE_NEW_AGENDA_RESPONSE,
  SET_SUCCESS,
  UPDATE_MINUTE_RESPONSE,
  PROCESS_AMENDMENT_REQUEST,
  UPDATE_AMENDMENT_REQUEST,
  EDIT_AGENDA_RESPONSE,
  DELETE_AGENDA_RESPONSE,
  SET_MOM_ACCEPT_DETAILS,
} from "../actions/minuteActions/actionTypes";

const initialObject = {
  loading: false,
  message: "",
  isSuccess: false,
  isMinutesCreated: false,
  finalMinutesData: [],
  finalAgendaMinutesData: [],
  agendaData: null,
  status: null,
  momAcceptDetails: [],
};

export const minuteReducer = (state = initialObject, action) => {
  switch (action.type) {
    case MAKE_REQUEST:
      return {
        ...state,
        loading: true,
        status: action.payload,
      };
    case FAIL_REQUEST:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        status: null,
      };

    case SET_SUCCESS:
      return {
        ...state,
        isSuccess: action.payload,
      };

    case CREATE_MINUTE_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isMinutesCreated: action.payload.success,
        isSuccess: action.payload.success,
        finalMinutesData: action.payload.success ? [] : state.finalMinutesData,
        agendaData: action.payload.success ? null : state.agendaData,
      };
    case SET_FINAL_MINUTES_DATA:
      return {
        ...state,
        loading: false,
        finalMinutesData: action.payload,
      };

    case SET_FINAL_AGENDA_MINUTES_DATA:
      return {
        ...state,
        loading: false,
        finalAgendaMinutesData: action.payload,
      };
    case SET_AGENDA_DATAS:
      return {
        ...state,
        loading: false,
        agendaData: action.payload,
      };

    case CREATE_NEW_AGENDA_RESPONSE:
      return {
        ...state,
        loading: false,
        agendaData: action.payload.success ? null : state.agendaData,
        message: action.payload.message,
        isSuccess: action.payload.success,
        finalAgendaMinutesData: action.payload.success
          ? []
          : state.finalAgendaMinutesData,
        agendaData: action.payload.success ? null : state.agendaData,
      };

    case UPDATE_MINUTE_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };

    case PROCESS_AMENDMENT_REQUEST:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };

    case UPDATE_AMENDMENT_REQUEST:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };
    case EDIT_AGENDA_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };

    case DELETE_AGENDA_RESPONSE:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
      };

    case SET_MOM_ACCEPT_DETAILS:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
        isSuccess: action.payload.success,
        momAcceptDetails: action.payload.data,
      };

    default:
      return state;
  }
};
