import {
  FAIL_REQUEST,
  GET_MEETING_ROOM_LIST,
  MAKE_REQUEST,
  GET_UNIT_LIST,
} from "../actions/meetingRoomAction/actionTypes";

const initialObject = {
  loading: false,
  message: "",
  totalCount: 0,
  isSuccess: false,
  meetingRoomList: [],
  unitList: [],
};

export const meetingRoomReducer = (state = initialObject, action) => {
  switch (action.type) {
    case MAKE_REQUEST:
      return {
        ...state,
      };
    case FAIL_REQUEST:
      return {
        ...state,
        loading: false,
        message: action.payload.message,
      };

    case GET_MEETING_ROOM_LIST:
      return {
        ...state,

        message: action.payload.message,
        meetingRoomList: action.payload.data.roomsDatas,
        isSuccess: action.payload.success,
      };

    case GET_UNIT_LIST:
      return {
        ...state,

        message: action.payload.message,
        unitList: action.payload.data.unitData,
        isSuccess: action.payload.success,
      };

    default:
      return state;
  }
};
