import React, { useState, useEffect } from "react";
import Collapse from "react-bootstrap/Collapse";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as constantMessages from "../../constants/constatntMessages";
import "../Login/style/Login.css";
import { logOut } from "../../redux/actions/authActions/authAction";
import {
  addAgendaDetails,
  setAgendaData,
  setFinalAgendaMinuteData,
} from "../../redux/actions/minuteActions/MinuteAction";
import { textBoxStrLength } from "../../config/config";
import { checkCharacterLeft } from "../../helpers/commonHelpers";

const AddMinuteAgenda = () => {
  const regularExpression = /^[0-9a-zA-Z .(),/-]+$/;
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const meetingData = useSelector((state) => state.meeting);
  const minuteData = useSelector((state) => state.minute);
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }

  const [isMinuteShow, setIsMinuteShow] = useState(false);

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    title: " ",
    topic: "",
    timeLine: "0",
  });
  const [newAgendaData, setNewAgendaData] = useState([]);

  useEffect(() => {
    dispatch(setAgendaData(formData));
    if (minuteData.isSuccess) {
      setFormData({
        ...formData,
        title: " ",
        topic: "",
        timeLine: "0",
      });
      setNewAgendaData([]);
      setIsMinuteShow(false);
      dispatch(setAgendaData(null));
    }
  }, [minuteData.isSuccess]);

  const submitAgendasDetails = (e) => {
    e.preventDefault();
    const newErrors = validateForm(formData);
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      const errors = {};
      errors.addAgenda = constantMessages.addAgenda;

      setErrors(errors);
    } else {
      const payload = {
        meetingId: meetingData?.meetingDetails?._id,
        agendaData: {
          topic: formData.topic,
          title: formData.title,
          timeLine: formData.timeLine.toString(),
        },
        minutes: meetingData.finalAgendaMinutesData,

        organizationId: userData.organizationId,
      };

      dispatch(addAgendaDetails(payload, accessToken));
    }
  };

  const validateForm = () => {
    //
    const errors = {};

    if (!formData?.title.trim()) {
      errors.title = constantMessages.titleRequired;
    } else if (!regularExpression.test(formData.title)) {
      errors.title = constantMessages.titleRegexError;
    } else if (
      formData.title.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.title = constantMessages.stringLengthError;
    }

    if (formData.timeLine > 365 || formData.timeLine < 0) {
      errors.timeLine = constantMessages.invalidTime;
    }
    return errors;
  };

  const handleChange = (e) => {
    setErrors({});

    const { name, value } = e.target;

    if (name === "topic") {
      if (value.length <= textBoxStrLength) {
        setFormData({
          ...formData,

          [name]: value,
        });
        dispatch(
          setAgendaData({
            ...minuteData.agendaData,
            [name]: value,
          })
        );
      }
    } else {
      setFormData({
        ...formData,

        [name]: value,
      });
      dispatch(
        setAgendaData({
          ...minuteData.agendaData,
          [name]: value,
        })
      );
    }
  };

  const [open, setOpen] = useState(true);
  const validateAgendaTitle = () => {
    const errors = {};
    if (!formData?.title.trim()) {
      errors.title = constantMessages.titleRequired;
    } else if (!regularExpression.test(formData.title)) {
      errors.title = constantMessages.titleRegexError;
    } else if (
      formData.title.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.title = constantMessages.stringLengthError;
    }
    setErrors(errors);
  };
  const validateAgendaTime = () => {
    const errors = {};

    if (formData.timeLine > 365 || formData.timeLine < 0) {
      errors.timeLine = constantMessages.invalidTime;
    }
    setErrors(errors);
  };

  const onAddAgenda = () => {
    //
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      dispatch(
        addAgendaDetails(
          {
            agendaData: [
              {
                topic: formData.topic,
                timeLine: formData.timeLine,
                title: formData.title,
                organizationId: userData.organizationId,
                meetingId: meetingData?.meetingDetails?._id,
              },
            ],
          },
          meetingData.meetingDetails._id,
          accessToken
        )
      );

      setFormData({
        ...formData,
        title: " ",
        topic: "",
        timeLine: "0",
      });
    }
  };

  const onRemoveAgenda = (uid) => {
    const filteredAgenda = minuteData.finalAgendaMinutesData.filter(
      (item) => item.uid !== uid
    );

    dispatch(setFinalAgendaMinuteData(filteredAgenda));
  };

  return (
    <form className="mt-2 no-padding-2" onSubmit={submitAgendasDetails}>
      <div className="inner-detail-form">
        <div className="form-group agenda mb-2">
          <label>Agenda Item</label>
        </div>

        <div id="inputFields">
          <div id="children-pane">
            <div className="agenda-background">
              <div className="topic-head p-2 d-flex align-items-center justify-content-between">
                <div>
                  <label> Agenda</label>
                </div>
              </div>
              <div className="open-div">
                <div className="collapse show">
                  <div className="form-group">
                    <div className="mb-2">
                      <div className="row">
                        <div className="col-md-4">
                          <label className="mb-1">Agenda Title</label>
                        </div>
                        <div className="col-md-8">
                          <input
                            type="text"
                            placeholder="Enter agenda title here"
                            name="title"
                            value={formData.title}
                            onChange={handleChange}
                            onBlur={validateAgendaTitle}
                            autoComplete="off"
                          />

                          {errors.title ? (
                            <span className="error-message">
                              {errors.title}
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="row">
                      <div className="col-md-4">
                        <label className="mb-1">
                          What are the topic to discuss ?
                        </label>
                      </div>
                      <div className="col-md-8">
                        <textarea
                          name="topic"
                          value={formData.topic}
                          onChange={handleChange}
                          autoComplete="off"
                          placeholder="Enter issue to discuss..."
                          id=""
                          cols="56"
                          rows="4"
                        ></textarea>
                        <p className="success-message">
                          {checkCharacterLeft(formData.topic)} Characters left
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="form-group m-0">
                    <div className="row">
                      <div className="col-md-4">
                        <label className="mb-1">
                          How long will this agenda item take to discuss?
                        </label>
                      </div>
                      <div className="col-md-8">
                        <div className="time-taken">
                          <input
                            name="timeLine"
                            value={formData.timeLine}
                            onChange={handleChange}
                            onBlur={validateAgendaTime}
                            required="required"
                            type="number"
                            autocomplete="off"
                          />
                          <div className="minute_box">mins</div>
                        </div>
                        {errors.timeLine && (
                          <span className="error-message">
                            {errors.timeLine}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="mt-3 plus pointer">
                      <button
                        type="button"
                        className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center "
                        onClick={onAddAgenda}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="#fff"
                          className="bi bi-plus-circle pointer me-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                        </svg>
                        <p> Add Agenda</p>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {newAgendaData?.length !== 0
              ? newAgendaData?.map((agenda, index) => {
                  return (
                    <>
                      <br></br>

                      <div className="agenda-background">
                        <h2>
                          <button
                            className=""
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                            type="button"
                          >
                            Agenda {index + 1}
                          </button>
                        </h2>
                        <div className="open-div">
                          <Collapse in={open}>
                            <div>
                              <div className="form-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="mb-1">
                                      Agenda Title{" "}
                                      <span className="star-mark"> *</span>
                                    </label>
                                  </div>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      placeholder="Enter agenda title"
                                      name="title"
                                      value={agenda.title}
                                      onChange={(e) => handleChange(e)}
                                      autoComplete="off"
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="form-group">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="mb-1">
                                      What are the topic to discuss ?
                                    </label>
                                  </div>
                                  <div className="col-md-8">
                                    <textarea
                                      name="topic"
                                      value={agenda.topic}
                                      onChange={(e) => handleChange(e)}
                                      autoComplete="off"
                                      placeholder="Enter issue to discuss..."
                                      id=""
                                      cols="56"
                                      rows="4"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group m-0">
                                <div className="row">
                                  <div className="col-md-4">
                                    <label className="mb-1">
                                      How long will this agenda item take to
                                      discuss?
                                    </label>
                                  </div>
                                  <div className="col-md-8">
                                    <div className="time-taken">
                                      <input
                                        name="timeLine"
                                        value={agenda.timeLine}
                                        onChange={(e) => handleChange(e)}
                                        required="required"
                                        type="number"
                                        autocomplete="off"
                                      />
                                      <div className="minute_box">mins</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Collapse>
                        </div>
                      </div>
                    </>
                  );
                })
              : null}
          </div>
        </div>
        {errors.addAgenda ? (
          <span className="error-message">{errors.addAgenda}</span>
        ) : null}

        {minuteData.finalAgendaMinutesData?.length !== 0
          ? minuteData.finalAgendaMinutesData?.map((agenda, index) => {
              return (
                <>
                  <br></br>

                  <div className="agenda-background">
                    <h2>
                      <button
                        className=""
                        onClick={() => setOpen(!open)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open}
                        type="button"
                      >
                        Agenda {index + 1}
                      </button>
                      <button
                        type="button"
                        onClick={() => onRemoveAgenda(agenda.uid)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23"
                          height="23"
                          fill="#0564f3"
                          className="bi-minus-circle bi bi-dash-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                          <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8" />
                        </svg>
                      </button>
                    </h2>
                    <div className="open-div">
                      <div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-4">
                              <label className="mb-1">
                                Agenda Title{" "}
                                <span className="star-mark">*</span>
                              </label>
                            </div>
                            <div className="col-md-8">
                              <input
                                type="text"
                                placeholder="Enter agenda title"
                                name="title"
                                value={agenda.title}
                                onChange={(e) => handleChange(e, agenda.uid)}
                                autoComplete="off"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="row">
                            <div className="col-md-4">
                              <label className="mb-1">
                                What are the topic to discuss ?
                              </label>
                            </div>
                            <div className="col-md-8">
                              <textarea
                                name="topic"
                                value={agenda.topic}
                                onChange={(e) => handleChange(e, agenda.uid)}
                                autoComplete="off"
                                placeholder="Enter issue to discuss..."
                                id=""
                                cols="56"
                                rows="4"
                              ></textarea>
                            </div>
                          </div>
                        </div>

                        <div className="form-group m-0">
                          <div className="row">
                            <div className="col-md-4">
                              <label className="mb-1">
                                How long will this agenda item take to discuss?
                              </label>
                            </div>
                            <div className="col-md-8">
                              <div className="time-taken">
                                <input
                                  name="timeLine"
                                  value={agenda.timeLine}
                                  onChange={(e) => handleChange(e, agenda.uid)}
                                  required="required"
                                  type="number"
                                  autocomplete="off"
                                />
                                <div className="minute_box">mins</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          : null}
        <div></div>
      </div>
    </form>
  );
};

export default AddMinuteAgenda;
