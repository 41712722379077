import React, { useState, useEffect } from "react";
import * as constantMessages from "../../constants/constatntMessages";
import { useSelector, useDispatch } from "react-redux";
import { fetchCurrentAttendeesList } from "../../redux/actions/meetingActions/MeetingAction";
import Select from "react-select";
import { getEmployeeList } from "../../redux/actions/userAction/userAction";
import LoaderButton from "../Common/LoaderButton";
import { toast } from "react-toastify";
import {
  createMinutes,
  setFinalMinuteData,
} from "../../redux/actions/minuteActions/MinuteAction";

const AddMinute = (props) => {
  const regularExpression = /^[0-9a-zA-Z .(),/-]+$/;
  const meetingData = useSelector((state) => state.meeting);
  const employeeData = useSelector((state) => state.user);
  const minutesDetails = useSelector((state) => state.minute);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [selectedAttendeeOption, setSelectedAttendeeOption] = useState([
    { label: "Name / Email Address", value: "" },
  ]);
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState([
    { label: "Name / Emp Id", value: "" },
  ]);
  const [formData, setFormData] = useState({
    description: "",
    date: "",
    priority: "",
    attendyType: "fromCurrentMeeting",
    responsiblePersonId: "",
    isAction: false,
    name: "",
    email: "",
  });
  const [minuteData, setMinuteData] = useState([]);

  useEffect(() => {
    if (
      minutesDetails?.finalMinutesData?.length !== 0 &&
      minuteData.length === 0
    ) {
      setMinuteData(minutesDetails.finalMinutesData);
    }

    // dispatch(
    //   fetchCurrentAttendeesList(
    //     userData.organizationId,
    //     meetingData?.meetingDetails?._id,
    //     accessToken
    //   )
    // );
  }, []);

  let attendeesData = meetingData.meetingDetails.attendees.map((attendee) => {
    return {
      id: attendee._id,
      status: "PENDING",
    };
  });

  const checkHandler = (e, uid) => {
    const { checked } = e.target;

    if (uid) {
      const modifiedMinutes = minutesDetails.finalMinutesData.map((obj) => {
        if (obj.uid === uid) {
          return { ...obj, isAction: checked };
        }
        return obj;
      });
      dispatch(setFinalMinuteData(modifiedMinutes));
    } else {
      setFormData({
        ...formData,
        isAction: !formData.isAction,
      });
    }
  };

  const handleChange = (e, uid) => {
    setErrors({});
    const { name, value } = e.target;

    if (uid) {
      const modifiedMinutes = minutesDetails.finalMinutesData.map((obj) => {
        if (obj.uid === uid) {
          return { ...obj, [name]: value };
        }
        return obj;
      });
      dispatch(setFinalMinuteData(modifiedMinutes));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (value === "fromEmployeeList") {
      const payload = {
        organizationId: userData.organizationId,
      };

      dispatch(getEmployeeList(payload, accessToken));
    }
   // alert(value)
    if (formData.attendyType === "fromCurrentMeeting" && accessToken  && value==="fromCurrentMeeting") {
    //  alert("hhhhhhh")
      dispatch(
        fetchCurrentAttendeesList(
          userData.organizationId,
          meetingData?.meetingDetails?._id,
          accessToken
        )
      );
    }
  };

  const validateMinuteTitle = () => {
    const errors = {};
    if (!formData?.description.trim()) {
      errors.description = constantMessages.titleRequired;
    } else if (!regularExpression.test(formData.description)) {
      errors.description = constantMessages.titleRegexError;
    } else if (
      formData.description.trim().length < 5 ||
      formData.description.trim().length > 400
    ) {
      errors.description = constantMessages.stringLengthError;
    }
    setErrors(errors);
  };

  const dateFieldValidationCheck = (e) => {
    const errors = {};
    if (formData.date.trim()) {
      const currentDate = new Date(meetingData?.meetingDetails?.date);
      const inputDate = new Date(formData.date);
      let differenceInTime = inputDate.getTime() - currentDate.getTime();
      let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      if (differenceInDays < 0) {
        errors.date = constantMessages.dateMoreThanMeetingDate;
      }
    }
    setErrors(errors);
  };

  const nameFieldValidationCheck = (e) => {
    const errors = {};

    if (!formData.name.trim()) {
      errors.name = constantMessages.nameRequired;
    } else if (!regularExpression.test(formData.name)) {
      errors.name = constantMessages.invalidName;
    } else if (
      formData.name.trim().length < 3 ||
      formData.name.trim().length > 100
    ) {
      errors.name = constantMessages.stringLengthError;
    }
    setErrors(errors);
  };

  const emailFieldValidationCheck = (e) => {
    const errors = {};

    if (!formData.email.trim()) {
      errors.email = constantMessages.emailRequired;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = constantMessages.invalidEmail;
    }
    setErrors(errors);
  };

  const validateForm = (data) => {
    const errors = {};
    if (formData?.attendyType === "addNewPeople") {
      if (!formData?.name?.trim()) {
        errors.name = constantMessages.nameRequired;
      } else if (!regularExpression.test(formData.name)) {
        errors.name = constantMessages.invalidName;
      } else if (
        formData?.name?.trim()?.length < 3 ||
        formData?.name?.trim()?.length > 50
      ) {
        errors.title = constantMessages.stringLengthError;
      }

      if (!formData?.email?.trim()) {
        errors.email = constantMessages.emailRequired;
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        errors.email = constantMessages.invalidEmail;
      }
    } else {
      if (
        !formData?.responsiblePersonId?.trim() &&
        formData.isAction &&
        formData.name === "" &&
        formData.email === ""
      ) {
        errors.responsiblePersonId = constantMessages.responsiblePersonRequired;
      }
    }

    if (!formData?.description?.trim()) {
      errors.description = constantMessages.titleRequired;
    } else if (!regularExpression.test(formData.description)) {
      errors.description = constantMessages.titleRegexError;
    } else if (
      formData.description.trim().length < 5 ||
      formData.description.trim().length > 400
    ) {
      errors.description = constantMessages.stringLengthError;
    }

    if (formData?.date?.trim() && formData.isAction) {
      const currentDate = new Date(meetingData?.meetingDetails?.date);
      const inputDate = new Date(formData.date);
      let differenceInTime = inputDate.getTime() - currentDate.getTime();
      let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      if (differenceInDays < 0) {
        errors.date = constantMessages.dateMoreThanMeetingDate;
      }
    }
    if (!formData?.priority?.trim() && formData.isAction) {
      errors.priority = constantMessages.priorityRequired;
    }

    return errors;
  };

  const onAddMinute = () => {
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const responsiblePersonId =
        formData.responsiblePersonId === "" &&
        formData.name === "" &&
        formData.email === ""
          ? userData._id
          : formData.responsiblePersonId;

      const isNewUser =
        formData.name !== "" &&
        formData.email !== "" &&
        formData.isAction === true
          ? true
          : formData.name === "" &&
            formData.email === "" &&
            formData.isAction === true
          ? false
          : undefined;
      const newMinuteData = {
        organizationId: userData.organizationId,
        agendaId: props.agenda._id,
        meetingId: meetingData?.meetingDetails?._id?.toString(),
        attendees: attendeesData,
        description: formData.description,
        dueDate:
          formData.isAction === true && formData.date !== ""
            ? formData.date
            : undefined,
        priority:
          formData.isAction === true && formData.priority !== ""
            ? formData.priority
            : undefined,
        isNewUser,

        assignedUserId:
          formData.isAction === true && isNewUser === false
            ? responsiblePersonId
            : undefined,
        isAction: formData.isAction,

        name:
          formData.isAction === true && isNewUser === true
            ? formData.name
            : undefined,
        email:
          formData.isAction === true && isNewUser === true
            ? formData.email
            : undefined,
      };

      dispatch(
        createMinutes(
          {
            meetingId: meetingData.meetingDetails._id,
            minutes: [newMinuteData],
          },
          accessToken
        )
      );
      setFormData({
        ...formData,
        description: " ",
        date: "",
        priority: "",
        attendyType: "fromCurrentMeeting",
        responsiblePersonId: "",
        isAction: false,
        name: "",
        email: "",
      });
    } else {
    }
  };

  if (meetingData?.currentAttendeesList?.length !== 0) {
    var attendeeOptions = meetingData?.currentAttendeesList?.map((attendee) => {
      const returnData = {
        value: attendee._id,
        label: attendee.name + " / " + attendee.email,
        name: "responsiblePersonId",
      };

      return returnData;
    });
  }

  const handleAttendeeChange = (e) => {
    setErrors({});
    const { name, value } = e;
    setFormData({
      ...formData,
      [name]: value,
    });
    setSelectedAttendeeOption(e);
  };
  const handleEmployeeChange = (e) => {
    setErrors({});
    const { name, value } = e;
    setFormData({
      ...formData,
      [name]: value,
    });
    setSelectedEmployeeOption(e);
  };
  if (employeeData?.employeeList?.length !== 0) {
    var employeeOptions = employeeData?.employeeList?.map((attendee) => {
      const returnData = {
        value: attendee._id,
        label: attendee.name + " / " + attendee.empId,
        name: "responsiblePersonId",
      };
      return returnData;
    });
  }

  return (
    <>
      <form className="addminutesboxfrom">
        <div className="mt-4 minutes-box show">
          <div className="form-group">
            <div className=" mt-1 mb-1 d-flex justify-content-between align-items-center ">
              <label>Minutes</label>

              <div className="form-check ">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexCheckDefault"
                  name="isAction"
                  onChange={checkHandler}
                  checked={formData.isAction}
                />
                <label
                  className="form-check-label "
                  id="flexCheckDefault"
                  for="flexCheckDefault"
                >
                  Add Action
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div>
                  <textarea
                    placeholder="Enter minute description here"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    onBlur={validateMinuteTitle}
                    autoComplete="off"
                  />

                  {errors.description ? (
                    <span className="error-message">{errors.description}</span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="add-action">
            {formData.isAction ? (
              <>
                <div className="form-group">
                  <div className="position-relative">
                    <label className="pb-2 input-date"> Due Date</label>
                    <input
                      type="date"
                      name="date"
                      value={formData.date}
                      onChange={handleChange}
                      onBlur={dateFieldValidationCheck}
                    />
                  </div>
                  {errors.date && (
                    <span className="error-message">{errors.date}</span>
                  )}
                </div>

                <div className="form-group">
                  <div className="position-relative">
                    <label className="pb-2 input-date">Priority</label>
                    <div>
                      <select
                        name="priority"
                        id="priority"
                        onChange={handleChange}
                      >
                        <option value="">Select Priority</option>
                        <option value="HIGH">High</option>
                        <option value="NORMAL">Normal</option>
                        <option value="LOW">Low</option>
                      </select>
                    </div>
                  </div>
                  {errors.priority ? (
                    <span className="error-message">{errors.priority}</span>
                  ) : null}
                </div>

                <div className="form-group pb-3 border-bottom">
                  <label className="pb-2">Select Responsible Person</label>

                  <div className="w-100">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="attendyType"
                        value="fromCurrentMeeting"
                        id="flexRadioDefault1"
                        onChange={handleChange}
                        checked={formData.attendyType === "fromCurrentMeeting"}
                      />
                      <label
                        className="mb-2 form-check-label"
                        for="flexRadioDefault1"
                        id="flexRadioDefault1"
                      >
                        Select From Current Meetings
                      </label>
                    </div>

                    <div className="form-check-inline">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="attendyType"
                          value="fromEmployeeList"
                          id="flexRadioDefault2"
                          onChange={handleChange}
                          checked={formData.attendyType === "fromEmployeeList"}
                        />
                        <label
                          className=" mb-2 form-check-label"
                          for="flexRadioDefault2"
                          id="flexRadioDefault2"
                        >
                          Select From Employees
                        </label>
                      </div>
                    </div>

                    <div className="form-check-inline">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="attendyType"
                          value="addNewPeople"
                          id="flexRadioDefault3"
                          checked={formData.attendyType === "addNewPeople"}
                          onChange={handleChange}
                        />
                        <label
                          className=" mb-2 form-check-label"
                          for="flexRadioDefault3"
                          id="flexRadioDefault3"
                        >
                          Add New Guest
                        </label>
                      </div>
                    </div>
                  </div>

                  {formData.attendyType === "fromCurrentMeeting" ? (
                    <Select
                      className="fltr-opt"
                      placeholder="Name / Email Address"
                      name="responsiblePersonId"
                      options={attendeeOptions}
                      onChange={handleAttendeeChange}
                      value={selectedAttendeeOption}
                    />
                  ) : formData.attendyType === "fromEmployeeList" ? (
                    <Select
                      className="fltr-opt"
                      placeholder="Name / Employee ID"
                      name="responsiblePersonId"
                      options={employeeOptions}
                      value={selectedEmployeeOption}
                      onChange={handleEmployeeChange}
                    />
                  ) : formData.attendyType === "addNewPeople" ? (
                    <div>
                      <label className="mt-1">Add New Guest</label>
                      <div className="row">
                        <div className="col-xl-6 col-md-6 mt-2">
                          <input
                            type="text"
                            autoComplete="off"
                            placeholder="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            onBlur={nameFieldValidationCheck}
                          />
                          {errors.name && (
                            <span className="error-message">{errors.name}</span>
                          )}
                        </div>
                        <div className="col-xl-6 col-md-6 mt-2">
                          <input
                            type="text"
                            placeholder="Email"
                            autoComplete="off"
                            name="email"
                            value={formData.email}
                            onBlur={emailFieldValidationCheck}
                            onChange={handleChange}
                          />
                          {errors.email && (
                            <span className="error-message">
                              {errors.email}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {errors.responsiblePersonId && (
                    <span className="error-message">
                      {errors.responsiblePersonId}
                    </span>
                  )}
                </div>
              </>
            ) : null}

            <div className="form-group m-0 d-flex ">
              {!employeeData.loading ? (
                <button
                  type="button"
                  className="btn rounded-pill add-btn Mom-btn d-flex align-items-center justify-content-center "
                  onClick={onAddMinute}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#fff"
                    className="bi bi-plus-circle pointer me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                  </svg>
                  <p> Add </p>
                </button>
              ) : (
                <LoaderButton />
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddMinute;
