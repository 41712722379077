import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import { deleteAgenda } from "../../redux/actions/minuteActions/MinuteAction";

const DeleteAgendaModal = (props) => {
  const { agendaData } = props;
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const minuteData = useSelector((state) => state.minute);
  useEffect(() => {
    if (minuteData.isSuccess) {
      props.setIsDeleteAgendaModal(false);
    }
  }, [minuteData.isSuccess]);

  const handleSubmit = () => {
    const payload = {
      meetingId: agendaData.meetingId,
    };

    dispatch(deleteAgenda(payload, agendaData._id, accessToken));
  };

  return (
    <>
      <Modal
        show={props.isDeleteAgendaModal}
        onClick={(e) => props.setIsDeleteAgendaModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Agenda</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you want to delete {props.agendaData?.title} ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={(e) => props.setIsDeleteAgendaModal(false)}
            className="btn-light"
          >
            <p>No</p>
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteAgendaModal;
