import { combineReducers } from "@reduxjs/toolkit";
import { authReducer } from "./authReducers";
import { meetingReducer } from "./meetingReducer";
import { userReducer } from "./userReducer";
import { meetingRoomReducer } from "./meetingRoomReducer";
import { minuteReducer } from "./minuteReducer";
import { actionReducer } from "./actionReducer";
import { notificationReducer } from "./notificationReducer";
import { commonReducer } from "./commonReducer";
import { organizationReducer } from "./organizerReducer";
export const rootReducer = combineReducers({
  auth: authReducer,
  meeting: meetingReducer,
  user: userReducer,
  meetingRoom: meetingRoomReducer,
  minute: minuteReducer,
  action: actionReducer,
  notification: notificationReducer,
  common: commonReducer,
  organization: organizationReducer,
});
