import { OPEN_SIDEBAR, CLOSE_SIDEBAR } from "./actionTypes";
import 'react-toastify/dist/ReactToastify.css'


export const openSidebar = () => {
  return {
    type: OPEN_SIDEBAR
  };
};
export const closeSidebar = () => {
  return {
    type: CLOSE_SIDEBAR
  };
};