import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import bgImage from "../../assets/images/LandingpageImage/bg.png";
import bookArrow from "../../assets/images/LandingpageImage/book-arrow.png";
import { Modal, Button, Form } from "react-bootstrap";
import Greencheck from "../../assets/images/LandingpageImage/check.svg";

const ContactUs = (props) => {

  const [formData, setFormData] = useState({
    name: "",
    phoneNo: "",
    email: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [otpStatus, setOtpStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpProcessloading, setOtpProcessloading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [otpSendMessage, setOtpSendMessage] = useState(""); // Message for "Get OTP"
  const [otpVerifyMessage, setOtpVerifyMessage] = useState("");
  const [otpVerifiedSuccessMessage, setOtpVerifiedSuccessMessage] =
    useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState(""); // Separate state for OTP
  const [otpVerified, setOtpVerified] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  const [isValidEmailEntered, setIsValidEmailEntered] = useState(false);
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [otpSuccessMessage, setOTPSuccessMessageVal] = useState("");
  const [otpErrorMessage, setOTPErrorMessageVal] = useState("");
  const [otpError, setOTPError] = useState("");

  const [isVisible, setIsVisible] = useState(false);
  const [verifyOTPSuccessMessage, setVerifyOTPSuccessMessageVal] = useState("");
  const [verifyOTPErrorMessage, setVerifyOTPErrorMessageVal] = useState("");
  const [modalSettings, setModalSettings] = useState({ backdrop: true });

  const topTitleRef = useRef(null);
  const paragraphRef = useRef(null);
  const solutionTextRef = useRef(null);
  const containerRef = useRef(null);

  const [showContactUsModal, setShowContactUsModal] = useState(false);

  const [srollPosition, setScrollPosition] = useState(0);

  useEffect(() => {

    setFormData({ name: "", phoneNo: "", email: "", message: "" });
    setFormErrors({});

    setOtpStatus(false);
    setLoading(false);
    setOtpProcessloading(false);
    setOtpLoading(false);
    setErrors({});
    setNameError("");
    setPhoneError("");
    setEmailError("");
    setMessageError("");

    setOtpSendMessage("");
    setOtpVerifyMessage("");
    setOtpVerifiedSuccessMessage("");
    setOtpSent(false);
    setOtpVerified(false);
    setIsValidEmail(false);

    setIsValidEmailEntered(false);
    setIsOTPVerified(false);
    setOTPSuccessMessageVal("");
    setOTPErrorMessageVal("");

    setIsVisible(false);
    setVerifyOTPSuccessMessageVal("");
    setVerifyOTPErrorMessageVal("");
    setErrorMessage("");
    setSuccessMessage("");

  }, []);

  const openBookADemoModal = () => {
    setFormData({ name: "", phoneNo: "", email: "", message: "" });
    setFormErrors({});

    setOtpStatus(false);
    setLoading(false);
    setOtpProcessloading(false);
    setOtpLoading(false);
    setErrors({});
    setNameError("");
    setPhoneError("");
    setEmailError("");
    setMessageError("");

    setOtpSendMessage("");
    setOtpVerifyMessage("");
    setOtpVerifiedSuccessMessage("");
    setOtpSent(false);
    setOtpVerified(false);
    setIsValidEmail(false);

    setIsValidEmailEntered(false);
    setIsOTPVerified(false);
    setOTPSuccessMessageVal("");
    setOTPErrorMessageVal("");

    setIsVisible(false);
    setVerifyOTPSuccessMessageVal("");
    setVerifyOTPErrorMessageVal("");
    setErrorMessage("");
    setSuccessMessage("");

    setShowContactUsModal(true);
  };

  const closeContactUsDemoModal = () => {

    setFormData({ name: "", phoneNo: "", email: "", message: "" });
    setFormErrors({});

    setOtpStatus(false);
    setLoading(false);
    setOtpProcessloading(false);
    setOtpLoading(false);
    setErrors({});
    setNameError("");
    setPhoneError("");
    setEmailError("");
    setMessageError("");

    setOtpSendMessage("");
    setOtpVerifyMessage("");
    setOtpVerifiedSuccessMessage("");
    setOtpSent(false);
    setOtpVerified(false);
    setIsValidEmail(false);

    setIsValidEmailEntered(false);
    setIsOTPVerified(false);
    setOTPSuccessMessageVal("");
    setOTPErrorMessageVal("");

    setIsVisible(false);
    setVerifyOTPSuccessMessageVal("");
    setVerifyOTPErrorMessageVal("");
    setErrorMessage("");
    setSuccessMessage("");
    
    props.setIsContaUsModalOpen(false);
    setShowContactUsModal(false);
  };

  const nameValidationCheck = () => {
    const newErrors = {};
    const excludeHtml = /^[0-9a-zA-Z ,&/ -]+$/;

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else if (!excludeHtml.test(formData.name)) {
      newErrors.name = "Invalid characters";
    } else if (formData.name.trim().length < 3) {
      newErrors.name = "Minimum 3 characters required";
    } else {
      newErrors.name = "";
    }

    setNameError(newErrors.name);
  };

  const phoneValidationCheck = () => {
    const newErrors = {};
    const phonePattern = /^[0-9]{10}$/;

    if (formData.phoneNo?.trim() === "") {
      newErrors.phoneNo = "Mobile number is required";
    } else if (formData.phoneNo.length > 0 && formData.phoneNo.length < 11) {
      if (!phonePattern.test(formData.phoneNo)) {
        newErrors.phoneNo = "Please enter a valid mobile number";
      } else {
        newErrors.phoneNo = "";
      }
    } else if (formData.phoneNo.length > 10) {
      newErrors.phoneNo = "Maximum 10 digits is allowed";
    } else {
      newErrors.phoneNo = "";
    }

    setPhoneError(newErrors.phoneNo);
  };

  const emailValidationCheck = () => {
    const newErrors = {};

    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
      setIsValidEmailEntered(false);
    } else if (!emailPattern.test(formData.email)) {
      newErrors.email = "Please enter a valid email address";
      setIsValidEmailEntered(false);
    } else {
      setIsValidEmailEntered(true);
      newErrors.email = "";
    }

    setEmailError(newErrors.email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === "name") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        name: null,
      }));
      const newErrors = {};
      const excludeHtml = /^[0-9a-zA-Z ,&/ -]+$/;

      if (value?.trim() == "") {
        newErrors.name = "Name is required";
      } else if (!excludeHtml.test(value)) {
        newErrors.name = "Invalid characters";
      } else if (value.trim().length < 3) {
        newErrors.name = "Minimum 3 characters required";
      } else {
        newErrors.name = "";
      }

      setNameError(newErrors.name);
    }

    if (name === "phoneNo") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        phoneNo: null,
      }));

      const newErrors = {};
      const phonePattern = /^[0-9]{10}$/;

      if (value?.trim() === "") {
        newErrors.phoneNo = "Mobile number is required";
      } else if (value.length > 0 && value.length < 11) {
        if (!phonePattern.test(value)) {
          newErrors.phoneNo = "Please enter a valid mobile number";
        } else {
          newErrors.phoneNo = "";
        }
      } else if (value.length > 10) {
        newErrors.phoneNo = "Maximum 10 digits is allowed";
      } else {
        newErrors.phoneNo = "";
      }

      setPhoneError(newErrors.phoneNo);
    }

    if (name === "email") {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        email: null,
      }));

      const newErrors = {};

      if (value?.trim() === "") {
        newErrors.email = "Email is required";
        setIsValidEmailEntered(false);
      } else if (!emailPattern.test(value)) {
        newErrors.email = "Please enter a valid email address.";
        setIsValidEmailEntered(false);
      } else {
        setIsValidEmailEntered(true);
        newErrors.email = "";
      }

      setEmailError(newErrors.email);
    }
  };

  const handleKeyDown = (e) => {
    const { name, value } = e.target;

    if (name === "phoneNo") {
      if (value.length >= 10 && ![8, 37, 39, 46].includes(e.keyCode)) {
        e.preventDefault();
      }
    }

    if (name === "otp") {
      if (value.length >= 6 && ![8, 37, 39, 46].includes(e.keyCode)) {
        e.preventDefault();
      }
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);

    if (e.target.value.toString().length == 6) {
      setOTPError("");
    }
  };

  const isValidate = () => {
    const errors = {};
    let isValid = false;

    var nameError = false;
    var mobileError = false;
    var emailError = false;

    const excludeHtml = /^[0-9a-zA-Z ,&/ -]+$/;

    if (formData.name?.trim() == "") {
      errors.name = "Name is required";
      nameError = true;
    } else if (!excludeHtml.test(formData.name)) {
      errors.name = "Invalid characters";
      nameError = true;
    } else if (formData.name.trim().length < 3) {
      errors.name = "Minimum 3 characters required";
      nameError = true;
    } else {
      errors.name = "";
      nameError = false;
    }

    const phonePattern = /^[0-9]{10}$/;

    if (formData.phoneNo?.trim() === "") {
      errors.phoneNo = "Mobile number is required";
      mobileError = true;
    } else if (formData.phoneNo.length > 0 && formData.phoneNo.length < 11) {
      if (!phonePattern.test(formData.phoneNo)) {
        errors.phoneNo = "Please enter a valid mobile number";
        mobileError = true;
      } else {
        errors.phoneNo = "";
        mobileError = false;
      }
    } else if (formData.phoneNo.length > 10) {
      errors.phoneNo = "Maximum 10 digits is allowed";
      mobileError = true;
    } else {
      errors.phoneNo = "";
      mobileError = false;
    }

    if (!formData.email.trim()) {
      errors.email = "Email is required";
      emailError = true;
    } else if (!emailPattern.test(formData.email)) {
      errors.email = "Please enter a valid email address";
      emailError = true;
    } else {
      errors.email = "";
      emailError = false;
    }

    setFormErrors(errors);

    if (nameError || mobileError || emailError) {
      isValid = false;
    } else {
      isValid = true;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage("");
    setSuccessMessage("");

    if (isValidate()) {
      if (!isOTPVerified) {
        setErrorMessage("Please verify the OTP before submitting");
        return false;
      }

      setLoading(true);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/V1/demo/saveContactUsDetails`,
          formData
        );

        if (response?.data) {
          if (response?.data?.success === true) {
            var message = response.data.message
              ? response.data.message
              : "Thank you for contacting to MinutesVault. Our team will reach out to you shortly.";

            setSuccessMessage(message);
            setTimeout(() => {
              setSuccessMessage("");
              setShowContactUsModal(false);
              props.setIsContaUsModalOpen(false);
            }, 5000);

            setFormData({ name: "", phoneNo: "", email: "", message: "" });
            setFormErrors({});

            setOtpStatus(false);
            setLoading(false);
            setOtpProcessloading(false);
            setOtpLoading(false);
            setErrors({});
            setNameError("");
            setPhoneError("");
            setEmailError("");
            setMessageError("");

            setOtpSendMessage("");
            setOtpVerifyMessage("");
            setOtpVerifiedSuccessMessage("");
            setOtpSent(false);
            setOtpSent(false);
            setOtpVerified(false);
            setIsValidEmail(false);

            setIsValidEmailEntered(false);
            setIsOTPVerified(false);
            setOTPSuccessMessageVal("");
            setOTPErrorMessageVal("");

            setIsVisible(false);
            setVerifyOTPSuccessMessageVal("");
            setVerifyOTPErrorMessageVal("");
          }
          if (response.data?.success === false) {
            var message = response.data.message
              ? response.data.message
              : "Something went wrong. Please try again later.";
            setErrorMessage(message);
            setTimeout(() => {
              setErrorMessage("");
            }, 8000);
          }
        }
      } catch (error) {
        setErrorMessage(error.response?.message || "Submission failed.");
        setTimeout(() => {
          setErrorMessage("");
        }, 8000);
      }
    }
  };

  const sendOtp = async (name, email) => {
    try {
      console.log(process.env.REACT_APP_API_URL);
      const response = await axios.post(
         `${process.env.REACT_APP_API_URL}/api/V1/demo/contactUsendOtp`,
        { name, email, type: 'contactus'  }
      );
      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || "An unexpected error occurred.";
      return { error: true, message };
    }
  };

  const verifyOtp = async (email, otp) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V1/demo/verifyContactUsOtp`,
        { email, otp }
      );

      return response.data;
    } catch (error) {
      const message =
        error.response?.data?.message || "An unexpected error occurred.";
      return { success: false, message };
    }
  };

  const handleSendOtp = async () => {
    const newErrors = {};
    const excludeHtml = /^[0-9a-zA-Z ,&/ -]+$/;

    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else if (!excludeHtml.test(formData.name)) {
      newErrors.name = "Invalid characters";
    } else if (formData.name.trim().length < 3) {
      newErrors.name = "Minimum 3 characters required.";
    } else {
      newErrors.name = "";
    }

    setNameError(newErrors.name);

    if (newErrors.name != "") {
      return;
    }

    setOtpLoading(true);
    setOtpVerifyMessage("");

    setOTPSuccessMessageVal("");
    setOTPErrorMessageVal("");

    const response = await sendOtp(formData.name, formData.email);

    if (response) {
      if (response?.success === true) {
        var message = response.message
          ? response.message
          : "OTP has been sent to the entered email";
        setOtpLoading(false);
        setOtpSent(true);
        setOtp("");
        setOTPSuccessMessageVal(message);
        setTimeout(() => {
          setOTPSuccessMessageVal("");
        }, 8000);
      }
      if (response.success === false) {
        setOtpLoading(false);
        var message = response.message
          ? response.message
          : "Unable to send OTP";
        setOTPErrorMessageVal(message);
        setOtpSent(false);
        setOtp("");
        setTimeout(() => {
          setOTPErrorMessageVal("");
        }, 8000);
      }
    }
  };

  const handleVerifyOtp = async () => {
    var isSafeOtpData = false;
    var isSafeEmailData = false;

    if (!otp) {
      setOTPError("Please enter the OTP.");
      setOtpStatus(false);
      isSafeOtpData = false;
    } else if (otp.toString().length < 6) {
      setOTPError("OTP should be 6 digits.");
      setOtpStatus(false);
      isSafeOtpData = false;
    } else if (otp.toString().length == 6) {
      setOTPError("");
      isSafeOtpData = true;
    }

    const newErrors = {};

    if (formData.email?.trim() === "") {
      newErrors.email = "Email is required.";
      setIsValidEmailEntered(false);
      isSafeEmailData = false;
    } else if (!emailPattern.test(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
      setIsValidEmailEntered(false);
      isSafeEmailData = false;
    } else {
      setIsValidEmailEntered(true);
      newErrors.email = "";
      isSafeEmailData = true;
    }

    setEmailError(newErrors.email);

    if (isSafeOtpData == false || isSafeEmailData == false) {
      return;
    }

    setOtpProcessloading(true);
    setVerifyOTPSuccessMessageVal("");
    setVerifyOTPErrorMessageVal("");
    setOTPSuccessMessageVal("");
    setOTPErrorMessageVal("");

    const response = await verifyOtp(formData.email, otp);

    if (response) {
      if (response?.success === true) {
        var message = response.message
          ? response.message
          : "OTP verified successfully";
        setOtpProcessloading(false);
        setIsOTPVerified(true);
        setOtpSent(false);
        setOtp("");
        setOTPError("");
        setVerifyOTPSuccessMessageVal(message);
        setTimeout(() => {
          setVerifyOTPSuccessMessageVal("");
        }, 8000);
      }
      if (response.success === false) {
        setOtpProcessloading(false);
        var message = response.message
          ? response.message
          : "Unable to verify OTP";
        setVerifyOTPErrorMessageVal(message);
        setTimeout(() => {
          setVerifyOTPErrorMessageVal("");
        }, 8000);
      }
    }
  };

  return (

    <Modal
      className="book-a-demo-modal"
          show={props.showContactUsModal}
          backdrop="static"
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          onHide={closeContactUsDemoModal}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="middle-text">👋🏻 Reach Out to Us!</div>
        <div className="middle-text mb-3">
          We’re here to help and can’t wait to hear from you.
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="contact-form mt-0">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    {/* Name Field */}
                    <div className="form-floating mb-3">
                      <div className="form-control-feedback-icon">
                        <i className="ph ph-user"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Your Name"
                        name="name"
                        id="name"
                        maxLength={100}
                        autoComplete="off"
                        value={formData.name}
                        onChange={handleChange}
                        onBlur={() => nameValidationCheck(formData.name)}
                      />
                      <label htmlFor="name">
                        Enter Your Name <span>*</span>
                      </label>
                      {nameError && <p className="text-danger">{nameError}</p>}
                      {formErrors.name && (
                        <p className="text-danger">{formErrors.name}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    {/* Phone Number Field */}
                    <div className="form-floating mb-3">
                      <div className="form-control-feedback-icon">
                        <i className="ph ph-phone"></i>
                      </div>
                      <input
                        type="number"
                        className="form-control only_number"
                        placeholder="Enter Mobile Number"
                        name="phoneNo"
                        id="phoneNo"
                        autoComplete="off"
                        value={formData.phoneNo}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onBlur={() => phoneValidationCheck(formData.phoneNo)}
                      />
                      <label htmlFor="phoneNo">
                        Enter Mobile Number <span>*</span>
                      </label>
                      {phoneError && (
                        <p className="text-danger">{phoneError}</p>
                      )}
                      {formErrors.phoneNo && (
                        <p className="text-danger">{formErrors.phoneNo}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    {/* Email Field with OTP Button */}
                    <div className="form-floating for-overflow-text">
                      <div className="form-control-feedback-icon">
                        <i className="ph ph-email"></i>
                      </div>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Work Email"
                        name="email"
                        id="email"
                        autoComplete="off"
                        value={formData.email}
                        readOnly={isOTPVerified}
                        onBlur={() => emailValidationCheck(formData.email)}
                        onChange={handleChange}
                        maxLength="96"
                      />
                      <label htmlFor="email">
                        Enter Work Email <span>*</span>
                      </label>
                      {emailError && (
                        <p className="text-danger">{emailError}</p>
                      )}
                      {formErrors.email && (
                        <p className="text-danger">{formErrors.email}</p>
                      )}

                      {emailError == "" &&
                      isValidEmailEntered &&
                      !isOTPVerified ? (
                        <button
                          type="button"
                          className="btn btn-primary verify-email"
                          onClick={handleSendOtp}
                        >
                          {otpLoading ? "Sending..." : "Get OTP"}
                        </button>
                      ) : null}

                      {isOTPVerified ? (
                        <div className="image-tick">
                          <img src={Greencheck} className="green-check"></img>
                        </div>
                      ) : null}
                    </div>
                    {otpLoading === false ? (
                      <div className="">
                        {otpSuccessMessage && (
                          <p className="success-response">
                            {otpSuccessMessage}
                          </p>
                        )}
                        {otpErrorMessage && (
                          <p className="error-response">{otpErrorMessage}</p>
                        )}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-12">
                    {/* OTP Input and Verify Button (Shown after OTP is sent and until verified) */}
                    {otpSent === true && (
                      <div className="form-floating mt-3">
                        <div className="form-control-feedback-icon">
                          <i className="ph ph-otp"></i>
                        </div>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter OTP"
                          name="otp"
                          id="otp"
                          autoComplete="off"
                          onKeyDown={handleKeyDown}
                          value={otp}
                          onChange={handleOtpChange}
                        />
                        <label htmlFor="otp">
                          Enter OTP <span>*</span>
                        </label>

                        {otpError && <p className="text-danger">{otpError}</p>}

                        <div className="">
                          <button
                            type="button"
                            className="btn btn-primary verify-email"
                            onClick={handleVerifyOtp}
                            disabled={otpProcessloading}
                          >
                            {otpProcessloading ? "Verifying..." : "Verify"}
                          </button>
                        </div>
                        {}
                      </div>
                    )}

                    {verifyOTPSuccessMessage != "" ||
                    verifyOTPErrorMessage != "" ? (
                      <div className="">
                        {verifyOTPSuccessMessage && (
                          <p className="success-response">
                            {verifyOTPSuccessMessage}
                          </p>
                        )}
                        {verifyOTPErrorMessage && (
                          <p className="error-response">
                            {verifyOTPErrorMessage}
                          </p>
                        )}
                      </div>
                    ) : null}
                  </div>

                  {/* OTP Verification Message */}
                  {/* Message Field */}
                  <div className="col-md-12">
                    <div className="form-floating mb-3 mt-3">
                      <div className="form-control-feedback-icon">
                        <i className="ph ph-message"></i>
                      </div>
                      <textarea
                        className="form-control"
                        placeholder="Message"
                        style={{ height: "70px" }}
                        name="message"
                        id="message"
                        maxLength={300}
                        autoComplete="off"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                      <label htmlFor="message">Message</label>
                      {messageError && (
                        <p className="text-danger">{messageError}</p>
                      )}
                      {formErrors.message && (
                        <p className="text-danger">{formErrors.message}</p>
                      )}
                    </div>
                  </div>
                  {/* Submit Button */}
                  <div className="book-demo-btn mt-2">
                    <button type="submit" className="book-btn">
                      {loading ? "Submitting..." : "Contact Us"}
                    </button>
                  </div>
                  {/* General Form Submission Messages */}
                  {errorMessage && (
                    <p className="f-error-response">{errorMessage}</p>
                  )}
                  {successMessage && (
                    <p className="f-success-response">{successMessage}</p>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ContactUs;
