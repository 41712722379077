import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Common/Loader";
import "../Login/style/Login.css";
import Alert from "../Common/Alert";
import NoDataFound from "../Common/NoDataFound";
import { customName } from "../../helpers/commonHelpers";
import { useLocation, useNavigate } from "react-router-dom";
import { logOut } from "../../redux/actions/authActions/authAction";
import AttendeesModal from "./AttendeesModal";
import GuestAttendeesModal from "./GuestAttendeesModal";
import OnlyAttendeeModal from "./OnlyAttendeeModal";

const ViewMeeting = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const meetingData = useSelector((state) => state.meeting);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attendeesData, setAttendeesData] = useState([]);
  const employeeData = useSelector((state) => state.user);
  const [isGuestModalOpen, setIsGuestModalOpen] = useState(false);
  const [isOnlyAttendeeModalOpen, setIsOnlyAttendeeModalOpen] = useState(false);

  useEffect(() => {
    return () => {};
  }, [meetingData.meetingId, meetingData.step]);

  const setGuestModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsGuestModalOpen(value);
      setAttendeesData([...attendeesData]);
    }
  };

  const setOnlyAttendeeModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsOnlyAttendeeModalOpen(value);
      setAttendeesData([...attendeesData]);
    }
  };

  return (
    <>
      {(meetingData.step + 1 === 1 && !meetingData.singleMeetingDetails) ||
      (meetingData.isNewMeetingPage === true &&
        !meetingData.singleMeetingDetails) ? (
        <form className="mt-2 details-form details-form-right">
          <Alert
            status={"info"}
            message={
              "Meeting preview will be available once you enter meeting details."
            }
            timeoutSeconds={0}
          />
          <div className="form-group mb-2">
            <div className="row">
              <NoDataFound dataType={"meeting"} />
            </div>
          </div>
        </form>
      ) : !meetingData.loading && meetingData.singleMeetingDetails ? (
        <form className="mt-2 details-form details-form-right">
          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting Id</label>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <p>{meetingData.singleMeetingDetails.meetingId}</p>
              </div>
            </div>
          </div>
          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Title</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>{meetingData.singleMeetingDetails.title}</p>
              </div>
            </div>
          </div>

          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting Mode</label>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <p>{meetingData.singleMeetingDetails.mode}</p>
              </div>
            </div>
          </div>

          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Location</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {meetingData?.singleMeetingDetails?.locationDetails
                    .isMeetingRoom === true
                    ? meetingData.singleMeetingDetails?.roomDetail[0]?.title +
                      ", " +
                      meetingData?.singleMeetingDetails?.roomDetail[0]?.location+ ", " +
                      meetingData?.singleMeetingDetails?.unitDetail?.name
                    : meetingData?.singleMeetingDetails?.locationDetails
                        ?.location}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting Link</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {meetingData.singleMeetingDetails.link
                    ? meetingData.singleMeetingDetails.link
                    : (meetingData.singleMeetingDetails.linkType==="ZOOM" && !meetingData.singleMeetingDetails.link)?
                    "Zoom link will be added once schedule the meeting":"NA"}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group mb-2">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Date & Time</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {meetingData.singleMeetingDetails.date},
                  {meetingData.singleMeetingDetails.fromTime} to{" "}
                  {meetingData.singleMeetingDetails.toTime}{" "}
                </p>
              </div>
            </div>
          </div>
          {meetingData.singleMeetingDetails.attendees.length > 0 ? (
            <div className="form-group mb-2">
              {meetingData?.singleMeetingDetails?.attendees.filter(
                (attendee) => attendee?.isEmployee === true
              ).length > 0 && (
                <div className="row align-items-center mt-2">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <label className="pb-1"> Attendee(s) </label>
                  </div>
                  <div
                    className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                    onClick={() => {
                      setOnlyAttendeeModalStatus(
                        true,
                        meetingData?.singleMeetingDetails?.attendees
                      );
                    }}
                  >
                    <div className="attendees">
                      {meetingData.singleMeetingDetails.attendees
                        .filter((attendee) => attendee.isEmployee === true)
                        .map((attendee) => (
                          <div className="attendee-list" key={attendee._id}>
                            {customName(attendee.name)}
                          </div>
                        ))}

                      {meetingData.singleMeetingDetails.attendees.filter(
                        (attendee) => attendee.isEmployee === true
                      ).length > 5 && (
                        <p className="m-0">
                          +
                          {meetingData.singleMeetingDetails.attendees.filter(
                            (attendee) => attendee.isEmployee === true
                          ).length - 5}{" "}
                          More
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {meetingData?.singleMeetingDetails?.attendees.filter(
                (attendee) => attendee?.isEmployee === false
              ).length > 0 && (
                <div className="row align-items-center mt-2">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <label className="pb-1"> Guest(s) </label>
                  </div>
                  <div
                    className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                    onClick={() => {
                      setGuestModalStatus(
                        true,
                        meetingData?.singleMeetingDetails?.attendees
                      );
                    }}
                  >
                    <div className="attendees">
                      {meetingData.singleMeetingDetails.attendees
                        .filter((attendee) => attendee.isEmployee === false)
                        .map((attendee) => (
                          <div className="attendee-list" key={attendee._id}>
                            {customName(attendee.name)}
                          </div>
                        ))}

                      {meetingData.singleMeetingDetails.attendees.filter(
                        (attendee) => attendee.isEmployee === false
                      ).length > 5 && (
                        <p className="m-0">
                          +
                          {meetingData.singleMeetingDetails.attendees.filter(
                            (attendee) => attendee.isEmployee === false
                          ).length - 5}{" "}
                          More
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <form className="mt-3 details-form-right">
              <Alert
                status={"info"}
                message={"No Attendee Added"}
                timeoutSeconds={0}
              />
              <div className="form-group mb-0 no-image">
                <NoDataFound dataType={"attendee"} />
              </div>
            </form>
          )}
          {meetingData.singleMeetingDetails.agendasDetail.length !== 0 ? (
            <div className="form-group agenda mt-4">
              <label className="mt-3 mb-3">
                <h4>Agenda(s)</h4>
              </label>
              {meetingData.singleMeetingDetails.agendasDetail.length !== 0 &&
                meetingData.singleMeetingDetails.agendasDetail.map(
                  (agenda, index) => {
                    return (
                      <div className="agenda-box-border">
                        <div className="row">
                          <div className="col-12">
                            <label className="mt-1 p-3 topic-head">
                              {" "}
                              Agenda {index + 1}
                            </label>
                          </div>
                        </div>

                        <div className="p-3">
                          <div className="form-group">
                            <div className="row">
                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                <label className="mt-1 mb-1">
                                  Agenda Title
                                </label>
                              </div>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                <p> {agenda.title}</p>
                              </div>
                            </div>
                          </div>
                          {agenda.topic !== "" ? (
                            <div className="form-group">
                              <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                  <label className="mt-2 topic">
                                    Topic to Discuss
                                  </label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                  <p className="mb-2">{agenda.topic} </p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <div className=" form-group">
                            <div className="row">
                              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                <label className="mt-1 mb-1">Timeline</label>
                              </div>
                              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                <p> {agenda.timeLine} Min</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
            </div>
          ) : meetingData.singleMeetingDetails.attendees.length > 0 ? (
            <form className="mt-2 details-form-right">
              <Alert
                status={"info"}
                message={"No Agenda Added"}
                timeoutSeconds={0}
              />
              <div className="form-group mb-0 no-image">
                <NoDataFound dataType={"agenda"} />
              </div>
            </form>
          ) : null}
        </form>
      ) : meetingData.isLoading ? (
        <form className="mt-2 details-form details-form-right">
          <div className="form-group mb-2 loader-icon">
            <div className="row">
              <Loader />
            </div>
          </div>
        </form>
      ) : null}
      <AttendeesModal
        IsModalOpen={isModalOpen}
        attendees={attendeesData}
        setIsModalOpen={setIsModalOpen}
        loginUserData={employeeData}
        isShow={false}
      />
      <GuestAttendeesModal
        IsGuestModalOpen={isGuestModalOpen}
        attendees={attendeesData}
        setIsGuestModalOpen={setIsGuestModalOpen}
      />
      <OnlyAttendeeModal
        IsOnlyAttendeeModalOpen={isOnlyAttendeeModalOpen}
        attendees={attendeesData}
        setIsOnlyAttendeeModalOpen={setIsOnlyAttendeeModalOpen}
        meetingData={meetingData}
      />
    </>
  );
};

export default ViewMeeting;
