import {
  SET_ORGANIZATION_DATA,
  FAIL_REQUEST,
  MAKE_REQUEST,
} from "./actionTypes";

export const makeRequest = (data) => {
  return {
    type: MAKE_REQUEST,
    payload: data,
  };
};
export const failRequest = (err) => {
  return {
    type: FAIL_REQUEST,
    payload: err,
  };
};

export const setOrganizationData = (data) => {
  return {
    type: SET_ORGANIZATION_DATA,
    payload: data,
  };
};
