import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logOut } from "../../redux/actions/authActions/authAction";
import NoDataFound from "../Common/NoDataFound";
import Loader from "../Common/Loader";
import Alert from "../Common/Alert";
import {
  convertFirstLetterOfFullNameToCapital,
  convertFirstLetterToCapital,
  customName,
} from "../../helpers/commonHelpers";
import {
  getactionActivitiesDetails,
  processReassign,
} from "../../redux/actions/actionAction/ActionAction";
import ReassignModal from "./ReassignModal";
import OtherModal from "./OtherModal";

const ViewActionActivities = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }

  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activityDetails, setActivityDetails] = useState(null);
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  const employeeData = useSelector((state) => state.user);
  const actionData = useSelector((state) => state.action);
  const [actionId, setActionId] = useState(null);
  const [actionDatas, setActionDatas] = useState(null);

  useEffect(() => {
    if (accessToken) {
      dispatch(getactionActivitiesDetails(props.actionId, accessToken));
    }

    return () => {
      // Anything in here is fired on component unmount.
    };
  }, [actionData?.actionActivitiesDetails?.length, actionData?.isSuccess]);

  const handleModal = (activity) => {
    setActivityDetails(activity);
    setIsModalOpen(true);
  };
  const handleReassignModal = (action, activity) => {
    setActivityDetails(activity);
    setActionId(action._id);
    setActionDatas(action);
    setIsReassignModalOpen(true);
  };

  const handleReassign = (data, status) => {
    if (status) {
      dispatch(
        processReassign(
          actionId,
          {
            organizationId: userData.organizationId,
            reAssignedId: data.attendeeId !== "" ? data.attendeeId : undefined,
            reAssignReason: data.reason,
            isNewUser: data.name !== "" && data.email !== "" ? true : false,
            name: data.name,
            email: data.email,
            dueDate: data.date ? data.date : undefined,
            priority: data.priority,
          },
          accessToken
        )
      );
    } else {
      dispatch(
        processReassign(
          actionId,
          {
            organizationId: userData.organizationId,
            reAssignedId: data.attendeeId !== "" ? data.attendeeId : undefined,
            reAssignReason: data.reason,
            reassignedUserName: data.reassignedUserName
              ? data.reassignedUserName
              : undefined,
            isNewUser: data.name !== "" && data.email !== "" ? true : false,
            name: data.name,
            email: data.email,
            dueDate: data.date ? data.date : undefined,
            priority: data.priority,
          },
          accessToken
        )
      );
    }
    setActionId(null);
  };
  return (
    <div className="form-group agenda">
      <div className="left-detailbox mt-3">
        <h4 className="Detailsheading">Action Activities</h4>
        <div className="form-group mb-0">
          {actionData?.actionActivitiesDetails?.length > 0 &&
          !actionData.loading ? (
            <>
              {actionData?.actionActivitiesDetails.map((activity) => {
                return (
                  <>
                    {activity?.reAssignedUserDetails?.length > 0 &&
                    activity.activityTitle !== "ACTION REOPENED" ? (
                      <>
                        <div className="inner-notification req-by">
                          <div>
                            <div className="action-comment-circle sl">
                              {" "}
                              {customName(activity.employeeDetails?.name)}
                            </div>
                          </div>
                          <div className="text">
                            <div className="heading">
                              {" "}
                              {convertFirstLetterToCapital(
                                activity?.activityTitle
                              )}{" "}
                              to{" "}
                              {convertFirstLetterOfFullNameToCapital(
                                activity?.reAssignedUserDetails[0]?.name
                              )}
                            </div>
                            <div className="date">{activity.date}</div>
                          </div>
                        </div>
                        <br></br>
                        <div className="inner-notification req-by">
                          <div>
                            <div className="action-comment-circle sl">
                              {" "}
                              {customName(activity.employeeDetails?.name)}
                            </div>
                          </div>
                          <div className="text">
                            <div className="heading">
                              {" "}
                              {convertFirstLetterToCapital(
                                activity.activityTitle
                              )}{" "}
                              by{" "}
                              {convertFirstLetterOfFullNameToCapital(
                                activity.employeeDetails?.name
                              )}
                            </div>
                            <div className="date">{activity.date}</div>
                            {activity.activityDetails ? (
                              <div className="mt-3">
                                <button
                                  className="reset"
                                  type="button"
                                  onClick={() => {
                                    handleModal(activity);
                                  }}
                                >
                                  <p>View Details</p>
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="inner-notification req-by">
                        <div>
                          <div className="action-comment-circle sl">
                            {" "}
                            {customName(activity.employeeDetails?.name)}
                          </div>
                        </div>
                        <div className="text">
                          <div className="heading">
                            {" "}
                            {convertFirstLetterToCapital(
                              activity.activityTitle
                            )}{" "}
                            by{" "}
                            {convertFirstLetterOfFullNameToCapital(
                              activity.employeeDetails?.name
                            )}
                          </div>

                          <div className="date">{activity.date}</div>
                          {activity.activityDetails ? (
                            <div className="mt-3">
                              {activity.activityTitle !== "ACTION REOPENED" &&
                              actionData?.singleActionDetails?.isRequested ===
                                true &&
                              activity.activityTitle !== "ACTION COMPLETED" &&
                              (employeeData.userData?.isAdmin ||
                                employeeData.userData?.isMeetingOrganiser) &&
                              actionData?.singleActionDetails?.isComplete ===
                                false &&
                              actionData?.singleActionDetails
                                ?.assignedUserId ===
                                actionData?.singleActionDetails?.reassigneRequestDetails[
                                  actionData?.singleActionDetails
                                    ?.reassigneRequestDetails?.length - 1
                                ]?.userId?.toString() ? (
                                <button
                                  className="reset"
                                  type="button"
                                  onClick={() => {
                                    handleReassignModal(
                                      props.action,
                                      activity.activityDetails
                                    );
                                  }}
                                >
                                  <p>View & Take Action</p>
                                </button>
                              ) : (
                                <button
                                  className="reset"
                                  type="button"
                                  onClick={() => {
                                    handleModal(activity);
                                  }}
                                >
                                  <p>View Details</p>
                                </button>
                              )}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    )}
                    <br></br>
                  </>
                );
              })}
            </>
          ) : actionData?.actionActivitiesDetails?.length === 0 &&
            !actionData.loading ? (
            <div className="no-data-img">
              <Alert
                status={"info"}
                message={"No data available."}
                timeoutSeconds={0}
              />
              <NoDataFound dataType={"meetingActivities"} />
            </div>
          ) : (
            <div
              className="meeting-page "
              style={{ textAlign: "center", paddingTop: 20 }}
            >
              <Loader />
            </div>
          )}
        </div>
      </div>

      <ReassignModal
        setIsReassignModalOpen={setIsReassignModalOpen}
        isModalOpen={isReassignModalOpen}
        handleSubmit={handleReassign}
        activity={activityDetails}
        actionId={props.actionId}
        actionData={actionDatas}
      />

      <OtherModal
        setIsModalOpen={setIsModalOpen}
        IsModalOpen={isModalOpen}
        activity={activityDetails}
      />
    </div>
  );
};

export default ViewActionActivities;
