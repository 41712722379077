import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import * as constantMessages from "../../constants/constatntMessages";
import { editAgenda } from "../../redux/actions/minuteActions/MinuteAction";
import { checkCharacterLeft } from "../../helpers/commonHelpers";
import { textBoxStrLength } from "../../config/config";
const EditAgendaModal = (props) => {
  const regularExpression = /^[0-9a-zA-Z .(),/-]+$/;
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("accessToken");
  const minuteData = useSelector((state) => state.minute);
  const { agendaData, meetingData } = props;
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    title: "",
    topic: "",
    timeLine: "0",
  });

  useEffect(() => {
    setFormData({
      ...formData,
      title: agendaData?.title,
      topic: agendaData?.topic,
      timeLine: agendaData?.timeLine,
    });
    if (minuteData.isSuccess) {
      props.setIsEditAgendaModal(false);
    }
  }, [props.IsEditAgendaModal, minuteData.isSuccess]);

  const handleSubmit = () => {
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const payload = {
        ...formData,
        organizationId: agendaData.organizationId,
        meetingId: agendaData.meetingId,
      };

      dispatch(editAgenda(payload, agendaData._id, accessToken));
    }
  };

  const validateAgendaTitle = () => {
    const errors = {};
    if (!formData?.title.trim()) {
      errors.title = constantMessages.titleRequired;
    } else if (
      formData.title.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.title = constantMessages.stringLengthError;
    } else if (!regularExpression.test(formData.title)) {
      errors.title = constantMessages.titleRegexError;
    }
    setErrors(errors);
  };
  const validateAgendaTime = () => {
    const errors = {};

    if (formData.timeLine > 365 || formData.timeLine < 0) {
      errors.timeLine = constantMessages.invalidTime;
    }
    setErrors(errors);
  };

  const handleChange = (e, uid) => {
    setErrors({});

    const { name, value } = e.target;

    if (name === "topic") {
      if (value.length <= textBoxStrLength) {
        setFormData({
          ...formData,

          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,

        [name]: value,
      });
    }
  };

  const validateForm = () => {
    //
    const errors = {};

    if (!formData?.title.trim()) {
      errors.title = constantMessages.titleRequired;
    } else if (
      formData.title.trim().length < 5 ||
      formData.title.trim().length > 100
    ) {
      errors.title = constantMessages.stringLengthError;
    } else if (!regularExpression.test(formData.title)) {
      errors.title = constantMessages.titleRegexError;
    }

    if (formData.timeLine > 365 || formData.timeLine < 0) {
      errors.timeLine = constantMessages.invalidTime;
    }

    return errors;
  };

  return (
    <>
      <Modal
        show={props.IsEditAgendaModal}
        onHide={(e) => props.setIsEditAgendaModal(false)}
      >
        <Modal.Dialog>
          <div className="modal-header attendees-modal">
            <div>
              <h4 className="modal-title">Edit Agenda</h4>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={(e) => props.setIsEditAgendaModal(false)}
              ></button>
            </div>
          </div>

          <Modal.Body className="attendees-popup modal-margin">
            <div className="open-div">
              <div>
                <div className="form-group">
                  <div className="mb-2">
                    <div className="row">
                      <div className="col-md-4">
                        <label className="mb-1">
                          Agenda Title <span className="star-mark">*</span>
                        </label>
                      </div>
                      <div className="col-md-8">
                        <input
                          type="text"
                          placeholder="Enter agenda title"
                          name="title"
                          value={formData.title}
                          onChange={handleChange}
                          onBlur={validateAgendaTitle}
                          autoComplete="off"
                        />

                        {errors.title ? (
                          <span className="error-message">{errors.title}</span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4">
                      <label className="mb-1">
                        What are the topic to discuss ?
                      </label>
                    </div>
                    <div className="col-md-8">
                      <textarea
                        name="topic"
                        value={formData.topic}
                        onChange={handleChange}
                        autoComplete="off"
                        placeholder="Enter issue to discuss..."
                        id=""
                        cols="56"
                        rows="4"
                      ></textarea>

                      <p className="success-message">
                        {checkCharacterLeft(
                          formData.topic ? formData.topic : ""
                        )}{" "}
                        Characters left
                      </p>
                    </div>
                  </div>
                </div>

                <div className="form-group m-0">
                  <div className="row">
                    <div className="col-md-4">
                      <label className="mb-1">
                        How long will this agenda item take to discuss?
                      </label>
                    </div>
                    <div className="col-md-8">
                      <div className="time-taken">
                        <input
                          name="timeLine"
                          value={formData.timeLine}
                          onChange={handleChange}
                          onBlur={validateAgendaTime}
                          required="required"
                          type="number"
                          autocomplete="off"
                        />
                        <div className="minute_box">mins</div>
                      </div>
                      {errors.timeLine && (
                        <span className="error-message">{errors.timeLine}</span>
                      )}
                    </div>
                  </div>

                  <div className="mt-3 plus pointer"></div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="light"
              onClick={(e) => props.setIsEditAgendaModal(false)}
              className="btn-light"
            >
              <p>Cancel</p>
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              className="Mom-btn"
            >
              <p>Update</p>
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default EditAgendaModal;
