import React from "react";
// @ts-ignore
import { Modal } from "react-bootstrap";
import { customName } from "../../helpers/commonHelpers";

const GuestAttendeesModal = (props) => {
  const { attendees, IsGuestModalOpen, setIsGuestModalOpen } = props;

  return (
    <Modal
      show={IsGuestModalOpen}
      onHide={() => setIsGuestModalOpen(false)}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Guest(s)</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="attendees-popup">
          {attendees &&
            attendees
              .filter((item) => !item.empId)
              .map((attendee, index) => {
                return (
                  <div className="attendee-content" key={index}>
                    <div key={index}>
                      <div className="attendee1 attendee-list sl">
                        {" "}
                        {customName(attendee.name)}
                      </div>
                    </div>

                    <div className="action-cmnt-text">
                      <p className="detail-name">{attendee.name}</p>

                      <p className="name-undertext comment-text">
                        {attendee.email}
                      </p>
                      {attendee?.designation ? (
                        <p className="detail-date-time mt-7">
                          Designation - {attendee.designation}
                        </p>
                      ) : null}
                      {attendee?.companyName ? (
                        <p className="detail-date-time mt-7">
                          Company Name - {attendee.companyName}
                        </p>
                      ) : null}
                    </div>
                  </div>
                );
              })}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default GuestAttendeesModal;
