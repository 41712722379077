import React from "react";
import Select from "react-select";

const DesignationDropdown = ({
  designations,
  employeeData,
  handleChange,
  errors,
}) => {
  const designationOptions = designations
    .filter((des) => des.isActive == true)
    .map((designation) => ({
      value: designation._id,
      label: designation.name,
    }));
  const test = designations.filter(
    (designation) => designation.isActive == false
  );

  const handleDesignationChange = (selectedOption) => {
    handleChange({
      target: {
        name: "designationId",
        value: selectedOption ? selectedOption.value : "",
      },
    });
  };

  return (
    <div className="col-md-4 col-sm-6">
      <div className="form-group">
        <label className="mb-1">
          Designation<span className="star-mark"> *</span>
        </label>
        <Select
          name="designationId"
          value={
            designationOptions.find(
              (option) => option.value === employeeData?.designationId
            ) || null
          }
          onChange={handleDesignationChange}
          options={designationOptions}
          placeholder="Select Designation"
        />
        {errors.designationId && (
          <span className="error-message">{errors?.designationId}</span>
        )}
      </div>
    </div>
  );
};
export default DesignationDropdown;
