import React, { useEffect, useState } from "react";
import Select from "react-select";

const EditUnitDropdown = ({
  units,
  selectedUnitId,
  handleChange,
  value,
  errors,
}) => {
  const [unit, SetUnits] = useState(value);
  useEffect(() => {
    SetUnits(value);
  }, []);
  const unitOption = units
    .filter((un) => un.isActive)
    .map((unit) => ({
      value: unit?._id,
      label: unit?.name,
    }));
  const handleUnitChange = (selectedOption) => {
    SetUnits({
      name: "unitId",
      label: selectedOption ? selectedOption.label : "",
      value: selectedOption ? selectedOption.value : "",
    });
    handleChange({
      name: "unitId",
      label: selectedOption ? selectedOption.label : "",
      value: selectedOption ? selectedOption.value : "",
    });
  };
  return (
    <div className="form-group  mt-2">
      <label className="mb-1">
        Units<span className="star-mark"> *</span>
      </label>
      <Select
        name="designationId"
        value={unit}
        onChange={handleUnitChange}
        options={unitOption}
        placeholder="Select Unit"
      />
      {errors?.designationId && (
        <span className="error-message">{errors?.unitId}</span>
      )}
    </div>
  );
};
export default EditUnitDropdown;
