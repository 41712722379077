import React, { useState, useEffect } from "react";
import "../../App.css";
import { useSelector, useDispatch } from "react-redux";
import Badge from "react-bootstrap/Badge";
import {
  generateMOM,
  getAgendaWithMinutesDetails,
  unSetSingleMeetingDetails,
} from "../../redux/actions/meetingActions/MeetingAction";
import { toast } from "react-toastify";
import {
  combineDateAndTime,
  convertFirstLetterOfFullNameToCapital,
  customName,
  formatDateTimeFormat,
} from "../../helpers/commonHelpers";
import {
  acceptMOM,
  processAmendmentRequest,
  setSuccess,
  updateAmendmentRequest,
} from "../../redux/actions/minuteActions/MinuteAction";
import MinutesRsvpStatusModal from "./MinutesRsvpStatusModal";
import AttendeesModal from "../Meeting/AttendeesModal";
import LoaderButton from "../Common/LoaderButton";
import AmendmentRequestModal from "./AmendmentRequestModal";
import AmendmentRequestViewModal from "./AmendmentRequestViewModal";
import MeetingActivities from "../Meeting/MeetingActivities";
import EditMinuteModal from "./EditMinuteModal";
import DeleteMeetingModal from "./DeleteMeetingModal";
import MeetingAttendance from "../Meeting/MeetingAttendance";
import AddAttendeesModal from "../Meeting/AddAttendeesModal";
import AttendanceModal from "./AttendanceModal";
import RescheduleMeetingModal from "../Meeting/RescheduleMeetingModal";
import MinutesActionDropDown from "./MinutesActionDropDown";
import GenerateMOMModal from "./GenerateMOMModal";

const ViewMinutes = (props) => {
  const accessToken = localStorage.getItem("accessToken");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const dispatch = useDispatch();
  const employeeData = useSelector((state) => state.user);

  const minuteData = useSelector((state) => state.minute);
  const meetingData = useSelector((state) => state.meeting);
  const [isMinutesRsvpStatusModal, setIsMinutesRsvpStatusModal] =
    useState(false);
  const [isRescheduleModalOpen, setIsRescheduleModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attendeesData, setAttendeesData] = useState([]);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isAttendaceModalOpen, setIsAttendaceModalOpen] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const [isDeleteMinuteModal, setIsDeleteMinuteModal] = useState(false);
  const [isEditMinuteModal, setIsEditMinuteModal] = useState(false);
  const [minuteDetails, setMinuteDetails] = useState(null);
  const [amendmentModal, setAmendmentModal] = useState(false);
  const [createdById, setCreatedById] = useState(null);
  const [details, setDetails] = useState(null);
  const [amendmentViewModal, setAmendmentViewModal] = useState(false);
  const [isAddAttendeeModal, setIsAddAttendeeModal] = useState(false);
  const [status, setStatus] = useState(null);
  const [minuteId, setMinuteId] = useState(null);
  useEffect(() => {
    if (meetingData.isFetchedMeetingList) {
      if (meetingData?.meetingDetails?._id && accessToken) {
        dispatch(
          getAgendaWithMinutesDetails(
            meetingData?.meetingDetails?._id,
            accessToken
          )
        );
      }
    }
    if (meetingData?.isSuccess === true) {
      setIsCloseModalOpen(false);
    }
    return () => {
      dispatch(unSetSingleMeetingDetails);
    };
  }, [meetingData.isFetchedMeetingList, meetingData?.isSuccess]);

  useEffect(() => {
    if (meetingData?.isSuccess === true) {
      setIsCloseModalOpen(false);
    }

    return () => {};
  }, [meetingData?.isSuccess]);

  const handleAmendmentRequest = (details) => {
    const payload = {
      details,
      meetingId: meetingData?.meetingDetails?._id,
    };

    dispatch(processAmendmentRequest(payload, minuteId, accessToken));
    setMinuteId(null);
    setAmendmentModal(false);
  };

  const handleAmendmentUpdate = (status) => {
    const payload = {
      createdById,
      status,
      meetingId: meetingData.meetingDetails._id,
    };

    dispatch(updateAmendmentRequest(payload, minuteId, accessToken));
    setMinuteId(null);
    setDetails(null);
    setCreatedById(null);
    setAmendmentViewModal(false);
  };

  const handleAmendmentUpdateByButton = (status, createdById, minuteId) => {
    const payload = {
      createdById,
      status,
      meetingId: meetingData.meetingDetails._id,
    };

    dispatch(updateAmendmentRequest(payload, minuteId, accessToken));
    setMinuteId(null);
    setDetails(null);
    setCreatedById(null);
    setAmendmentViewModal(false);
  };

  const handleAmendmentUpdateModal = (minuteId, details, createdById) => {
    setMinuteId(minuteId);
    setDetails(details);
    setCreatedById(createdById);
    setAmendmentViewModal(true);
  };

  const updateIsMinutesRsvpStatusModal = (status, attendees) => {
    if (attendees.length !== 0) {
      setStatus(status);
      setAttendeesData(attendees);
      setIsMinutesRsvpStatusModal(!isMinutesRsvpStatusModal);
    }
  };

  const setModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsModalOpen(value);
      setAttendeesData([...attendeesData]);
    }
  };

  const setAttendanceModalStatus = (value, attendeesData) => {
    if (attendeesData.length !== 0) {
      setIsAttendaceModalOpen(value);

      setAttendeesData([...attendeesData]);
    }
  };

  const setAddAttendeesModal = (status, attendeesData) => {
    setAttendeesData([...attendeesData]);
    setIsAddAttendeeModal(status);
  };

  const setIsAgendaShowModal = (value) => {
    setIsAgendaShowModal(value);
  };

  const handleEditMinuteModal = (status, minute) => {
    dispatch(setSuccess(false));
    setMinuteDetails(minute);
    setIsEditMinuteModal(status);
  };
  const handleDeleteMinuteModal = (status, minute) => {
    dispatch(setSuccess(false));
    setMinuteDetails(minute);
    setIsDeleteMinuteModal(status);
  };
  const momId =
    meetingData?.meetingDetails?.momGenerationDetails?.length !== 0
      ? meetingData?.meetingDetails?.momGenerationDetails[
          meetingData?.meetingDetails?.momGenerationDetails?.length - 1
        ]?.momId
      : null;
  const acceptMOMData = (meetingId) => {
    if (isAccepted) {
      dispatch(acceptMOM(meetingId, { momId }, accessToken));
    } else {
      toast.warning("Please accept MOM condition.", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const generateMOMDetails = () => {
    const meetingId = props.meetingId
      ? props.meetingId
      : meetingData.meetingDetails?._id;
    const payload = {
      organizationId: userData.organizationId,
      attendees: meetingData?.meetingDetails?.attendees?.map(
        ({
          rsvp,
          isAttended,
          isEmployee,
          empId,
          designation,
          companyName,
          ...keepAttrs
        }) => keepAttrs
      ),
    };
    dispatch(generateMOM(meetingId, payload, accessToken));
  };
  const isMomAccepted =
    meetingData?.meetingDetails?.momAcceptDetails?.length > 0 &&
    meetingData?.meetingDetails?.momAcceptDetails?.find(
      (user) =>
        user?.userId?.toString() == employeeData?.userData?._id.toString()
    )
      ? true
      : false;

  const meetingDateTime = combineDateAndTime(
    new Date(meetingData?.meetingDetails?.actualDate),
    meetingData?.meetingDetails?.actualFromTime
  ).getTime();

  const currentDateTime = new Date().getTime();
  const isWriteMinuteAllowed =
    meetingDateTime <= currentDateTime ? true : false;

  const isStatusAllowd =
    meetingData?.meetingDetails?.meetingStatus?.status === "scheduled" ||
    meetingData?.meetingDetails?.meetingStatus?.status === "rescheduled"
      ? true
      : false;

  const handleGenerateMOMModal = () => {
    setIsCloseModalOpen(true);
  };

  return (
    <>
      <div className="save-meet-head">
        <div className="meeting-header-text">
          {props.meetingId ? <h4>MOM Details</h4> : <h4>MOM Preview</h4>}
        </div>
        {!props.meetingId &&
        (employeeData?.userData?.isMeetingOrganiser === true ||
          employeeData?.userData?.isAdmin === true) ? (
          <div className="save-meet-btn">
            <div className="row">
              <div className="col">
                {meetingData?.meetingDetails?.isMinutesAdded ? (
                  <div className="d-inline-block">
                    <button
                      className="Mom-btn"
                      onClick={handleGenerateMOMModal}
                    >
                      <p>Generate MOM</p>
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {meetingData.meetingDetails ? (
        <form className="mt-2 details-form details-form-right">
          {meetingData?.meetingDetails?.parentMeetingDetails?.length !== 0 ? (
            <>
              <div className="form-group">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <label className="mb-1">Parent Meeting ID</label>
                  </div>
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <p>
                      {
                        meetingData?.meetingDetails?.parentMeetingDetails[0]
                          .meetingId
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <label className="mb-1">Parent Meeting Title</label>
                  </div>
                  <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                    <p>
                      {
                        meetingData?.meetingDetails?.parentMeetingDetails[0]
                          .title
                      }
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          <div className="form-group">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting ID</label>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <p>{meetingData.meetingDetails?.meetingId}</p>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Title</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {convertFirstLetterOfFullNameToCapital(
                    meetingData.meetingDetails?.title
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting Mode</label>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <p>
                  {convertFirstLetterOfFullNameToCapital(
                    meetingData.meetingDetails?.mode
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group ">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Location</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {meetingData?.meetingDetails?.locationDetails
                    .isMeetingRoom === true
                    ? meetingData?.meetingDetails?.roomDetail[0]?.title +
                      ", " +
                      meetingData?.meetingDetails?.roomDetail[0]?.location+", "+  meetingData?.meetingDetails?.unitDetail?.name
                    : meetingData?.meetingDetails?.locationDetails?.location}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Meeting Link</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {meetingData.meetingDetails?.link
                    ? meetingData.meetingDetails?.link
                    : "NA"}
                </p>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <label className="mb-1">Date & Time</label>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                <p>
                  {
                    formatDateTimeFormat(meetingData.meetingDetails?.date)
                      .formattedDate
                  }
                  ,{meetingData.meetingDetails?.fromTime} to{" "}
                  {meetingData.meetingDetails?.toTime}{" "}
                </p>
              </div>
            </div>
          </div>

          {meetingData.meetingDetails.attendees.length !== 0 ? (
            <>
              <div className="row align-items-center mb-3">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                  <label className="pb-1"> Attendee(s) </label>
                </div>
                <div
                  className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                  onClick={(e) => {
                    setModalStatus(true, meetingData.meetingDetails?.attendees);
                  }}
                >
                  <div className="attendees">
                    {meetingData.meetingDetails?.attendees.map((attendee) => {
                      return (
                        <>
                          <div className="attendee-list">
                            {customName(attendee.name)}
                          </div>
                        </>
                      );
                    })}

                    <p className="m-0">
                      {meetingData.meetingDetails?.attendees.length > 5
                        ? `+${
                            meetingData.meetingDetails?.attendees.length - 5
                          } More`
                        : null}
                    </p>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12"></div>
                  <div
                    className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12"
                    onClick={(e) => {
                      setModalStatus(
                        true,
                        meetingData.meetingDetails?.attendees
                      );
                    }}
                  >
                    <div className="attendees"></div>
                  </div>
                </div>
                {!props.meetingId &&
                (employeeData?.userData?.isAdmin === true ||
                  employeeData?.userData?.isMeetingOrganiser) === true &&
                isWriteMinuteAllowed === true &&
                isStatusAllowd === true ? (
                  <>
                    <div className="button-position btn-outerbox mt-3">
                      <button
                        className="add-minutes Mom-btn mt-2 me-2"
                        type="button"
                        onClick={(e) => {
                          setAddAttendeesModal(
                            true,
                            meetingData.meetingDetails?.attendees
                          );
                        }}
                      >
                        <p>Update New Attendees</p>
                      </button>

                      <button
                        className="add-minutes Mom-btn mt-2 me-2"
                        type="button"
                        onClick={(e) => {
                          setAttendanceModalStatus(
                            true,
                            meetingData.meetingDetails?.attendees
                          );
                        }}
                      >
                        <p>Update Attendance & Time</p>
                      </button>
                    </div>
                  </>
                ) : null}
              </div>
            </>
          ) : null}
          <div className="minutes-border"></div>
          {meetingData?.agendasDetail?.length !== 0 ? (
            <div className="form-group agenda">
              <label className="mt-3 mb-3 add-agenda">
                <h4>Agenda(s)</h4>
              </label>
              {meetingData?.agendaDetails?.length !== 0 &&
                meetingData?.agendaDetails?.map((agenda, index) => {
                  return (
                    <div className="mt-2 agenda-box-border">
                      <div className="form-group  m-0">
                        <div className="topic-head p-2 d-flex align-items-center justify-content-between">
                          <div>
                            <label> Agenda {index + 1}</label>
                          </div>
                        </div>
                      </div>

                      <div className="p-3">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                              <label className="mt-1 mb-1">Agenda Title</label>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                              <p> {agenda.title}</p>
                            </div>
                          </div>
                        </div>
                        {agenda.topic !== "" ? (
                          <>
                            <div className="form-group">
                              <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                  <label className="mt-2 topic">
                                    Topic to Discuss
                                  </label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                  <p className="mb-2">{agenda.topic} </p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                        <div className=" form-group">
                          <div className="row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                              <label className="mt-1 mb-1">Timeline</label>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                              <p> {agenda.timeLine} Min</p>
                            </div>
                          </div>
                        </div>
                        <div className=" form-group m-0">
                          <div className="row">
                            <div className="col-md-4">
                              <label className="mt-1 mb-1">Total Minutes</label>
                            </div>
                            <div className="col-md-8">
                              <p> {agenda?.minutesDetail?.length}</p>
                            </div>
                          </div>
                        </div>

                        <div className=" form-group m-0">
                          <div className="row">
                            <div className="col-md-4">
                              <label className="mt-1 mb-1">Status</label>
                            </div>
                            <div className="col-md-8">
                              <p>
                                {" "}
                                {agenda?.minutesDetail?.length !== 0 ? (
                                  <Badge bg="success"> Discussed</Badge>
                                ) : (
                                  <Badge bg="danger"> Pending</Badge>
                                )}
                              </p>
                            </div>
                          </div>
                        </div>

                        {agenda?.minutesDetail?.filter(
                          (item) => item.isActive === true
                        ).length !== 0 ? (
                          <>
                            <div></div>
                            <div className=" minutes-border"></div>

                            {agenda?.minutesDetail?.filter(
                              (item) => item.isActive === true
                            ).length !== 0 &&
                              agenda?.minutesDetail
                                ?.filter((item) => item.isActive === true)
                                .map((minute, index) => {
                                  return (
                                    <>
                                      <div className="agenda-minutes">
                                        <div className=" form-group ">
                                          <div className="row">
                                            <div className="col-md-4">
                                              <label className="mt-1 mb-1">
                                                Minutes {index + 1}
                                              </label>
                                            </div>
                                            <div className="col-md-6">
                                              <p> {minute.description}</p>
                                            </div>
                                            {minute.status ===
                                            "PUBLISHED" ? null : (
                                              <div className="col-md-2 d-flex justify-content-end">
                                                <div className="btn-outerbox">
                                                  {!props.meetingId &&
                                                  (employeeData?.userData
                                                    ?.isMeetingOrganiser ===
                                                    true ||
                                                    employeeData?.userData
                                                      ?.isAdmin === true ||
                                                    meetingData?.meetingDetails
                                                      ?.canWriteMOM ===
                                                      true) ? (
                                                    <div className="d-inline-block menu-dropdown custom-dropdown">
                                                      <MinutesActionDropDown
                                                        minuteId={minute._id}
                                                        isShowDelete={true}
                                                        handleRequestForEditModal={() => {
                                                          handleEditMinuteModal(
                                                            true,
                                                            minute
                                                          );
                                                        }}
                                                        handleRequestForDeleteModal={() => {
                                                          handleDeleteMinuteModal(
                                                            true,
                                                            minute
                                                          );
                                                        }}
                                                        minute={minute}
                                                      />
                                                    </div>
                                                  ) : null}
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                          <div className="row">
                                            <div className="col-md-4">
                                              <label className="mt-1 mb-1">
                                                Status
                                              </label>
                                            </div>
                                            <div className="col-md-6">
                                              <p>
                                                <Badge bg="info">
                                                  {minute.status}
                                                </Badge>
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        {minute.isAction ? (
                                          <>
                                            <div className=" form-group">
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <label className="mt-1 mb-1">
                                                    Action Due Date
                                                  </label>
                                                </div>
                                                <div className="col-md-8">
                                                  <p>
                                                    {" "}
                                                    {
                                                      formatDateTimeFormat(
                                                        minute?.mainDueDate
                                                      ).formattedDate
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                            </div>

                                            {minute?.parentMinuteId ? (
                                              <div className="form-group">
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <label className="mb-1 mb-1">
                                                      Assigned Due Date
                                                    </label>
                                                  </div>
                                                  <div className="col-md-8">
                                                    <p>
                                                      {" "}
                                                      {
                                                        formatDateTimeFormat(
                                                          minute?.dueDate
                                                        ).formattedDate
                                                      }
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}

                                            <div className=" form-group">
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <label className="mt-1 mb-1">
                                                    Priority
                                                  </label>
                                                </div>
                                                <div className="col-md-8">
                                                  <p>
                                                    {" "}
                                                    {minute.priority
                                                      .charAt(0)
                                                      .toUpperCase() +
                                                      minute.priority
                                                        .slice(1)
                                                        .toLowerCase()}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                            {minute?.isAction ? (
                                              <div className=" form-group">
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <label className="mt-1 mb-1">
                                                      Responsible Person
                                                    </label>
                                                  </div>
                                                  <div className="col-md-8">
                                                    <p>
                                                      {minute
                                                        ?.assignedUserDetails
                                                        ?.name
                                                        ? convertFirstLetterOfFullNameToCapital(
                                                            minute
                                                              ?.assignedUserDetails
                                                              ?.name
                                                          )
                                                        : null}
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}
                                          </>
                                        ) : null}

                                        {minute.reassignDetails ? (
                                          <div className=" form-group">
                                            <div className="row">
                                              <div className="col-md-4">
                                                <label className="mt-1 mb-1">
                                                  Reassigned To
                                                </label>
                                              </div>
                                              <div className="col-md-8 detail ">
                                                <p>Rakesh Baral</p>
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}

                                        {minute?.attendees?.length == 0 ? (
                                          <>
                                            <div className="form-group mt-4">
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <label className="mt-2 topic">
                                                    Accepted By
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-8"
                                                  onClick={() =>
                                                    updateIsMinutesRsvpStatusModal(
                                                      "Accepted",
                                                      minute?.attendees?.filter(
                                                        (attendee) =>
                                                          attendee.status ===
                                                          "ACCEPTED"
                                                      )
                                                    )
                                                  }
                                                >
                                                  {minute?.attendees?.filter(
                                                    (attendee) =>
                                                      attendee.status ===
                                                      "ACCEPTED"
                                                  ).length !== 0 &&
                                                    minute?.attendees
                                                      ?.filter(
                                                        (attendee) =>
                                                          attendee.status ===
                                                          "ACCEPTED"
                                                      )
                                                      .map((attendee) => {
                                                        return (
                                                          <>
                                                            <div className="attendees mb-2">
                                                              <div className="attendee-list">
                                                                {customName(
                                                                  attendee.name
                                                                )}
                                                              </div>
                                                              {}

                                                              <p className="plus-more-text m-0">
                                                                {minute
                                                                  .attendees
                                                                  .length > 5
                                                                  ? `+${
                                                                      minute
                                                                        .attendees
                                                                        .length -
                                                                      5
                                                                    } More`
                                                                  : null}
                                                              </p>
                                                            </div>
                                                          </>
                                                        );
                                                      })}

                                                  <p>
                                                    Accepted by{" "}
                                                    {
                                                      minute?.attendees?.filter(
                                                        (attendee) =>
                                                          attendee.status ===
                                                          "ACCEPTED"
                                                      ).length
                                                    }
                                                    /{minute.attendees.length}{" "}
                                                    attendants
                                                  </p>
                                                </div>
                                              </div>
                                            </div>

                                            <div className="form-group mt-4">
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <label className="mt-2 topic">
                                                    Rejected By
                                                  </label>
                                                </div>
                                                <div
                                                  className="col-md-8"
                                                  onClick={() =>
                                                    updateIsMinutesRsvpStatusModal(
                                                      "Rejected",
                                                      minute?.attendees?.filter(
                                                        (attendee) =>
                                                          attendee.status ===
                                                          "REJECTED"
                                                      )
                                                    )
                                                  }
                                                >
                                                  {minute?.attendees?.filter(
                                                    (attendee) =>
                                                      attendee.status ===
                                                      "REJECTED"
                                                  ).length !== 0 &&
                                                    minute?.attendees
                                                      ?.filter(
                                                        (attendee) =>
                                                          attendee.status ===
                                                          "REJECTED"
                                                      )
                                                      .map((attendee) => {
                                                        return (
                                                          <>
                                                            <div className="attendees mb-2">
                                                              <div className="attendee-list">
                                                                {customName(
                                                                  attendee.name
                                                                )}
                                                              </div>
                                                              {}

                                                              <p className="plus-more-text m-0">
                                                                {minute
                                                                  .attendees
                                                                  .length > 5
                                                                  ? `+${
                                                                      minute
                                                                        .attendees
                                                                        .length -
                                                                      5
                                                                    } More`
                                                                  : null}
                                                              </p>
                                                            </div>
                                                          </>
                                                        );
                                                      })}

                                                  <p>
                                                    Rejected by{" "}
                                                    {
                                                      minute?.attendees?.filter(
                                                        (attendee) =>
                                                          attendee.status ===
                                                          "REJECTED"
                                                      ).length
                                                    }
                                                    /{minute.attendees.length}{" "}
                                                    attendants
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ) : null}
                                        {!employeeData.userData
                                          .isMeetingOrganiser &&
                                        props.meetingId ? (
                                          <div>
                                            <></>
                                            <div className=" minutes-border"></div>
                                          </div>
                                        ) : null}

                                        {employeeData?.userData
                                          ?.isMeetingOrganiser &&
                                        minute?.amendmentDetails?.length !==
                                          0 ? (
                                          <div className="added-by">
                                            {minute?.amendmentDetails?.map(
                                              (amendment) => {
                                                return (
                                                  <>
                                                    <div className="add-agenda">
                                                      <p className="add-text">
                                                        Amendment Requested By
                                                      </p>
                                                      <div className="attendee1 attendee-list">
                                                        {customName(
                                                          amendment.name
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div
                                                      className="view"
                                                      onClick={() => {
                                                        handleAmendmentUpdateModal(
                                                          minute._id,
                                                          amendment.details,
                                                          amendment.createdById
                                                        );
                                                      }}
                                                    >
                                                      <p>View Details</p>
                                                    </div>

                                                    <div className="add-agenda">
                                                      <div className="pe-2">
                                                        {minuteData.loading &&
                                                        minuteData.status ===
                                                          "REJECTED" ? (
                                                          <LoaderButton />
                                                        ) : (
                                                          <button
                                                            className="add-minutes Mom-btn"
                                                            type="button"
                                                            onClick={() =>
                                                              handleAmendmentUpdateByButton(
                                                                "ACCEPTED",
                                                                amendment.createdById,
                                                                minute._id
                                                              )
                                                            }
                                                          >
                                                            <p>Accept</p>
                                                          </button>
                                                        )}
                                                      </div>

                                                      <div>
                                                        {minuteData.loading &&
                                                        minuteData.status ===
                                                          "REJECTED" ? (
                                                          <LoaderButton />
                                                        ) : (
                                                          <button
                                                            className=" reset"
                                                            type="button"
                                                            onClick={() =>
                                                              handleAmendmentUpdateByButton(
                                                                "REJECTED",
                                                                amendment.createdById,
                                                                minute._id
                                                              )
                                                            }
                                                          >
                                                            <p>Reject</p>
                                                          </button>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </>
                                                );
                                              }
                                            )}
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className=" minutes-border"></div>
                                    </>
                                  );
                                })}
                          </>
                        ) : null}
                      </div>
                    </div>
                  );
                })}
              {employeeData?.userData?.isAdmin === true ? null : (
                <>
                  {employeeData?.userData?.isMeetingOrganiser === false &&
                  props.meetingId &&
                  isMomAccepted === false ? (
                    <div className="mom-acceptance">
                      <div className="meeting-organiser d-flex align-items-center">
                        <input
                          type="checkbox"
                          name="isAccepted"
                          checked={isAccepted}
                          onChange={() => {
                            setIsAccepted(!isAccepted);
                          }}
                        />
                        <span>
                          By acknowledging this Minutes of Meeting (MOM), you
                          confirm the accuracy and completeness of the
                          discussions and agreements recorded herein.{" "}
                        </span>
                      </div>
                      <button
                        className="add-minutes Mom-btn mt-2"
                        type="button"
                        onClick={(e) => {
                          acceptMOMData(meetingData.meetingDetails._id);
                        }}
                      >
                        <p>I Accept the MOM</p>
                      </button>
                    </div>
                  ) : employeeData?.userData?.isMeetingOrganiser === false &&
                    props.meetingId &&
                    isMomAccepted ? (
                    <div className="mom-acceptance meeting-organiser d-flex align-items-center">
                      <button
                        className="add-minutes Mom-btn mt-2"
                        type="button"
                      >
                        <p>MOM Accepted</p>
                      </button>
                    </div>
                  ) : null}
                </>
              )}
            </div>
          ) : null}
        </form>
      ) : null}
      {props.meetingId ? null : (
        <>
          <form className="mt-4 details-form details-form-right">
            <MeetingAttendance />
          </form>
          <form className="mt-4 details-form details-form-right">
            <MeetingActivities />
          </form>
        </>
      )}

      <GenerateMOMModal
        message={"Do you want to generate the MOM report ?"}
        title={"Generate MOM"}
        setIsModalOpen={setIsCloseModalOpen}
        isModalOpen={isCloseModalOpen}
        handleSubmit={generateMOMDetails}
        buttonName={"Cancel"}
        isCalledFromOthers={true}
      />

      <AmendmentRequestViewModal
        handleSubmit={handleAmendmentUpdate}
        details={details}
        setAmendmentViewModal={setAmendmentViewModal}
        amendmentViewModal={amendmentViewModal}
      />

      <AmendmentRequestModal
        amendmentModal={amendmentModal}
        setAmendmentModal={setAmendmentModal}
        handleSubmit={handleAmendmentRequest}
      />
      <AttendeesModal
        IsModalOpen={isModalOpen}
        attendees={attendeesData}
        setIsModalOpen={setIsModalOpen}
        loginUserData={employeeData}
        isShow={false}
        isAttendace={true}
        meetingData={meetingData?.meetingDetails}
      />
      <AddAttendeesModal
        isAddAttendeeModal={isAddAttendeeModal}
        attendees={attendeesData}
        setIsAddAttendeeModal={setIsAddAttendeeModal}
        meetingData={meetingData?.meetingDetails}
      />
      <DeleteMeetingModal
        meetingId={minuteDetails?.meetingId}
        minuteId={minuteDetails?._id}
        IsDeleteMinuteModal={isDeleteMinuteModal}
        setIsDeleteMinuteModal={setIsDeleteMinuteModal}
        accessToken={accessToken}
      />
      <EditMinuteModal
        IsEditMinuteModal={isEditMinuteModal}
        minuteData={minuteDetails}
        setIsEditMinuteModal={setIsEditMinuteModal}
      />

      <AttendanceModal
        IsAttendanceModalOpen={isAttendaceModalOpen}
        attendees={attendeesData}
        SetIsAttendanceModalOpen={setIsAttendaceModalOpen}
        loginUserData={employeeData}
        meetingData={meetingData?.meetingDetails}
      />

      <RescheduleMeetingModal
        setIsRescheduleModalOpen={setIsRescheduleModalOpen}
        isRescheduleModalOpen={isRescheduleModalOpen}
        meetingData={meetingData?.meetingDetails}
        isFetchedMeetingList={meetingData.isFetchedMeetingList}
      />

      <MinutesRsvpStatusModal
        IsMinutesRsvpStatusModal={isMinutesRsvpStatusModal}
        attendees={attendeesData}
        setIsMinutesRsvpStatusModal={setIsMinutesRsvpStatusModal}
        status={status}
      />
    </>
  );
};

export default ViewMinutes;
