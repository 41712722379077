import Header from "../Common/Header/Header";
import Sidebar from "../Common/Sidebar/Sidebar";
import "./style/CreateMeeting.css";
import React, { useEffect } from "react";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./style/meetings-css.css";
import EditMeeting from "./EditMeeting";
import {
  getSingleMeetingDetails,
  unSetSingleMeetingDetails,
  updateStep,
} from "../../redux/actions/meetingActions/MeetingAction";
import ViewMeeting from "./ViewMeeting";
import { logOut } from "../../redux/actions/authActions/authAction";
const EditMeetingPage = () => {
  const location = useLocation();
  const stateData = location.state;
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  if (authData.isInValidUser) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  const accessToken = localStorage.getItem("accessToken");

  useEffect(() => {
    if (stateData?.meetingId) {
      dispatch(getSingleMeetingDetails(stateData?.meetingId, accessToken));
    }

    return () => {
      dispatch(unSetSingleMeetingDetails());

      dispatch(updateStep(0));
    };
  }, []);

  return (
    <>
      {!stateData?.meetingId ? <Navigate to="/meeting-list" /> : null}
      <Header />

      <Sidebar />
      <div className="main-content">
        <div className="row">
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
            <div className="meeting-header-text">
              <h4>Meeting Preview</h4>
            </div>
            <ViewMeeting />
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 detail-col">
            <div className="meeting-header-text">
              <h4>Edit Meeting Details</h4>
            </div>
            <EditMeeting />
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMeetingPage;
