import {
    OPEN_SIDEBAR,CLOSE_SIDEBAR
} from "../actions/commonActions/actionTypes";

const initialObject = {
  sidebarStatus: false,
};

export const commonReducer = (state = initialObject, action) => {
 
  switch (action.type) {
    case OPEN_SIDEBAR:
      return {
        ...state,

        sidebarStatus: true,
      };
    case CLOSE_SIDEBAR:
      return {
        ...state,

        sidebarStatus: false,
      };

    default:
      return state;
  }
};
