import { setInValidUser } from "../authActions/authAction";
import {
  FAIL_REQUEST,
  MAKE_REQUEST,
  GET_NOTIFICATION_LIST,
  UPDATE_ISSUCCESS,
} from "./actionTypes";
import axios from "axios";
import { toast } from "react-toastify";
import * as constantMessages from "../../../constants/constatntMessages";

export const makeRequest = (data) => {
  return {
    type: MAKE_REQUEST,
    payload: data,
  };
};
export const failRequest = (err) => {
  return {
    type: FAIL_REQUEST,
    payload: err,
  };
};
export const updateSuccess = (data) => {
  return {
    type: UPDATE_ISSUCCESS,
    payload: data,
  };
};
export const viewAllNotifications = (payload, accessToken) => {
  return (dispatch) => {
    dispatch(makeRequest());
    const webApiUrl = `${process.env.REACT_APP_API_URL}/api/V1/notification/viewNotifications`;
    const headerObject = {
      headers: {
        "Content-Type": "application/json",
        Authorization: accessToken,
      },
    };

    const bodyPayload = {
      organizationId: payload.organizationId,
    };
    axios
      .post(webApiUrl, bodyPayload, headerObject)
      .then((result) => {
        const resData = result.data;

        if (resData.data?.isInValidUser) {
          dispatch(setInValidUser(true));
        }
        if (
          (!resData.success && resData.data?.totalCount !== 0) ||
          resData.data?.isInValidUser
        ) {
        }

        dispatch(getNotificationList(resData));
      })
      .catch((err) => {
        dispatch(failRequest(err.message));
        toast.error(constantMessages.serverErrorMessage, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };
};

export const getNotificationList = (data) => {
  return {
    type: GET_NOTIFICATION_LIST,
    payload: data,
  };
};
