import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ActionDropDown from "./ActionDropDown";
import Badge from "react-bootstrap/Badge";
import {
  convertFirstLetterOfFullNameToCapital,
  customName,
  formatDateTimeFormat,
} from "../../helpers/commonHelpers";
import LoaderButton from "../Common/LoaderButton";
import NoDataFound from "../Common/NoDataFound";
import Alert from "../Common/Alert";
import { setSuccess } from "../../redux/actions/minuteActions/MinuteAction";
import ReopenActionModal from "../Action/ReopenActionModal";
import {
  setParentAgendaWithMinutesDetails,
  viewParentMeeting,
} from "../../redux/actions/meetingActions/MeetingAction";
import CommonModal from "../Common/CommonModal";
import {
  cancelAction,
  processReassign,
  setIsSuccess,
} from "../../redux/actions/actionAction/ActionAction";
import ReassignModal from "../Action/ReassignModal";

const ViewParentActions = (props) => {
  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = localStorage.getItem("accessToken");
  const [isReopenMinuteModal, setIsReopenMinuteModal] = useState(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  const [actionId, setActionId] = useState(null);
  const [minuteDetails, setMinuteDetails] = useState(null);
  const employeeData = useSelector((state) => state.user);
  const meetingData = useSelector((state) => state.meeting);
  const minuteData = useSelector((state) => state.minute);
  const actionData = useSelector((state) => state.action);

  useEffect(() => {
    if (props.parentMeetingId) {
      dispatch(viewParentMeeting(props.childMeetingId, accessToken));
    } else {
      dispatch(setParentAgendaWithMinutesDetails(null));
    }
    if (actionData?.isSuccess === true) {
      setIsCloseModalOpen(false);
      setIsReassignModalOpen(false);
    }
    return () => {
      setMinuteDetails(null);
    };
  }, [props.parentMeetingId, actionData.isSuccess]);

  const isActionAvailable = meetingData?.parentAgendaDetails?.filter(
    (agenda) => {
      if (agenda.minutesDetail) {
        const data = agenda.minutesDetail.filter(
          (minute) => minute.isAction === true
        );

        if (data.length !== 0) {
          return agenda;
        }
      }
    }
  );

  const handleReassign = (data) => {
    dispatch(
      processReassign(
        actionId,
        {
          organizationId: userData.organizationId,
          reAssignedId: data.attendeeId !== "" ? data.attendeeId : undefined,
          reAssignReason: data.reason,
          isNewUser: data.name !== "" && data.email !== "" ? true : false,
          name: data.name,
          email: data.email,
          reassignedUserName: data.reassignedUserName,
          dueDate: data.date ? data.date : undefined,
          priority: data.priority,
        },
        accessToken
      )
    );
    setActionId(null);
  };

  const handleReopenActionModal = (status, minute) => {
    minute["modalStatus"] = "REOPEN";
    dispatch(setSuccess(false));
    setMinuteDetails(minute);
    setIsReopenMinuteModal(status);
  };

  const handleApproveActionModal = (status, minute) => {
    minute["modalStatus"] = "APPROVE";
    dispatch(setSuccess(false));
    setMinuteDetails(minute);
    setIsReopenMinuteModal(status);
  };

  const handleCancelAction = (status, minute) => {
    setMinuteDetails(minute);
    setIsCloseModalOpen(status);
  };

  const submitCancelAction = () => {
    const payload = {
      assignedUserDetails: minuteDetails?.assignedUserDetails,
    };
    dispatch(
      cancelAction(
        props.parentMeetingId,
        minuteDetails?._id,
        accessToken,
        payload
      )
    );
  };

  const handleReassignModal = (actionId, minute) => {
    setActionId(actionId);
    setIsReassignModalOpen(true);
    dispatch(setIsSuccess(true));
    setMinuteDetails(minute);
  };
  return (
    <>
      {meetingData?.parentAgendaDetails?.length !== 0 ? (
        <form className="mt-2 details-form details-form-right">
          <div className="form-group agenda">
            <label className="mt-3 mb-3 add-agenda">
              <h4>Last Meeting Action Points Status</h4>
            </label>
            {isActionAvailable?.length !== 0 ? (
              <>
                {isActionAvailable.map((agenda, index) => {
                  return (
                    <>
                      {agenda?.minutesDetail?.length !== 0 &&
                      agenda?.minutesDetail?.filter(
                        (minute) => minute.isAction === true
                      ).length !== 0 ? (
                        <div className="mt-2 agenda-box-border">
                          <div className="form-group  m-0">
                            <div className="topic-head p-2 d-flex align-items-center justify-content-between">
                              <div>
                                <label> Agenda {index + 1}</label>
                              </div>
                            </div>
                          </div>
                          <div className="p-3">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                  <label className="mt-1 mb-1">
                                    Agenda Title
                                  </label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                  <p> {agenda.title}</p>
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                  <label className="mt-1 mb-1">
                                    Meeting Id
                                  </label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                  <p> {agenda.meetingId}</p>
                                </div>
                              </div>
                            </div>

                            {agenda.topic !== "" ? (
                              <>
                                <div className="form-group">
                                  <div className="row">
                                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                      <label className="mt-2 topic">
                                        Topic to Discuss
                                      </label>
                                    </div>
                                    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                      <p className="mb-2">{agenda.topic} </p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <div className=" form-group">
                              <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                                  <label className="mt-1 mb-1">Timeline</label>
                                </div>
                                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
                                  <p> {agenda.timeLine} Min</p>
                                </div>
                              </div>
                            </div>

                            {agenda?.minutesDetail?.filter(
                              (item) => item.isActive === true
                            ).length !== 0 ? (
                              <>
                                <div></div>
                                <div className=" minutes-border"></div>

                                {agenda?.minutesDetail?.filter(
                                  (item) =>
                                    item.isActive === true &&
                                    item.isAction === true
                                ).length !== 0 &&
                                  agenda?.minutesDetail
                                    ?.filter(
                                      (item) =>
                                        item.isActive === true &&
                                        item.isAction === true
                                    )
                                    .map((minute, index) => {
                                      function updateIsMinutesRsvpStatusModal(
                                        arg0,
                                        arg1
                                      ) {
                                        throw new Error(
                                          "Function not implemented."
                                        );
                                      }

                                      function handleAmendmentRequestModal(
                                        _id
                                      ) {
                                        throw new Error(
                                          "Function not implemented."
                                        );
                                      }

                                      return (
                                        <>
                                          <div className="agenda-minutes">
                                            <div className=" form-group ">
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <label className="mt-1 mb-1">
                                                    Minutes {index + 1}
                                                  </label>
                                                </div>
                                                <div className="col-md-6">
                                                  <p> {minute.description}</p>
                                                </div>

                                                <div className="col-md-2 d-flex justify-content-end">
                                                  <div className="btn-outerbox">
                                                    {!props.meetingId &&
                                                    !props.isHideOptions &&
                                                    (employeeData?.userData
                                                      ?.isMeetingOrganiser ===
                                                      true ||
                                                      employeeData?.userData
                                                        ?.isAdmin === true ||
                                                      meetingData
                                                        ?.meetingDetails
                                                        ?.canWriteMOM ===
                                                        true) &&
                                                    minute.isCancelled ===
                                                      false &&
                                                    minute.isApproved ===
                                                      false ? (
                                                      <div className="d-inline-block menu-dropdown custom-dropdown">
                                                        <ActionDropDown
                                                          minuteId={minute._id}
                                                          actionDetails={minute}
                                                          isFollowOnMeeting={
                                                            true
                                                          }
                                                          handleReopenActionModal={() => {
                                                            handleReopenActionModal(
                                                              true,
                                                              minute
                                                            );
                                                          }}
                                                          handleApproveActionModal={() => {
                                                            handleApproveActionModal(
                                                              true,
                                                              minute
                                                            );
                                                          }}
                                                          handleCancelActionModal={() => {
                                                            handleCancelAction(
                                                              true,
                                                              minute
                                                            );
                                                          }}
                                                          handleReassignModal={() => {
                                                            handleReassignModal(
                                                              minute?._id,
                                                              minute
                                                            );
                                                          }}
                                                        />
                                                      </div>
                                                    ) : null}
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row">
                                                <div className="col-md-4">
                                                  <label className="mt-1 mb-1">
                                                    Status
                                                  </label>
                                                </div>
                                                <div className="col-md-6">
                                                  <p>
                                                    {minute.isApproved ===
                                                    true ? (
                                                      <Badge bg="info">
                                                        Approved
                                                      </Badge>
                                                    ) : minute.isComplete ===
                                                      true ? (
                                                      <Badge bg="success">
                                                        Completed
                                                      </Badge>
                                                    ) : minute.isReopened ===
                                                      true ? (
                                                      <Badge bg="danger">
                                                        Reopened
                                                      </Badge>
                                                    ) : minute.isCancelled ===
                                                      true ? (
                                                      <Badge bg="secondary">
                                                        Cancelled
                                                      </Badge>
                                                    ) : (
                                                      <Badge bg="danger">
                                                        Pending
                                                      </Badge>
                                                    )}
                                                  </p>
                                                </div>
                                              </div>
                                            </div>

                                            {minute.isAction ? (
                                              <>
                                                <div className=" form-group">
                                                  <div className="row">
                                                    <div className="col-md-4">
                                                      <label className="mt-1 mb-1">
                                                        Due Date
                                                      </label>
                                                    </div>
                                                    <div className="col-md-8">
                                                      <p>
                                                        {" "}
                                                        {
                                                          formatDateTimeFormat(
                                                            minute?.dueDate
                                                          ).formattedDate
                                                        }
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className=" form-group">
                                                  <div className="row">
                                                    <div className="col-md-4">
                                                      <label className="mt-1 mb-1">
                                                        Priority
                                                      </label>
                                                    </div>
                                                    <div className="col-md-8">
                                                      <p>
                                                        {" "}
                                                        {minute.priority
                                                          .charAt(0)
                                                          .toUpperCase() +
                                                          minute.priority
                                                            .slice(1)
                                                            .toLowerCase()}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                {minute?.isAction ? (
                                                  <div className=" form-group">
                                                    <div className="row">
                                                      <div className="col-md-4">
                                                        <label className="mt-1 mb-1">
                                                          Responsible Person
                                                        </label>
                                                      </div>
                                                      <div className="col-md-8">
                                                        <p>
                                                          {convertFirstLetterOfFullNameToCapital(
                                                            minute
                                                              ?.assignedUserDetails
                                                              ?.name
                                                          )}
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : null}
                                              </>
                                            ) : null}

                                            {minute.reassignDetails ? (
                                              <div className=" form-group">
                                                <div className="row">
                                                  <div className="col-md-4">
                                                    <label className="mt-1 mb-1">
                                                      Reassigned To
                                                    </label>
                                                  </div>
                                                  <div className="col-md-8 detail ">
                                                    <p>Rakesh Baral</p>
                                                  </div>
                                                </div>
                                              </div>
                                            ) : null}

                                            {minute?.attendees?.length == 0 ? (
                                              <>
                                                <div className="form-group mt-4">
                                                  <div className="row">
                                                    <div className="col-md-4">
                                                      <label className="mt-2 topic">
                                                        Accepted By
                                                      </label>
                                                    </div>
                                                    <div
                                                      className="col-md-8"
                                                      onClick={() =>
                                                        updateIsMinutesRsvpStatusModal(
                                                          "Accepted",
                                                          minute?.attendees?.filter(
                                                            (attendee) =>
                                                              attendee.status ===
                                                              "ACCEPTED"
                                                          )
                                                        )
                                                      }
                                                    >
                                                      {minute?.attendees?.filter(
                                                        (attendee) =>
                                                          attendee.status ===
                                                          "ACCEPTED"
                                                      ).length !== 0 &&
                                                        minute?.attendees
                                                          ?.filter(
                                                            (attendee) =>
                                                              attendee.status ===
                                                              "ACCEPTED"
                                                          )
                                                          .map((attendee) => {
                                                            return (
                                                              <>
                                                                <div className="attendees mb-2">
                                                                  <div className="attendee-list">
                                                                    {customName(
                                                                      attendee.name
                                                                    )}
                                                                  </div>
                                                                  {}

                                                                  <p className="plus-more-text m-0">
                                                                    {minute
                                                                      .attendees
                                                                      .length >
                                                                    5
                                                                      ? `+${
                                                                          minute
                                                                            .attendees
                                                                            .length -
                                                                          5
                                                                        } More`
                                                                      : null}
                                                                  </p>
                                                                </div>
                                                              </>
                                                            );
                                                          })}

                                                      <p>
                                                        Accepted by{" "}
                                                        {
                                                          minute?.attendees?.filter(
                                                            (attendee) =>
                                                              attendee.status ===
                                                              "ACCEPTED"
                                                          ).length
                                                        }
                                                        /
                                                        {
                                                          minute.attendees
                                                            .length
                                                        }{" "}
                                                        attendants
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>

                                                <div className="form-group mt-4">
                                                  <div className="row">
                                                    <div className="col-md-4">
                                                      <label className="mt-2 topic">
                                                        Rejected By
                                                      </label>
                                                    </div>
                                                    <div
                                                      className="col-md-8"
                                                      onClick={() =>
                                                        updateIsMinutesRsvpStatusModal(
                                                          "Rejected",
                                                          minute?.attendees?.filter(
                                                            (attendee) =>
                                                              attendee.status ===
                                                              "REJECTED"
                                                          )
                                                        )
                                                      }
                                                    >
                                                      {minute?.attendees?.filter(
                                                        (attendee) =>
                                                          attendee.status ===
                                                          "REJECTED"
                                                      ).length !== 0 &&
                                                        minute?.attendees
                                                          ?.filter(
                                                            (attendee) =>
                                                              attendee.status ===
                                                              "REJECTED"
                                                          )
                                                          .map((attendee) => {
                                                            return (
                                                              <>
                                                                <div className="attendees mb-2">
                                                                  <div className="attendee-list">
                                                                    {customName(
                                                                      attendee.name
                                                                    )}
                                                                  </div>
                                                                  {}

                                                                  <p className="plus-more-text m-0">
                                                                    {minute
                                                                      .attendees
                                                                      .length >
                                                                    5
                                                                      ? `+${
                                                                          minute
                                                                            .attendees
                                                                            .length -
                                                                          5
                                                                        } More`
                                                                      : null}
                                                                  </p>
                                                                </div>
                                                              </>
                                                            );
                                                          })}

                                                      <p>
                                                        Rejected by{" "}
                                                        {
                                                          minute?.attendees?.filter(
                                                            (attendee) =>
                                                              attendee.status ===
                                                              "REJECTED"
                                                          ).length
                                                        }
                                                        /
                                                        {
                                                          minute.attendees
                                                            .length
                                                        }{" "}
                                                        attendants
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ) : null}
                                            {!employeeData.userData
                                              .isMeetingOrganiser &&
                                            props.meetingId ? (
                                              <div>
                                                <></>
                                                <div className=" minutes-border"></div>
                                              </div>
                                            ) : null}

                                            {employeeData?.userData
                                              ?.isMeetingOrganiser &&
                                            minute?.amendmentDetails?.length !==
                                              0 ? (
                                              <div className="added-by">
                                                {minute?.amendmentDetails?.map(
                                                  (amendment) => {
                                                    function handleAmendmentUpdateByButton(
                                                      arg0,
                                                      createdById,
                                                      _id
                                                    ) {
                                                      throw new Error(
                                                        "Function not implemented."
                                                      );
                                                    }

                                                    return (
                                                      <>
                                                        <div className="add-agenda">
                                                          <p className="add-text">
                                                            Amendment Requested
                                                            By
                                                          </p>
                                                          <div className="attendee1 attendee-list">
                                                            {customName(
                                                              amendment.name
                                                            )}
                                                          </div>
                                                        </div>
                                                        <div
                                                          className="view"
                                                          onClick={() => {
                                                            handleAmendmentRequestModal(
                                                              minute._id,
                                                              amendment.details,
                                                              amendment.createdById
                                                            );
                                                          }}
                                                        >
                                                          <p>View Details</p>
                                                        </div>

                                                        <div className="add-agenda">
                                                          <div className="pe-2">
                                                            {minuteData.loading &&
                                                            minuteData.status ===
                                                              "REJECTED" ? (
                                                              <LoaderButton />
                                                            ) : (
                                                              <button
                                                                className="add-minutes Mom-btn"
                                                                type="button"
                                                                onClick={() =>
                                                                  handleAmendmentUpdateByButton(
                                                                    "ACCEPTED",
                                                                    amendment.createdById,
                                                                    minute._id
                                                                  )
                                                                }
                                                              >
                                                                <p>Accept</p>
                                                              </button>
                                                            )}
                                                          </div>

                                                          <div>
                                                            {minuteData.loading &&
                                                            minuteData.status ===
                                                              "REJECTED" ? (
                                                              <LoaderButton />
                                                            ) : (
                                                              <button
                                                                className=" reset"
                                                                type="button"
                                                                onClick={() =>
                                                                  handleAmendmentUpdateByButton(
                                                                    "REJECTED",
                                                                    amendment.createdById,
                                                                    minute._id
                                                                  )
                                                                }
                                                              >
                                                                <p>Reject</p>
                                                              </button>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            ) : null}
                                          </div>
                                          <div className=" minutes-border"></div>
                                        </>
                                      );
                                    })}
                              </>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                    </>
                  );
                })}
              </>
            ) : (
              <div className="no-data-img">
                <Alert
                  status={"info"}
                  message={"No data available."}
                  timeoutSeconds={0}
                />
                <NoDataFound dataType={"meetingActivities"} />
              </div>
            )}
          </div>
          <ReopenActionModal
            meetingId={minuteDetails?.meetingId}
            minuteDetails={minuteDetails}
            IsReopenMinuteModal={isReopenMinuteModal}
            setIsReopenMinuteModal={setIsReopenMinuteModal}
            accessToken={accessToken}
            parentMeetingId={props.parentMeetingId}
          />
          <CommonModal
            message={"Do you want to cancel the action ?"}
            title={"Cancel Action"}
            setIsModalOpen={setIsCloseModalOpen}
            isModalOpen={isCloseModalOpen}
            handleSubmit={submitCancelAction}
            buttonName={"Cancel"}
            isCalledFromOthers={true}
          />
          <ReassignModal
            setIsReassignModalOpen={setIsReassignModalOpen}
            isModalOpen={isReassignModalOpen}
            handleSubmit={handleReassign}
            actionData={minuteDetails}
          />
        </form>
      ) : null}
    </>
  );
};

export default ViewParentActions;
