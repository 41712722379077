import React from "react";
import Modal from "react-bootstrap/Modal";
const ShowWriteMinuteYetToStartModal = (props) => {
  return (
    <>
      <Modal
        show={props.isModalOpen}
        onHide={(e) => props.setIsModalOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Alert!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Write minutes option is not available before the meeting schedule
          time.
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ShowWriteMinuteYetToStartModal;
