import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import * as constantMessages from "../../constants/constatntMessages";
import { getEmployeeList } from "../../redux/actions/userAction/userAction";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkCharacterLeft } from "../../helpers/commonHelpers";
import { textBoxStrLength } from "../../config/config";
import { fetchCurrentAttendeesList } from "../../redux/actions/meetingActions/MeetingAction";
import Select from "react-select";
import { rejectReassignRequest } from "../../redux/actions/actionAction/ActionAction";
import Spinner from "react-bootstrap/Spinner";

const ReassignModal = (props) => {
  const regularExpression = /^[0-9a-zA-Z .(),/-]+$/;
  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const meetingData = useSelector((state) => state.meeting);
  const actionData = useSelector((state) => state.action);
  const employeeData = useSelector((state) => state.user);
  const [reason, setReason] = useState("");
  const [selectedAttendeeOption, setSelectedAttendeeOption] = useState([
    { label: "Name / Email Address", value: "" },
  ]);
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState([
    { label: "Name / Emp Id", value: "" },
  ]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    reason: "",
    priority: "",
    attendyType: "addNewPeople",
    responsiblePersonId: null,
    name: "",
    email: "",
    acceptStatus: "accept",
    attendeeId: null,
    date: null,
    companyName: null,
    designation: null,
  });

  useEffect(() => {
    if (props?.actionData?.meetingId) {
      dispatch(
        fetchCurrentAttendeesList(
          userData.organizationId,
          props?.actionData?.meetingId,
          accessToken
        )
      );
    }

    if (actionData?.isSuccess === true) {
      setReason("");
      setErrors({});
      props.setIsReassignModalOpen(false);
      setFormData({
        ...formData,
        reason: "",
        priority: "",
        attendyType: "addNewPeople",
        responsiblePersonId: "",
        name: "",
        email: "",
        acceptStatus: "accept",
        date: null,
        designation: null,
        companyName: null,
      });
    }
    return () => {
      setFormData({
        ...formData,
        reason: "",
        priority: "",
        attendyType: "addNewPeople",
        responsiblePersonId: "",
        name: "",
        email: "",
        acceptStatus: "accept",
        date: null,
        designation: null,
        companyName: null,
      });
    };
  }, [actionData?.isSuccess]);

  const handleSubmit = () => {
    const newErrors = validateForm();

    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      if (formData.attendeeId) {
        const resPersonNameFromAttendees =
          props?.actionData?.attendeesDetail?.find(
            (item) => item._id.toString() === formData.attendeeId
          )?.name;
        const resPersonNameFromEmployee = employeeData.employeeList.find(
          (item) => item._id.toString() === formData.attendeeId
        )?.name;

        if (formData.acceptStatus === "accept") {
          props.handleSubmit({
            ...formData,
            reassignedUserName: resPersonNameFromEmployee
              ? resPersonNameFromEmployee
              : resPersonNameFromAttendees,
          });
        } else {
          const payload = {
            rejectDetails: formData.reason,
            assignedUserId: formData.attendeeId,
          };

          dispatch(rejectReassignRequest(props.actionId, payload, accessToken));
        }
      } else {
        if (formData.acceptStatus === "reject") {
          const payload = {
            rejectDetails: formData.reason,
            assignedUserId: actionData?.singleActionDetails?.assignedUserId,
          };
          dispatch(rejectReassignRequest(props.actionId, payload, accessToken));
        } else {
          props.handleSubmit({
            ...formData,
          });
        }
      }
    } else {
    }
  };

  if (meetingData?.currentAttendeesList?.length !== 0) {
    var attendeeOptions = meetingData?.currentAttendeesList?.map((attendee) => {
      const returnData = {
        value: attendee._id,
        label: attendee.name + " / " + attendee.email,
        name: "attendeeId",
      };

      return returnData;
    });
  }

  const validateForm = () => {
    const errors = {};
    if (formData.acceptStatus === "accept") {
      if (!formData.reason.trim()) {
        errors.reason = constantMessages.reasonRequired;
      } else if (
        formData.reason.trim().length < 3 ||
        formData.reason.trim().length > 200
      ) {
        errors.reason = constantMessages.stringLengthError;
      } else if (!regularExpression.test(formData.reason.trim())) {
        errors.reason = constantMessages.invalidReason;
      }

      if (formData.attendyType === "addNewPeople") {
        if (!formData.name.trim()) {
          errors.name = constantMessages.nameRequired;
        } else if (!regularExpression.test(formData.name)) {
          errors.name = constantMessages.invalidName;
        } else if (
          formData.name.trim().length < 3 ||
          formData.name.trim().length > 100
        ) {
          errors.title = constantMessages.stringLengthError;
        }

        if (!formData.email.trim()) {
          errors.email = constantMessages.emailRequired;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          errors.email = constantMessages.invalidEmail;
        }
      } else {
        if (!formData?.priority?.trim()) {
          errors.priority = constantMessages.priorityRequired;
        }
        if (formData.attendeeId === null) {
          errors.responsiblePerson = constantMessages.responsiblePersonRequired;
        }
      }
    } else {
      if (!formData?.reason?.trim()) {
        errors.reason = constantMessages.reasonRequired;
      } else if (
        formData.reason.trim().length < 3 ||
        formData.reason.trim().length > 200
      ) {
        errors.reason = constantMessages.smallStringLengthError;
      } else if (!regularExpression.test(formData.reason.trim())) {
        errors.reason = constantMessages.invalidReason;
      }
    }
    setErrors(errors);
    return errors;
  };

  const validateReasonForm = () => {
    const errors = {};
    if (formData.acceptStatus === "accept") {
      if (!formData.reason.trim()) {
        errors.reason = constantMessages.reasonRequired;
      } else if (
        formData.reason.trim().length < 3 ||
        formData.reason.trim().length > 200
      ) {
        errors.reason = constantMessages.smallStringLengthError;
      } else if (!regularExpression.test(formData.reason.trim())) {
        errors.reason = constantMessages.invalidReason;
      }

      if (formData.attendyType === "addNewPeople") {
        if (!formData.name.trim()) {
          errors.name = constantMessages.nameRequired;
        } else if (!regularExpression.test(formData.name)) {
          errors.name = constantMessages.invalidName;
        } else if (
          formData.name.trim().length < 3 ||
          formData.name.trim().length > 100
        ) {
          errors.title = constantMessages.stringLengthError;
        }

        if (!formData.email.trim()) {
          errors.email = constantMessages.emailRequired;
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          errors.email = constantMessages.invalidEmail;
        }
      }

      if (formData?.date?.trim()) {
        const currentDate = new Date(meetingData?.meetingDetails?.date);
        const inputDate = new Date(formData.date);
        let differenceInTime = inputDate.getTime() - currentDate.getTime();
        let differenceInDays = Math.round(
          differenceInTime / (1000 * 3600 * 24)
        );
        if (differenceInDays < 0) {
          errors.date = constantMessages.dateMoreThanMeetingDate;
        }
      }
    } else {
      if (!formData.reason.trim()) {
        errors.reason = constantMessages.reasonRequired;
      } else if (
        formData.reason.trim().length < 3 ||
        formData.reason.trim().length > 200
      ) {
        errors.reason = constantMessages.stringLengthError;
      }
    }
    setErrors(errors);
    return errors;
  };

  const closeModal = () => {
    setReason("");
    setErrors({});
    props.setIsReassignModalOpen(false);
    setFormData({
      ...formData,
      reason: "",
      priority: "",
      attendyType: "fromCurrentMeeting",
      responsiblePersonId: "",
      name: "",
      email: "",
      acceptStatus: "accept",
      date: null,
      designation: null,
      companyName: null,
    });
  };

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;

    if (name === "reason") {
      if (value.length <= textBoxStrLength) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (value === "fromEmployeeList") {
      const payload = {
        organizationId: userData.organizationId,
      };

      dispatch(getEmployeeList(payload, accessToken));
    }

    if (value === "fromCurrentMeeting") {
      dispatch(
        fetchCurrentAttendeesList(
          userData.organizationId,
          props?.actionData?.meetingId,
          accessToken
        )
      );
    }
  };

  const nameFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.name.trim()) {
      errors.name = constantMessages.nameRequired;
    } else if (!regularExpression.test(formData.name)) {
      errors.name = constantMessages.invalidName;
    } else if (
      formData.name.trim().length < 3 ||
      formData.name.trim().length > 30
    ) {
      errors.name = constantMessages.nameLengthError;
    }
    setErrors(errors);
  };

  const emailFieldValidationCheck = (e) => {
    const errors = {};
    if (!formData.email.trim()) {
      errors.email = constantMessages.emailRequired;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = constantMessages.invalidEmail;
    }
    setErrors(errors);
  };

  if (employeeData?.employeeList?.length !== 0) {
    var employeeOptions = employeeData?.employeeList?.map((attendee) => {
      const returnData = {
        value: attendee._id,
        label: attendee.name + " / " + attendee.empId,
        name: "attendeeId",
      };
      return returnData;
    });
  }

  const handleAttendeeChange = (e) => {
    setErrors({});
    const { name, value } = e;
    setFormData({
      ...formData,
      [name]: value,
    });
    setSelectedAttendeeOption(e);
  };
  const handleEmployeeChange = (e) => {
    setErrors({});
    const { name, value } = e;
    setFormData({
      ...formData,
      [name]: value,
    });
    setSelectedEmployeeOption(e);
  };
  const dateFieldValidationCheck = (e) => {
    const errors = {};
    if (formData?.date?.trim()) {
      const currentDate = new Date(meetingData?.meetingDetails?.date);
      const inputDate = new Date(formData.date);
      let differenceInTime = inputDate.getTime() - currentDate.getTime();
      let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      if (differenceInDays < 0) {
        errors.date = constantMessages.dateMoreThanMeetingDate;
      }
    }
    setErrors(errors);
  };

  const designationFieldValidationCheck = (e) => {
    const errors = {};

    const regularExpression = /^[A-Za-z\s]+$/; //returns true if matched, vaidates for a-z and A-Z and white space
    if (formData?.designation?.trim()) {
      if (!regularExpression.test(formData.designation)) {
        errors.designation = constantMessages.invalidDesignation;
      }
    }
    setErrors(errors);
  };

  const companyNameFieldValidationCheck = (e) => {
    const errors = {};
    const regularExpression = /^[A-Za-z\s]+$/; //returns true if matched, vaidates for a-z and A-Z and white space
    if (formData?.companyName?.trim()) {
      if (!regularExpression.test(formData.companyName)) {
        errors.companyName = constantMessages.invalidCompanyName;
      }
    }
    setErrors(errors);
  };

  return (
    <>
      <Modal show={props.isModalOpen} onHide={closeModal} backdrop="static">
        {props.activity ? (
          <>
            <div className="modal-header">
              <h4 className="modal-title">Reassignment Request</h4>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={closeModal}
              ></button>
            </div>
          </>
        ) : (
          <div className="modal-header">
            <h4 className="modal-title">Reassign </h4>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={closeModal}
            ></button>
          </div>
        )}
        <div className="modal-body">
          {props.activity ? (
            <>
              <div className="delete-txt">
                <p>{props.activity}</p>
              </div>
              <div className="minutes-border"></div>
            </>
          ) : null}
          <form>
            {props.activity ? (
              <div className="d-flex w-100">
                <div className="form-check form-check-inline">
                  <input
                    id="flexRadioDefault1"
                    className="form-check-input"
                    type="radio"
                    name="acceptStatus"
                    value="accept"
                    onChange={handleChange}
                    checked={formData.acceptStatus === "accept"}
                  />
                  <label
                    for="flexRadioDefault1"
                    id="flexRadioDefault1"
                    className="form-check-label"
                  >
                    Accept
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      id="flexRadioDefault2"
                      type="radio"
                      name="acceptStatus"
                      value="reject"
                      onChange={handleChange}
                      checked={formData.acceptStatus === "reject"}
                    />
                    <label
                      className="form-check-label"
                      for="flexRadioDefault2"
                      id="flexRadioDefault2"
                    >
                      Reject
                    </label>
                  </div>
                </div>
              </div>
            ) : null}
            {formData.acceptStatus === "accept" ? (
              <>
                <div className={`form-group ${props.activity ? "mt-3" : ""}`}>
                  <div className="position-relative">
                    <label className="pb-2 input-date">
                      Reason
                      <span className="star-mark">*</span>
                    </label>
                    <div>
                      <textarea
                        cols="40"
                        rows="4"
                        id="remark"
                        name="reason"
                        onBlur={validateReasonForm}
                        onChange={handleChange}
                        value={formData.reason}
                        placeholder="Enter your reason here..."
                        required
                      ></textarea>
                      <p className="success-message">
                        {checkCharacterLeft(formData.reason)} Characters left
                      </p>
                      {errors.reason && (
                        <span className="error-message">{errors.reason}</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="position-relative">
                    <label className="pb-2 input-date"> Due Date</label>
                    <input
                      type="date"
                      name="date"
                      value={formData.date}
                      onChange={handleChange}
                      onBlur={dateFieldValidationCheck}
                    />
                  </div>
                  {errors.date && (
                    <span className="error-message">{errors.date}</span>
                  )}
                </div>
                <div className="form-group">
                  <div className="position-relative">
                    <label className="pb-2 input-date">Priority</label>
                    <div>
                      <select
                        name="priority"
                        id="priority"
                        onChange={handleChange}
                      >
                        <option value="">Select Priority</option>
                        <option value="HIGH">High</option>
                        <option value="NORMAL">Normal</option>
                        <option value="LOW">Low</option>
                      </select>
                    </div>
                  </div>
                  {errors.priority ? (
                    <span className="error-message">{errors.priority}</span>
                  ) : null}
                </div>
                <div className="form-group">
                  <label className="pb-2">Select Responsible Person</label>
                  <div className="w-100">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="attendyType"
                        value="fromCurrentMeeting"
                        id="flexRadioDefault1"
                        onChange={handleChange}
                        checked={formData.attendyType === "fromCurrentMeeting"}
                      />

                      <label
                        className="mb-2 form-check-label"
                        for="flexRadioDefault1"
                        id="flexRadioDefault1"
                      >
                        Select From Current Meeting
                      </label>
                    </div>

                    <div className="form-check-inline">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="attendyType"
                          value="fromEmployeeList"
                          id="flexRadioDefault2"
                          onChange={handleChange}
                          checked={formData.attendyType === "fromEmployeeList"}
                        />
                        <label
                          className=" mb-2 form-check-label"
                          for="flexRadioDefault2"
                          id="flexRadioDefault2"
                        >
                          Select From Employees
                        </label>
                      </div>
                    </div>

                    <div className="form-check-inline">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="attendyType"
                          value="addNewPeople"
                          id="flexRadioDefault3"
                          checked={formData.attendyType === "addNewPeople"}
                          onChange={handleChange}
                        />
                        <label
                          className=" mb-2 form-check-label"
                          for="flexRadioDefault3"
                          id="flexRadioDefault3"
                        >
                          Add New Guest
                        </label>
                      </div>
                    </div>
                  </div>

                  {formData.attendyType === "fromCurrentMeeting" ? (
                    <Select
                      className="fltr-opt"
                      placeholder="Name / Email Address"
                      name="attendeeId"
                      options={attendeeOptions}
                      onChange={handleAttendeeChange}
                      value={selectedAttendeeOption}
                    />
                  ) : formData.attendyType === "fromEmployeeList" ? (
                    <Select
                      className="fltr-opt"
                      placeholder="Name / Employee ID"
                      name="attendeeId"
                      options={employeeOptions}
                      value={selectedEmployeeOption}
                      onChange={handleEmployeeChange}
                    />
                  ) : formData.attendyType === "addNewPeople" ? (
                    <div className="form-group">
                      <label className="pb-2">Add New Guest</label>
                      <div className="row">
                        <div className="col-xl-6">
                          <input
                            type="text"
                            autoComplete="off"
                            placeholder="Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            onBlur={nameFieldValidationCheck}
                          />
                          {errors.name && (
                            <span className="error-message">{errors.name}</span>
                          )}
                        </div>
                        <div className="col-xl-6">
                          <input
                            type="text"
                            placeholder="Email"
                            autoComplete="off"
                            name="email"
                            value={formData.email}
                            onBlur={emailFieldValidationCheck}
                            onChange={handleChange}
                          />
                          {errors.email && (
                            <span className="error-message">
                              {errors.email}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xl-6 col-md-6 mt-2">
                          <input
                            type="text"
                            autoComplete="off"
                            placeholder="Designation"
                            name="designation"
                            value={formData.designation}
                            onChange={handleChange}
                            onBlur={designationFieldValidationCheck}
                          />
                          {errors.designation && (
                            <span className="error-message">
                              {errors.designation}
                            </span>
                          )}
                        </div>

                        <div className="col-xl-6 col-md-6 mt-2">
                          <input
                            type="text"
                            autoComplete="off"
                            placeholder="Company Name"
                            name="companyName"
                            value={formData.companyName}
                            onChange={handleChange}
                            onBlur={companyNameFieldValidationCheck}
                          />
                          {errors.companyName && (
                            <span className="error-message">
                              {errors.companyName}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {errors.responsiblePerson ? (
                    <span className="error-message">
                      {errors.responsiblePerson}
                    </span>
                  ) : null}
                </div>
              </>
            ) : (
              <div className="form-group">
                <div className="position-relative">
                  <label className="pb-2 input-date">Reason</label>
                  <span className="star-mark">*</span>
                  <div>
                    <textarea
                      cols="40"
                      rows="4"
                      id="remark"
                      name="reason"
                      onBlur={validateForm}
                      onChange={handleChange}
                      value={formData.reason}
                      placeholder="Enter your reason here..."
                      required
                    ></textarea>
                    <p className="success-message">
                      {checkCharacterLeft(formData.reason)} Characters left
                    </p>
                    {errors.reason && (
                      <span className="error-message">{errors.reason}</span>
                    )}
                  </div>
                </div>
              </div>
            )}
          </form>
        </div>
        <div className="modal-footer">
          {!actionData.loading &&
          actionData.isSuccess &&
          formData.acceptStatus === "accept" ? (
            <button
              type="button"
              onClick={handleSubmit}
              className="Mom-btn btn btn-secondary bg-primary border-primary"
            >
              <p>Reassign</p>
            </button>
          ) : !actionData.loading &&
            actionData.isSuccess &&
            formData.acceptStatus === "reject" ? (
            <button
              type="button"
              onClick={handleSubmit}
              className="Mom-btn btn btn-secondary bg-primary border-primary"
            >
              <p>Reject</p>
            </button>
          ) : (
            <button
              className="Mom-btn btn btn-secondary bg-primary border-primary"
              type="button"
            >
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Processing...
            </button>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ReassignModal;
