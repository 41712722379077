import React, { useEffect, useState } from "react";
import Header from "../../Common/Header/Header";
import Sidebar from "../../Common/Sidebar/Sidebar";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { logOut } from "../../../redux/actions/authActions/authAction";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoaderButton from "../../Common/LoaderButton";

const generateOptions = (max, step = 1) => {
  if (step <= 0) {
    console.warn("Step must be greater than 0, defaulting to 1.");
    step = 1;
  }
  return Array.from({ length: Math.floor(max / step) + 1 }, (_, i) => i * step);
};

const Configuration = () => {
  const userData = JSON.parse(localStorage.getItem("userData"));
  const authData = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  if (authData.isInValidUser) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }

  const organizationId = userData?.organizationId;
  const accessToken = localStorage.getItem("accessToken");

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [config, setConfig] = useState({
    amendmentRequestTime: 1,
    acceptanceRejectionEndtime: 1,
    writeMinuteMaxTimeInHour: 1,
    showWriteMinutesDays: 1,
  });
  const [savedConfig, setSavedConfig] = useState({});
  const [daysOption, setDaysOption] = useState([]);
  const hourOptions = generateOptions(24);
  // const dayOptions = generateOptions(30);
  const [selectedDays, setSelectedDays] = useState(0);
  const [selectedHours, setSelectedHours] = useState(0);

  useEffect(() => {
    document.title = "Configuration: MinutesVault";
    const fetchConfig = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/V1/configuration/viewConfiguration/${organizationId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: accessToken,
            },
          }
        );

        if (response.data && response.data.data) {
          setConfig(response.data.data);
          setSavedConfig(response.data.data);
          const totalHours = response.data.data.writeMinuteMaxTimeInHour;

          const days = Math.floor(totalHours / 24);
          const hours = totalHours % 24;
          setSelectedDays(days);
          setSelectedHours(hours);
        }
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };

    fetchConfig();

    const daysOption = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/V1/configuration/getDaysOption`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: accessToken,
            },
          }
        );

        setDaysOption(response.data.data);
      } catch (error) {
        console.error("Error fetching config:", error);
      }
    };

    daysOption();
  }, [organizationId, accessToken]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setConfig((prevConfig) => ({
      ...prevConfig,
      [name]: parseInt(value, 10),
    }));

    if (name === "writeMinuteMaxTimeInHour") {
      setSelectedHours(value);
    }

    if (name === "showWriteMinutesDays") {
      setSelectedDays(value);
    }
  };

  const handleBlurValidation = (name, value) => {
    let error = "";
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
  };

  // const validateConfig = () => {
  //   const minHourLimit = 0;
  //   const maxHourLimit = 24;
  //   const minDayLimit = 0;
  //   const maxDayLimit = 7;
  //   const errors = {};
  //   let isValid = true;

  const handleSaveConfiguration = async () => {
    setIsLoading(true);

    const totalWriteMinutesTime =
      parseInt(selectedDays) * 24 + parseInt(selectedHours);

    const configData = {
      organizationId,
      amendmentRequestTime: config.amendmentRequestTime,
      acceptanceRejectionEndtime: config.acceptanceRejectionEndtime,
      writeMinuteMaxTimeInHour: totalWriteMinutesTime,
      isAlert: false,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/V1/configuration/createConfiguration`,
        { ...configData, organizationId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: accessToken,
          },
        }
      );

      if (response.data.success) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setSavedConfig(configData);
      } else {
        toast.error(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    } catch (error) {
      console.error("Error saving configuration:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Header />
      <Sidebar />
      <div className="main-content">
        <div className="meeting-room-page">
          <div className="meeting-header-text">
            <h4>Configuration</h4>
          </div>

          <form className="details-form">
            <div className="alert-box amendment">
              <div className="d-flex align-items-center">
                <label className="mb-0 me-2">
                  Allow MOM amendment requests within
                  <select
                    name="amendmentRequestTime"
                    value={config.amendmentRequestTime}
                    onChange={handleChange}
                    onBlur={(e) =>
                      handleBlurValidation(e.target.name, e.target.value)
                    }
                    className="custom-width-input me-2"
                  >
                    {hourOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  hour(s) of meeting MOM creation.
                </label>
              </div>
              {errors.amendmentRequestTime && (
                <p className="error mt-2">{errors.amendmentRequestTime}</p>
              )}
            </div>

            <div className="alert-box amendment mt-3">
              <div className="d-flex align-items-center">
                <label className="mb-0 me-2">
                  Allow acceptance of meeting minutes within
                  <select
                    name="acceptanceRejectionEndtime"
                    value={config.acceptanceRejectionEndtime}
                    onChange={handleChange}
                    onBlur={(e) =>
                      handleBlurValidation(e.target.name, e.target.value)
                    }
                    className="custom-width-input"
                  >
                    {hourOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  hour(s) of meeting MOM creation.
                </label>
              </div>
              {errors.acceptanceRejectionEndtime && (
                <p className="error mt-2">
                  {errors.acceptanceRejectionEndtime}
                </p>
              )}
            </div>

            <div className="alert-box amendment mt-3">
              <div className="d-flex align-items-center">
                <label className="mb-0 me-2">
                  Allow write minutes / Add new agenda / Generate new MOM of
                  meeting within
                  <select
                    name="showWriteMinutesDays"
                    value={selectedDays}
                    onChange={handleChange}
                    onBlur={(e) =>
                      handleBlurValidation(e.target.name, e.target.value)
                    }
                    className="custom-width-input"
                  >
                    {daysOption?.map((option) => (
                      <option key={option.dayValue} value={option.dayValue}>
                        {option.dayText}
                      </option>
                    ))}
                  </select>
                  day(s) and
                  <select
                    name="writeMinuteMaxTimeInHour"
                    value={selectedHours}
                    onChange={handleChange}
                    onBlur={(e) =>
                      handleBlurValidation(e.target.name, e.target.value)
                    }
                    className="custom-width-input"
                  >
                    {hourOptions.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                  hour(s) of meeting MOM creation.
                </label>
              </div>
              {errors.showWriteMinutesDays && (
                <p className="error mt-2">{errors.showWriteMinutesDays}</p>
              )}
              {errors.writeMinuteMaxTimeInHour && (
                <p className="error mt-2">{errors.writeMinuteMaxTimeInHour}</p>
              )}
            </div>

            <div className="mt-3">
              {isLoading ? (
                <LoaderButton />
              ) : (
                <button
                  className="add-btn send-email Mom-btn"
                  type="button"
                  onClick={handleSaveConfiguration}
                  disabled={isLoading}
                >
                  <p>Save Configuration</p>
                </button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Configuration;
