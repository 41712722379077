import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import MinutesAcceptRejectStatusTable from "./MinutesAcceptRejectStatusTable";
const MinutesRsvpStatusModal = (props) => {
  return (
    <>
      <Modal
        show={props.IsMinutesRsvpStatusModal}
        onHide={(e) => props.setIsMinutesRsvpStatusModal(false)}
      >
        <Modal.Dialog>
          <div className="modal-header attendees-modal">
            <div>
              <h4 className="modal-title">{props.status} by</h4>
            </div>
            <div>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={(e) => props.setIsMinutesRsvpStatusModal(false)}
              ></button>
            </div>
          </div>

          <Modal.Body className="attendees-popup modal-margin">
            <MinutesAcceptRejectStatusTable
              attendeesData={props.attendees}
              status={props.status}
            />
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </>
  );
};

export default MinutesRsvpStatusModal;
