import React, { useState } from "react";

const Alert = (props) => {
  const [show, setShow] = useState(true);
  if (props.timeoutSeconds !== 0) {
    setTimeout(() => setShow(false), props.timeoutSeconds);
  }

  return (
    <>
      {show ? (
        <>
          {props.status === "success" ? (
            <div
              className="alert alert-success alert-dismissible fade show"
              role="alert"
            >
              {props.message}
            </div>
          ) : props.status === "info" ? (
            <div
              className="alert alert-info alert-dismissible fade show"
              role="alert"
            >
              {props.message}
            </div>
          ) : (
            <div
              className="alert alert-danger alert-dismissible fade show"
              role="alert"
            >
              {props.message}
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default Alert;
