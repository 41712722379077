import React, { useState, useEffect } from "react";
import "./Footer.css";
// @ts-ignore
import { useNavigate, Navigate, Link } from "react-router-dom";
// @ts-ignore
import { logOut } from "../../../redux/actions/authActions/authAction";
import { useSelector, useDispatch } from "react-redux";
import { viewSingleUser } from "../../../redux/actions/userAction/userAction";
import CommonModal from "../CommonModal";
import { logOutMessage } from "../../../constants/constatntMessages";
import { setCreateNewMeetingPage } from "../../../redux/actions/meetingActions/MeetingAction";
import io from "socket.io-client";
import { viewAllNotifications } from "../../../redux/actions/notificationAction/NotificationAction";

const Footer = () => {
  const socket = io.connect("http://192.168.1.125:8080", {
    transports: ["websocket"],
  });

  const [name, setName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();

  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = localStorage.getItem("accessToken");
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    setIsModalOpen(false);
    socket.emit("notification", "message from frontend");
  };
  const employeeData = useSelector((state) => state.user);
  const notificationData = useSelector((state) => state.notification);

  let notificationCalled = false;
  useEffect(() => {
    socket.on("connection", () => {
      console.log(`I'm connected with the back-end`);
    });
    socket.on("notification", (message) => {
      console.log(`notification is ${message}`);
      notificationCalled = true;

      if (accessToken) {
        dispatch(
          viewAllNotifications(
            { organizationId: userData.organizationId },
            accessToken
          )
        );
      }
    });

    if (userData) {
      setName(employeeData.userData?.name);
      dispatch(viewSingleUser(userData._id, accessToken));
    } else {
      navigate("/login");
    }

    if (notificationCalled === false && accessToken) {
      dispatch(
        viewAllNotifications(
          { organizationId: userData?.organizationId },
          accessToken
        )
      );
    }
    return () => {};
  }, [notificationData.isSuccess]);

  return (
    <>
      {!accessToken || employeeData?.userData === null ? (
        <Navigate to="/login" />
      ) : null}

      <section className="footer">
        <div className="container-fluid">
          <div className="create-meeting-button">
            <Link
              to="/create-meeting"
              state={{ isNewMeeting: true }}
              style={{ textDecoration: "none" }}
            >
              <button
                className="create-meeting-button Mom-btn"
                onClick={() => {
                  dispatch(setCreateNewMeetingPage(true));
                }}
              >
                <p>Create Meeting</p>
              </button>
            </Link>
          </div>
        </div>
        <CommonModal
          message={logOutMessage}
          title={"Logout"}
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          handleSubmit={handleLogout}
          buttonName={"Logout"}
        />
      </section>
    </>
  );
};

export default Footer;
