import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import axios from "../../../../node_modules/axios/index";
import { toast } from "react-toastify";

const CustomizedSwitches = ({ unit }) => {
  const userData = JSON.parse(localStorage.getItem("userData"));

  const accessToken = localStorage.getItem("accessToken");
  const organizationId = userData?.organizationId;

  const [isActive, setIsActive] = useState(unit.isActive);

  useEffect(() => {
    if (unit) {
      setIsActive(unit.isActive);
    }
  }, [unit]);

  const handleToggle = async (unitId, isactive) => {
    const updateUnit = { isActive: isactive, organizationId };
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/V1/unit/editUnit/${unitId}`,
      updateUnit,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken,
        },
      }
    );
    setIsActive(!isActive);
    if (response.data.success) {
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      toast.error(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <FormGroup>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{isActive ? "Active" : "Deactive"}</Typography>
        <Switch
          checked={isActive}
          onClick={() => {
            handleToggle(unit._id, !isActive);
          }}
          inputProps={{ "aria-label": "ant design" }}
        />
      </Stack>
    </FormGroup>
  );
};

export default CustomizedSwitches;
