import React, { useState, useEffect } from "react";
import { logOut } from "../../redux/actions/authActions/authAction";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AddMinute from "./AddMinute";
import AddMinuteAgenda from "./AddMinuteAgenda";
import {
  setAgendaData,
  setSuccess,
} from "../../redux/actions/minuteActions/MinuteAction";
import EditAgendaModal from "./EditAgendaModal";
import DeleteAgendaModal from "./DeleteAgendaModal";
import ActionDropDown from "./ActionDropDown";

const CreateMinutes = (props) => {
  const dispatch = useDispatch();
  const employeeData = useSelector((state) => state.user);
  const minuteData = useSelector((state) => state.minute);
  const authData = useSelector((state) => state.auth);
  const meetingData = useSelector((state) => state.meeting);
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [isMinuteShow, setIsMinuteShow] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState([false, false]);
  const [isAgendaShow, setIsAgendaShow] = useState(false);
  const [isEditAgendaModal, setIsEditAgendaModal] = useState(false);
  const [agendaDatas, setAgendaDatas] = useState(null);
  const [isDeleteAgendaModal, setIsDeleteAgendaModal] = useState(false);

  if (authData.isInValidUser) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("rememberMe");
    dispatch(logOut());
    navigate("/login");
  }
  useEffect(() => {
    document.title = "Write Minutes: MinutesVault";
    if (minuteData.isSuccess) {
      setIsAgendaShow(false);
      setIsMinuteShow(false);
      props.closeAgendaForm(false);
    }
  }, [minuteData.isSuccess]);

  const setModalStatus = (value) => {
    setIsAgendaShow(value);
    if (!value) {
      dispatch(setAgendaData(null));
    }
  };

  const setEditAgendaModalStatus = (status, agenda) => {
    dispatch(setSuccess(false));
    setAgendaDatas(agenda);
    setIsEditAgendaModal(status);
  };

  const setDeleteAgendaModalStatus = (status, agenda) => {
    dispatch(setSuccess(false));
    setAgendaDatas(agenda);
    setIsDeleteAgendaModal(status);
  };

  return (
    <>
      <div className="meeting-header-text">
        <h4>Write Minutes</h4>
      </div>
      <div className="mt-2 details-form details-form-right">
        <div className="form-group">
          <div className="agendas-header d-flex align-items-center justify-content-between">
            <div>
              <label className="mt-3 mb-3">
                <h4>Agenda(s)</h4>
              </label>
            </div>
            {employeeData?.userData?.isMeetingOrganiser === true ||
            employeeData?.userData?.isAdmin ||
            meetingData?.meetingDetails?.canWriteMOM === true ? (
              <div
                onClick={() => {
                  setModalStatus(!isAgendaShow);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="#0564f3"
                  className="bi bi-plus-circle pointer"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                </svg>
              </div>
            ) : null}
          </div>

          {isAgendaShow || props.openAgendaForm ? (
            <AddMinuteAgenda meetingData={meetingData} />
          ) : null}

          <>
            {meetingData?.agendaDetails?.length > 0 &&
              meetingData?.agendaDetails?.map((agenda, index) => {
                return (
                  <div className="mt-3 agenda-box-border" key={index}>
                    <div className="form-group m-0" id={`agenda${index + 1}`}>
                      <div className="topic-head p-2 d-flex align-items-center justify-content-between">
                        <div>
                          <label> Agenda {index + 1}</label>
                        </div>

                        {((agenda?.minutesDetail?.length !== 0 &&
                          agenda?.isMOMGenerated === false) ||
                          (agenda?.minutesDetail?.length === 0 &&
                            agenda?.isMOMGenerated === false)) &&
                        (employeeData?.userData?.isMeetingOrganiser === true ||
                          employeeData?.userData?.isAdmin === true ||
                          meetingData?.meetingDetails?.canWriteMOM === true) ? (
                          <div className="d-flex justify-content-end">
                            <div className="btn-outerbox">
                              <div className="d-inline-block menu-dropdown custom-dropdown">
                                <ActionDropDown
                                  agendaId={agenda._id}
                                  isShowDelete={
                                    meetingData?.agendaDetails?.length > 1
                                      ? true
                                      : false
                                  }
                                  handleRequestForEditModal={() => {
                                    setEditAgendaModalStatus(true, agenda);
                                  }}
                                  handleRequestForDeleteModal={() => {
                                    setDeleteAgendaModalStatus(true, agenda);
                                  }}
                                  agenda={agenda}
                                />
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="p-3">
                      <div className=" form-group">
                        <div className="row">
                          <div className="col-md-4">
                            <label className="mt-1 mb-1">Agenda Title</label>
                          </div>
                          <div className="col-md-8">
                            <p> {agenda.title}</p>
                          </div>
                        </div>
                      </div>
                      {agenda.topic !== "" ? (
                        <div className="  form-group">
                          <div className="row">
                            <div className="col-md-4">
                              <label className="mt-2 topic">
                                Topic to Discuss
                              </label>
                            </div>
                            <div className="col-md-8">
                              <p>{agenda.topic} </p>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className=" form-group">
                        <div className="row">
                          <div className="col-md-4">
                            <label className="mt-1 mb-1">Timeline</label>
                          </div>
                          <div className="col-md-8">
                            <p>
                              {" "}
                              {agenda.timeLine === ""
                                ? 0
                                : agenda.timeLine}{" "}
                              Mins
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className=" form-group m-0">
                        <div className="row">
                          <div className="col-md-4">
                            <label className="mt-1 mb-1">Total Minutes</label>
                          </div>
                          <div className="col-md-8">
                            <p>
                              {" "}
                              {
                                agenda?.minutesDetail?.filter(
                                  (minute) => minute.isActive
                                ).length
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                      {employeeData?.userData?.isMeetingOrganiser === true ||
                      employeeData?.userData?.isAdmin === true ||
                      meetingData?.meetingDetails?.canWriteMOM === true ? (
                        <AddMinute
                          agenda={agenda}
                          currentIndex={currentIndex}
                          index={index}
                        />
                      ) : null}
                    </div>
                  </div>
                );
              })}
          </>
        </div>
        <EditAgendaModal
          IsEditAgendaModal={isEditAgendaModal}
          agendaData={agendaDatas}
          setIsEditAgendaModal={setIsEditAgendaModal}
          meetingData={meetingData}
        />
        <DeleteAgendaModal
          setIsDeleteAgendaModal={setIsDeleteAgendaModal}
          isDeleteAgendaModal={isDeleteAgendaModal}
          agendaData={agendaDatas}
        />
      </div>
    </>
  );
};

export default CreateMinutes;
