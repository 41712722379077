import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useSelector, useDispatch } from "react-redux";
import * as constantMessages from "../../constants/constatntMessages";
import { updateMinute } from "../../redux/actions/minuteActions/MinuteAction";
import { fetchCurrentAttendeesList } from "../../redux/actions/meetingActions/MeetingAction";
import { getEmployeeList } from "../../redux/actions/userAction/userAction";
import Select from "react-select";

const EditMinuteModal = (props) => {
  const { minuteData } = props;
  const regularExpression = /^[0-9a-zA-Z .(),/-]+$/;
  const meetingData = useSelector((state) => state.meeting);
  const employeeData = useSelector((state) => state.user);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const accessToken = localStorage.getItem("accessToken");
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const [selectedAttendeeOption, setSelectedAttendeeOption] = useState([
    { label: "Name / Email Address", value: "" },
  ]);
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState([
    { label: "Name / Emp Id", value: "" },
  ]);
  const [formData, setFormData] = useState({
    description: "",
    dueDate: "",
    priority: "",
    attendyType: "fromCurrentMeeting",
    responsiblePersonId: "",
    isAction: false,
    name: "",
    email: "",
    designation: "",
    companyName: "",
  });
  useEffect(() => {
    if (minuteData?.dueDate) {
      var date = new Date(minuteData?.dueDate);
      var dateString = new Date(
        date?.getTime() - date?.getTimezoneOffset() * 60000
      )
        .toISOString()
        .split("T")[0];
    }
    setFormData({
      ...formData,
      description: minuteData?.description,
      dueDate: dateString,
      priority: minuteData?.priority,
      attendyType: "fromCurrentMeeting",
      responsiblePersonId: minuteData?.assignedUserId,
      isAction: minuteData?.isAction,
      name: minuteData?.name,
      email: minuteData?.email,
    });
    if (minuteData?.isSuccess) {
      props.setIsEditMinuteModal(false);
    }
    if (formData.attendyType === "fromCurrentMeeting") {
      if (accessToken) {
        dispatch(
          fetchCurrentAttendeesList(
            userData.organizationId,
            meetingData?.meetingDetails?._id,
            accessToken
          )
        );
      }
    }
  }, [props.IsEditMinuteModal, minuteData?.isSuccess]);

  const checkHandler = (e, uid) => {
    const { checked } = e.target;

    if (uid) {
    } else {
      setFormData({
        ...formData,
        isAction: !formData.isAction,
      });
    }
  };

  const handleChange = (e) => {
    setErrors({});
    const { name, value } = e.target;

    setFormData({
      ...formData,

      [name]: value,
    });

    if (value === "fromEmployeeList") {
      const payload = {
        organizationId: userData.organizationId,
      };

      dispatch(getEmployeeList(payload, accessToken));
    }
  };

  const validateMinuteTitle = () => {
    const errors = {};
    if (!formData?.description.trim()) {
      errors.description = constantMessages.titleRequired;
    } else if (!regularExpression.test(formData.description)) {
      errors.description = constantMessages.titleRegexError;
    } else if (
      formData.description.trim().length < 5 ||
      formData.description.trim().length > 100
    ) {
      errors.description = constantMessages.stringLengthError;
    }
    setErrors(errors);
  };

  const dateFieldValidationCheck = (e) => {
    const errors = {};
    if (formData?.dueDate?.trim()) {
      const currentDate = new Date();
      const inputDate = new Date(formData?.dueDate);
      let differenceInTime = inputDate.getTime() - currentDate.getTime();
      let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      if (differenceInDays < 0) {
        errors.date = constantMessages.invalidDate;
      }
    }
    setErrors(errors);
  };

  const nameFieldValidationCheck = (e) => {
    const errors = {};

    if (!formData?.name?.trim()) {
      errors.name = constantMessages.nameRequired;
    } else if (!regularExpression.test(formData.name)) {
      errors.name = constantMessages.invalidName;
    } else if (
      formData?.name?.trim().length < 3 ||
      formData?.name?.trim().length > 50
    ) {
      errors.name = constantMessages.stringLengthError;
    }
    setErrors(errors);
  };

  const emailFieldValidationCheck = (e) => {
    const errors = {};

    if (!formData?.email?.trim()) {
      errors.email = constantMessages.emailRequired;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = constantMessages.invalidEmail;
    }

    setErrors(errors);
  };

  const validateForm = () => {
    const errors = {};
    if (formData.attendyType === "addNewPeople") {
      if (!formData?.name?.trim()) {
        errors.name = constantMessages.nameRequired;
      } else if (!regularExpression.test(formData?.name)) {
        errors.name = constantMessages.invalidName;
      } else if (
        formData?.name?.trim().length < 3 ||
        formData?.name?.trim().length > 50
      ) {
        errors.name = constantMessages.nameLengthError;
      }

      if (!formData?.email?.trim()) {
        errors.email = constantMessages.emailRequired;
      } else if (!/\S+@\S+\.\S+/.test(formData?.email)) {
        errors.email = constantMessages.invalidEmail;
      }
    } else {
      if (
        !formData?.responsiblePersonId?.trim() &&
        formData?.isAction &&
        formData?.name === ("" || undefined) &&
        formData?.email === ("" || undefined)
      ) {
        errors.responsiblePersonId = constantMessages.responsiblePersonRequired;
      }
    }

    if (!formData?.description.trim()) {
      errors.description = constantMessages.titleRequired;
    } else if (!regularExpression.test(formData.description)) {
      errors.description = constantMessages.titleRegexError;
    } else if (
      formData.description.trim().length < 5 ||
      formData.description.trim().length > 100
    ) {
      errors.description = constantMessages.stringLengthError;
    }

    if (formData?.dueDate?.trim() && formData?.isAction) {
      const currentDate = new Date(meetingData?.meetingDetails?.date);
      const inputDate = new Date(formData?.dueDate);
      let differenceInTime = inputDate.getTime() - currentDate.getTime();
      let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
      if (differenceInDays < 0) {
        errors.date = constantMessages.dateMoreThanMeetingDate;
      }
    } else if (!formData?.dueDate?.trim() && formData?.isAction) {
      errors.date = constantMessages.dateRequired;
    }
    if (!formData?.priority?.trim() && formData?.isAction) {
      errors.priority = constantMessages.priorityRequired;
    }

    return errors;
  };

  const onAddMinute = () => {
    const newErrors = validateForm(formData);
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const responsiblePersonId =
        formData.responsiblePersonId === "" &&
        formData.name === "" &&
        formData.email === ""
          ? userData._id
          : formData.responsiblePersonId;

      const isNewUser =
        formData.name !== undefined &&
        formData.email !== undefined &&
        formData.isAction === true
          ? true
          : formData.name === undefined &&
            formData.email === undefined &&
            formData.isAction === true
          ? false
          : undefined;
      const newMinuteData = {
        organizationId: userData?.organizationId,

        meetingId: meetingData?.meetingDetails?._id?.toString(),

        description: formData.description,
        dueDate:
          formData.isAction === true && formData.dueDate !== ""
            ? formData.dueDate
            : undefined,
        priority:
          formData.isAction === true && formData.priority !== ""
            ? formData.priority
            : undefined,
        isNewUser,

        assignedUserId:
          formData.isAction === true && isNewUser === false
            ? responsiblePersonId
            : undefined,
        isAction: formData.isAction,

        name:
          formData.isAction === true && isNewUser === true
            ? formData.name
            : undefined,
        email:
          formData.isAction === true && isNewUser === true
            ? formData.email
            : undefined,
      };

      dispatch(updateMinute(newMinuteData, minuteData._id, accessToken));
      props.setIsEditMinuteModal(false);

      setFormData({
        ...formData,
        description: " ",
        dueDate: "",
        priority: "",
        attendyType: "fromCurrentMeeting",
        responsiblePersonId: "",
        isAction: false,

        name: "",
        email: "",
        designation: "",
        companyName: "",
      });
    } else {
    }
  };

  const designationFieldValidationCheck = (e) => {
    const errors = {};

    const regularExpression = /^[A-Za-z\s]+$/; //returns true if matched, vaidates for a-z and A-Z and white space
    if (formData?.designation?.trim()) {
      if (!regularExpression.test(formData.designation)) {
        errors.designation = constantMessages.invalidDesignation;
      }
    }
    setErrors(errors);
  };

  const companyNameFieldValidationCheck = (e) => {
    const errors = {};

    const regularExpression = /^[A-Za-z\s]+$/; //returns true if matched, vaidates for a-z and A-Z and white space
    if (formData?.companyName?.trim()) {
      if (!regularExpression.test(formData.companyName)) {
        errors.companyName = constantMessages.invalidCompanyName;
      }
    }
    setErrors(errors);
  };

  if (meetingData?.currentAttendeesList?.length !== 0) {
    var attendeeOptions = meetingData?.currentAttendeesList?.map((attendee) => {
      const returnData = {
        value: attendee._id,
        label: attendee.name + " / " + attendee.email,
        name: "responsiblePersonId",
      };

      return returnData;
    });
  }

  const handleAttendeeChange = (e) => {
    setErrors({});

    const { name, value } = e;
    setFormData({
      ...formData,
      [name]: value,
    });
    setSelectedAttendeeOption(e);
  };
  const handleEmployeeChange = (e) => {
    setErrors({});

    const { name, value } = e;
    setFormData({
      ...formData,
      [name]: value,
    });
    setSelectedEmployeeOption(e);
  };
  if (employeeData?.employeeList?.length !== 0) {
    var employeeOptions = employeeData?.employeeList?.map((attendee) => {
      const returnData = {
        value: attendee._id,
        label: attendee.name + " / " + attendee.empId,
        name: "responsiblePersonId",
      };

      return returnData;
    });
  }
  return (
    <>
      <Modal
        show={props.IsEditMinuteModal}
        onHide={(e) => props.setIsEditMinuteModal(false)}
      >
        <div className="modal-header attendees-modal">
          <div>
            <h4 className="modal-title">Edit Minute</h4>
          </div>
          <div>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={(e) => props.setIsEditMinuteModal(false)}
            ></button>
          </div>
        </div>

        <Modal.Body className="attendees-popup modal-margin">
          <div className="open-div">
            <form className="addminutesboxfrom">
              <div
                className={
                  props.isMinuteShow && props.index === props.currentIndex
                    ? "minutes-box show"
                    : !props.isMinuteShow && props.index === props.currentIndex
                    ? "minutes-box"
                    : "minutes-box"
                }
              >
                <div className="form-group">
                  <div className=" mt-1 mb-1 d-flex justify-content-between align-items-center ">
                    <label>Minutes</label>

                    <div className="form-check ">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                        name="isAction"
                        onChange={checkHandler}
                        checked={formData.isAction}
                      />
                      <label
                        className="form-check-label "
                        id="flexCheckDefault"
                        for="flexCheckDefault"
                      >
                        Add Action
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div>
                        <textarea
                          placeholder="Enter minute description here"
                          name="description"
                          value={formData.description}
                          onChange={handleChange}
                          onBlur={validateMinuteTitle}
                          autoComplete="off"
                        />

                        {errors.description ? (
                          <span className="error-message">
                            {errors.description}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="add-action">
                  {formData.isAction ? (
                    <>
                      <div className="form-group">
                        <div className="position-relative">
                          <label className="pb-2 input-date"> Due Date</label>
                          <input
                            type="date"
                            name="dueDate"
                            value={formData.dueDate}
                            onChange={handleChange}
                            onBlur={dateFieldValidationCheck}
                          />
                        </div>
                        {errors.date && (
                          <span className="error-message">{errors.date}</span>
                        )}
                      </div>

                      <div className="form-group">
                        <div className="position-relative">
                          <label className="pb-2 input-date">Priority</label>
                          <div>
                            <select
                              name="priority"
                              id="priority"
                              onChange={handleChange}
                            >
                              {minuteData.priority ? (
                                <option value={minuteData.priority}>
                                  {minuteData.priority.charAt(0).toUpperCase() +
                                    minuteData.priority.slice(1).toLowerCase()}
                                </option>
                              ) : (
                                <option value="">Select Priority</option>
                              )}

                              <option value="HIGH">High</option>
                              <option value="NORMAL">Normal</option>
                              <option value="LOW">Low</option>
                            </select>
                          </div>
                        </div>
                        {errors.priority ? (
                          <span className="error-message">
                            {errors.priority}
                          </span>
                        ) : null}
                      </div>

                      <div className="form-group pb-3 border-bottom">
                        <label className="pb-2">
                          Select Responsible Person
                        </label>

                        <div className="w-100">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="attendyType"
                              value="fromCurrentMeeting"
                              id="flexRadioDefault1"
                              onChange={handleChange}
                              checked={
                                formData.attendyType === "fromCurrentMeeting"
                              }
                            />
                            <label
                              className="mb-2 form-check-label"
                              for="flexRadioDefault1"
                              id="flexRadioDefault1"
                            >
                              Select From Current Meetings
                            </label>
                          </div>

                          <div className="form-check-inline">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="attendyType"
                                value="fromEmployeeList"
                                id="flexRadioDefault2"
                                onChange={handleChange}
                                checked={
                                  formData.attendyType === "fromEmployeeList"
                                }
                              />
                              <label
                                className=" mb-2 form-check-label"
                                for="flexRadioDefault2"
                                id="flexRadioDefault2"
                              >
                                Select From Employees
                              </label>
                            </div>
                          </div>

                          <div className="form-check-inline">
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="attendyType"
                                value="addNewPeople"
                                id="flexRadioDefault3"
                                checked={
                                  formData.attendyType === "addNewPeople"
                                }
                                onChange={handleChange}
                              />
                              <label
                                className=" mb-2 form-check-label"
                                for="flexRadioDefault3"
                                id="flexRadioDefault3"
                              >
                                Add New Guest
                              </label>
                            </div>
                          </div>
                        </div>

                        {formData.attendyType === "fromCurrentMeeting" ? (
                          <Select
                            className="fltr-opt"
                            placeholder="Name / Email Address"
                            name="responsiblePersonId"
                            options={attendeeOptions}
                            onChange={handleAttendeeChange}
                            value={selectedAttendeeOption}
                          />
                        ) : formData.attendyType === "fromEmployeeList" ? (
                          <Select
                            className="fltr-opt"
                            placeholder="Name / Employee ID"
                            name="responsiblePersonId"
                            options={employeeOptions}
                            value={selectedEmployeeOption}
                            onChange={handleEmployeeChange}
                          />
                        ) : formData.attendyType === "addNewPeople" ? (
                          <div>
                            <label className="">Add New Guest</label>
                            <div className="row">
                              <div className="col-xl-6 col-md-6 mt-2">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  placeholder="Name"
                                  name="name"
                                  value={formData.name}
                                  onChange={handleChange}
                                  onBlur={nameFieldValidationCheck}
                                />
                                {errors.name && (
                                  <span className="error-message">
                                    {errors.name}
                                  </span>
                                )}
                              </div>
                              <div className="col-xl-6 col-md-6 mt-2">
                                <input
                                  type="text"
                                  placeholder="Email"
                                  autoComplete="off"
                                  name="email"
                                  value={formData.email}
                                  onBlur={emailFieldValidationCheck}
                                  onChange={handleChange}
                                />
                                {errors.email && (
                                  <span className="error-message">
                                    {errors.email}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xl-6 col-md-6 mt-2">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  placeholder="Designation"
                                  name="designation"
                                  value={formData.designation}
                                  onChange={handleChange}
                                  onBlur={designationFieldValidationCheck}
                                />
                                {errors.designation && (
                                  <span className="error-message">
                                    {errors.designation}
                                  </span>
                                )}
                              </div>

                              <div className="col-xl-6 col-md-6 mt-2">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  placeholder="Company Name"
                                  name="companyName"
                                  value={formData.companyName}
                                  onChange={handleChange}
                                  onBlur={companyNameFieldValidationCheck}
                                />
                                {errors.companyName && (
                                  <span className="error-message">
                                    {errors.companyName}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {errors.responsiblePersonId && (
                          <span className="error-message">
                            {errors.responsiblePersonId}
                          </span>
                        )}
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={(e) => props.setIsEditMinuteModal(false)}
            className="btn-light"
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={onAddMinute}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditMinuteModal;
