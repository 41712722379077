import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Modal from "react-bootstrap/Modal";

const AmendmentRequestViewModal = (props) => {
  return (
    <>
      <Modal
        show={props.amendmentViewModal}
        onHide={(e) => props.setAmendmentViewModal(false)}
      >
        <div className="modal-header">
          <h4 className="modal-title">Request for Amendment</h4>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={(e) => props.setAmendmentViewModal(false)}
          ></button>
        </div>
        <div className="modal-body delete-txt">
          <h6>Amendment Detail</h6>
          <textarea
            cols="40"
            rows="4"
            id="details"
            name="details"
            value={props.details}
            disabled
            required
          ></textarea>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={(e) => props.handleSubmit("ACCEPTED")}
            className="Mom-btn btn btn-secondary bg-primary border-primary"
          >
            <p>Accept</p>
          </button>
          <button
            type="button"
            onClick={(e) => props.handleSubmit("REJECTED")}
            className="reset btn btn-light bg-white border-primary text-primary "
          >
            <p>Reject</p>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AmendmentRequestViewModal;
